const tw = {
  loaderContainer:
    "w-full h-full absolute z-50 flex justify-center items-center",
  loader: "flex justify-center items-center animate-rotate-loader",
  cube: "w-5 h-5 bg-interactive-text-text-b-primary animate-scale-loader",
};

export const Loader = () => (
  <div className={tw.loaderContainer}>
    <div className={tw.loader}>
      <div>
        <div className={tw.cube} />
        <div className={tw.cube} />
      </div>
      <div>
        <div className={tw.cube} />
        <div className={tw.cube} />
      </div>
    </div>
  </div>
);
