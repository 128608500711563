import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ListStatusDropdown from "src/components/ListStatusDropdown";
import { PersonRow } from "src/components/PersonRow";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { Routes } from "src/config/routes";
import { usePageURLParams, useSort, useTrackPageVisited } from "src/hooks";
import { Person } from "src/models/person-api-response";
import {
  useAddPersonToListMutation,
  useRemovePeopleFromListMutation,
  useSavedList,
  useSavedListDetails,
} from "src/queries/lists/useSavedList";

import {
  Button,
  CheckboxCheckmark,
  IconButton,
  Pagination,
} from "@50y/celestial";
import { TrashIcon } from "src/assets/icons/general/TrashIcon";
import { RequestIntroIcon } from "src/assets/icons/ui/RequestIntroIcon";
import BatchIntroRequestModal from "src/components/BatchIntroRequestModal";
import BreadcrumbComponent from "src/components/Breadcrumbs";
import { Loader } from "src/components/Loader";
import DeleteModal from "src/components/modals/DeleteModal";
import PageEmptyState from "src/components/PageEmptyState";
import { PeopleSearch } from "src/components/PeopleSearch";
import { StickyHeader } from "src/components/StickyHeader";
import { SortableCell } from "src/components/table/SortableCell";
import { useUpdatePersonStatusForList } from "src/queries/lists/useUpdatePersonStatusForList";
import { QUERY_KEYS } from "src/queries/query-keys";
import { LIST_PAGE_SIZE } from "src/services/lists.service";
import { SortTypes, URLParams } from "src/utils/constants";

const DEFAULT_PAGE = 1;

const ListPage: React.FC = () => {
  const { getSearchParam, setSearchParams } = usePageURLParams();
  const [openDeleteModal, setDeleteModalOpen] = useState<boolean>(false);
  const [isBatchIntroModalOpen, setBatchIntroModalOpen] =
    useState<boolean>(false);
  const params = useParams();
  const listId = Number(params.list_id);
  const { data: savedListDetails } = useSavedListDetails(listId);

  const { sort, getSort, sortHandler } = useSort(SortTypes.PERSON_ADDED_LAST);

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const currentPage =
    (getSearchParam(URLParams.PAGE) as number) || DEFAULT_PAGE;

  const {
    data,
    isLoading: savedListIsLoading,
    isFetching: savedListIsFetching,
  } = useSavedList(listId, currentPage, sort);

  const savedListData = useMemo(() => data?.data, [data]);

  const { mutate: removeContactsFromList } = useRemovePeopleFromListMutation();

  const { mutate: addContactToList } = useAddPersonToListMutation();

  const { mutate: updatePersonStatusForList } =
    useUpdatePersonStatusForList(listId);

  useTrackPageVisited(NavigationEvents.LIST_PAGE_VIEWED);

  const statusByPersonId = savedListDetails?.saved_list_person_statuses?.reduce(
    (acc, { person, status }) => {
      acc.set(person, status);
      return acc;
    },
    new Map<number, number>(),
  );

  const listName = savedListDetails?.name;

  const isEmptyList = !savedListDetails?.people_ids.length;

  const removePeople = () => {
    if (savedListData && savedListDetails) {
      removeContactsFromList({
        list: savedListDetails,
        peopleIds: Array.from(selectedRows),
        page: Routes.LIST,
        pageNumber: currentPage,
        queryParams: { id: listId, page: 1, sort },
      });
    }
    setDeleteModalOpen(false);
    setSelectedRows(new Set());
    setSearchParams({ [URLParams.PAGE]: `${DEFAULT_PAGE}` }, { replace: true });
  };

  const handleOnPersonSelect = (person: Person) => {
    if (savedListData && savedListDetails) {
      addContactToList({
        list: savedListDetails,
        person,
        page: Routes.LIST,
        pageNumber: currentPage,
        sort,
        queryToInvalidate: person.investor
          ? QUERY_KEYS.INVESTORS
          : QUERY_KEYS.CONNECTIONS,
      });
    }
  };

  const handlePersonStatusChange = async (personId: number, status: number) => {
    if (savedListDetails) {
      updatePersonStatusForList({
        listId: savedListDetails.id,
        personId,
        status,
        listName: savedListDetails.name,
      });
    }
  };

  const toggleRowSelection = (id: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(id)) {
      newSelectedRows.delete(id);
    } else {
      newSelectedRows.add(id);
    }
    setSelectedRows(newSelectedRows);
  };

  const renderedPeople =
    savedListData?.results.map((person) => (
      <PersonRow
        key={person.id}
        person={person}
        savedListId={listId}
        isSelected={selectedRows.has(person.id)}
        onRowClick={toggleRowSelection}
      >
        <ListStatusDropdown
          personId={person.id}
          selectedStatus={statusByPersonId?.get(person.id)}
          onChange={(status) => {
            handlePersonStatusChange(person.id, status);
          }}
        ></ListStatusDropdown>
      </PersonRow>
    )) || [];

  const changePage = (page: number | string) => {
    setSearchParams({ [URLParams.PAGE]: page.toString() }, { replace: true });
  };

  const handleMainSelectionClick = () => {
    if (selectedRows.size === savedListData?.results.length) {
      setSelectedRows(new Set());
    } else {
      setSelectedRows(new Set(savedListData?.results.map((p) => p.id)));
    }
  };

  return (
    <div className="h-full relative overflow-y-auto">
      <StickyHeader className="h-auto !block !p-0">
        <BreadcrumbComponent
          breadcrumbs={[{ link: "#", title: listName ?? "" }]}
        />
        <div className="h-16 p-3 flex justify-between">
          <PeopleSearch
            placeholder={
              savedListData?.results.length
                ? "Search"
                : "Add contacts by searching"
            }
            onPersonSelect={handleOnPersonSelect}
            selectedPeopleIds={new Set(savedListData?.results.map((p) => p.id))}
          ></PeopleSearch>
          {!!selectedRows.size && (
            <div className="flex gap-4">
              {selectedRows.size > 1 && (
                <Button
                  variant="outlined"
                  icon={() => <RequestIntroIcon className="fill-current" />}
                  onPress={() => setBatchIntroModalOpen(true)}
                >
                  Request Intros
                </Button>
              )}
              <IconButton
                variant="outlined"
                label="Delete"
                icon={() => <TrashIcon className="fill-current" />}
                onPress={() => setDeleteModalOpen(true)}
              />
            </div>
          )}
        </div>
      </StickyHeader>
      <div className="h-[calc(100%_-_105px)] overflow-x-auto">
        {isEmptyList &&
          !savedListIsLoading &&
          !savedListIsFetching &&
          savedListData?.count === 0 && (
            <PageEmptyState
              title="List is looking rather empty"
              description="Add contacts with the search bar above."
            />
          )}
        <div className="h-full flex flex-col justify-between relative">
          <div className="p-6 grid">
            {!!savedListData?.count && (
              <div className="grid grid-cols-list-grid border-b border-elevation-outline-0 py-2-5">
                <div
                  className="px-3 group"
                  {...(selectedRows.size < savedListData?.results.length &&
                    selectedRows.size > 0 && {
                      "data-indeterminate": true,
                    })}
                  {...(selectedRows.size === savedListData?.results.length &&
                    selectedRows.size > 0 && {
                      "data-selected": true,
                    })}
                >
                  <CheckboxCheckmark
                    variant="default"
                    className="cursor-pointer"
                    onClick={handleMainSelectionClick}
                  />
                </div>
                <div className="px-3">
                  <SortableCell
                    cellText="Name"
                    direction={getSort(SortTypes.NAME)}
                    onSortClick={sortHandler(SortTypes.NAME)}
                  />
                </div>
                <div className="px-3">
                  <SortableCell
                    cellText="Company"
                    direction={getSort(SortTypes.COMPANY)}
                    onSortClick={sortHandler(SortTypes.COMPANY)}
                  />
                </div>
                <div className="px-3">
                  <SortableCell
                    cellText="Connections"
                    direction={getSort(SortTypes.CONNECTIONS)}
                    onSortClick={sortHandler(SortTypes.CONNECTIONS)}
                  />
                </div>
                <div className="px-3">
                  <SortableCell
                    cellText="Status"
                    direction={getSort(SortTypes.STATUS)}
                    onSortClick={sortHandler(SortTypes.STATUS)}
                  />
                </div>
                <div className="px-3" />
              </div>
            )}
            {renderedPeople}
          </div>
          {savedListIsLoading && <Loader />}
          {!savedListIsLoading && (
            <Pagination
              total={savedListData?.count || 0}
              perPage={LIST_PAGE_SIZE}
              page={Number(currentPage)}
              onPageChange={changePage}
            />
          )}
        </div>
      </div>
      {openDeleteModal && (
        <DeleteModal
          onDelete={removePeople}
          onClose={() => setDeleteModalOpen(false)}
          bodyText={`You are about to remove people from ${savedListDetails?.name}.`}
          deleteButtonText="Remove"
        />
      )}
      {isBatchIntroModalOpen && (
        <BatchIntroRequestModal
          selectedPeople={(savedListData?.results || []).filter((p) =>
            selectedRows.has(p.id),
          )}
          onRemovePeople={(people) => {
            const newSelectedRows = new Set(selectedRows);
            for (const person of people) {
              newSelectedRows.delete(person.id);
            }
            setSelectedRows(newSelectedRows);
          }}
          onClose={() => setBatchIntroModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ListPage;
