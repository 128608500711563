import { Avatar, Button as CelestialButton, Typography } from "@50y/celestial";
import { reset } from "@amplitude/analytics-browser";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import {
  Button,
  Key,
  Link,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
} from "react-aria-components";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ArrowDropDownIcon } from "src/assets/icons/general/ArrowDropDown";
import { SettingsIcon } from "src/assets/icons/ui/SettingsIcon";
import { LoginEvents } from "src/config/amplitudeEvents";
import { routeNames, Routes } from "src/config/routes";
import { useAmplitudeTrack } from "src/hooks";
import { getParentRoute } from "src/utils/helpers";

enum MenuItems {
  LOGOUT = "logout",
}

// This function is used to get the page name from the pathname
const getPageName = (pathname: string) => {
  const routeKeys = Object.keys(routeNames).find((key) =>
    key.includes(pathname.split("/")[1]),
  );
  return routeNames[routeKeys as keyof typeof routeNames]?.name ?? "";
};

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth0();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { track } = useAmplitudeTrack();

  const handleLogout = useCallback(() => {
    track(LoginEvents.LOGGED_OUT);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    reset();
  }, [logout, track]);

  const handleMenuAction = (key: Key) => {
    switch (key) {
      case MenuItems.LOGOUT:
        handleLogout();
        break;
      default:
        break;
    }
  };

  const isMainPage = pathname.split("/").filter(Boolean).length === 1;

  return (
    <header className="flex items-center justify-between w-full px-6 py-4 bg-elevation-0 border-b border-elevation-outline-0">
      <div className="flex items-center justify-between w-full">
        {isMainPage ? (
          <Typography
            size="title-5"
            variant="prominent-1"
            className="capitalize"
          >
            {getPageName(pathname)}
          </Typography>
        ) : (
          <NavLink
            to={getParentRoute(pathname)}
            className="flex items-center gap-2"
          >
            <Typography
              size="title-5"
              variant="prominent-1"
              className="capitalize"
            >
              {getPageName(pathname)}
            </Typography>
          </NavLink>
        )}

        <div className="flex items-center">
          <Link
            href="https://50y.typeform.com/founder-request"
            target="_blank"
            className="mr-3"
          >
            <CelestialButton variant="secondary" size="small" width="s">
              ✨Magic✨
            </CelestialButton>
          </Link>
          <SettingsIcon
            className="cursor-pointer"
            onClick={() => navigate(Routes.SETTINGS)}
          />
          <div className="bg-elevation-outline-0 w-[1px] h-6 mx-3" />
          <MenuTrigger
            onOpenChange={(isOpen: boolean) => setMenuIsOpen(isOpen)}
          >
            <Button
              aria-label="Menu"
              className="outline-none flex items-center gap-2 cursor-pointer"
            >
              <Avatar src={user?.picture} />
              <ArrowDropDownIcon
                className={`w-6 h-6 transition-all duration-300 ${
                  menuIsOpen ? "rotate-180" : ""
                }`}
              />
            </Button>
            <Popover className="!top-16">
              <Menu
                onAction={handleMenuAction}
                className="p-2 outline-none border border-elevation-outline-1 bg-white w-32"
              >
                <MenuItem
                  id={MenuItems.LOGOUT}
                  className="focus-visible:outline-none cursor-pointer px-3"
                >
                  Logout
                </MenuItem>
              </Menu>
            </Popover>
          </MenuTrigger>
        </div>
      </div>
    </header>
  );
};
