import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  GenericApiResponse,
  PaginatedApiResponse,
} from "src/models/api-response";
import { Person, User } from "src/models/person-api-response";
import {
  API_SERVER_URL,
  DEFAULT_PAGE_SIZE,
  getHeaders,
} from "./global.service";

export const getMe = async (
  accessToken: string,
): Promise<AxiosResponse<User>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/users/me`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getUser = async (
  accessToken: string,
  id: number | undefined,
): Promise<AxiosResponse<User>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/users/${id}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getPersons = async (
  accessToken: string,
  page: number,
  search: string,
  sort?: string | undefined,
): Promise<GenericApiResponse<PaginatedApiResponse<Person>>> => {
  const params = {
    limit: DEFAULT_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
    q: search,
    ordering: sort,
  };
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/persons`,
    method: "GET",
    params,
    headers: getHeaders(accessToken),
  };

  return axios(config);
};
