// Function returns empty list if there is no intersected elements
export const intersectArrays = <T>(arr1: T[], arr2: T[]): T[] => {
  const set1 = new Set(arr1);
  const result: T[] = [];

  for (const element of arr2) {
    if (set1.has(element)) {
      result.push(element);
      set1.delete(element);
    }
  }

  return result;
};
