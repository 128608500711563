import { ActionEvents } from "src/config/amplitudeEvents";
import { trackingService } from "./trackingService";

export interface ThreadSummary {
  threadId: string;
  from: string;
  to: string;
  messageCount: number;
  subject: string;
  createdDate: string;
  updatedDate: string;
  participants: Set<string>;
  recipients: Set<string>;
}

export const launchGmailAuthorization = async () => {
  // Google's OAuth 2.0 endpoint for requesting an access code.
  // Note: This results in a redirect and the /add-connections page
  //       looks at the parameters in the redirect URL to get the code.
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
  const scopes = ["https://www.googleapis.com/auth/gmail.metadata"];

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement("form");
  form.setAttribute("method", "GET");
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  interface Params {
    [key: string]: string;
  }
  const params: Params = {
    client_id: client_id,
    redirect_uri: `${process.env.REACT_APP_APP_URL}/add-connections`,
    response_type: "code",
    scope: scopes.join(" "),
    include_granted_scopes: "true",
    prompt: "consent",
    access_type: "offline",
  };

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }
  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
};

export const launchGoogleAuthorization = async (getAccessToken: any) => {
  // Google's OAuth 2.0 endpoint for requesting an access token.
  // Note: This results in a redirect and the /add-connections page
  //       looks at the parameters in the redirect URL to get the token.
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
  const scopes = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/contacts.readonly",
    "https://www.googleapis.com/auth/contacts.other.readonly",
    "https://www.googleapis.com/auth/calendar.events.readonly",
  ];

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement("form");
  form.setAttribute("method", "GET");
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  interface Params {
    [key: string]: string;
  }
  const params: Params = {
    client_id: client_id,
    redirect_uri: `${process.env.REACT_APP_APP_URL}/add-connections`,
    response_type: "token",
    scope: scopes.join(" "),
    include_granted_scopes: "true",
    prompt: "select_account",
  };

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }
  const accessToken = await getAccessToken();
  trackingService(accessToken, ActionEvents.GOOGLE_CONNECTIONS_IMPORT_STARTED);
  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
};

export const getUserGoogleEmail = async (accessTokenGoogle: string) => {
  const url = "https://www.googleapis.com/oauth2/v3/userinfo?";
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessTokenGoogle,
      },
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log(`Error while calling People API: ${error}`);
  }
  return [];
};

export const getGooglePeopleApiContacts = async (accessTokenGoogle: string) => {
  // Wrapper around the google contacts api that handles pagination
  const connections = [];
  let nextPageToken = null;
  let counter = 0; // Limit how many times we call this loop

  while (counter++ < 10) {
    const result: any = await callGooglePeopleApiContacts(
      accessTokenGoogle,
      nextPageToken,
    );
    if (result?.error?.code) {
      console.error("Error while getting contacts: " + result?.error?.message);
      break;
    } else {
      connections.push(...(result?.connections || []));
      nextPageToken = result?.nextPageToken;
      if (!nextPageToken) break;
    }
  }

  return connections;
};

const callGooglePeopleApiContacts = async (
  accessTokenGoogle: string,
  nextPageToken: string | null,
) => {
  let url =
    "https://people.googleapis.com/v1/people/me/connections?" +
    "personFields=names,phoneNumbers,emailAddresses,urls&" +
    "sortOrder=LAST_MODIFIED_DESCENDING&" +
    "pageSize=1000&" +
    "access_token=" +
    accessTokenGoogle;
  if (nextPageToken) {
    url += `&pageToken=${nextPageToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log(`Error while calling People API: ${error}`);
  }
  return [];
};

export const getGooglePeopleApiOtherContacts = async (
  accessTokenGoogle: string,
) => {
  // Wrapper around the google other contacts api that handles pagination
  const connections = [];
  let nextPageToken = null;
  let counter = 0; // Limit how many times we call this loop

  while (counter++ < 10) {
    const result: any = await callGooglePeopleApiOtherContacts(
      accessTokenGoogle,
      nextPageToken,
    );
    if (result?.error?.code) {
      console.error("Error while getting contacts: " + result?.error?.message);
      break;
    } else {
      connections.push(...(result?.otherContacts ?? []));
      nextPageToken = result?.nextPageToken;
      if (!nextPageToken) break;
    }
  }

  return connections;
};

const callGooglePeopleApiOtherContacts = async (
  accessTokenGoogle: string,
  nextPageToken: string | null,
) => {
  let url =
    "https://people.googleapis.com/v1/otherContacts?" +
    "readMask=names,phoneNumbers,emailAddresses&" +
    "pageSize=1000&" +
    "access_token=" +
    accessTokenGoogle;
  if (nextPageToken) {
    url += `&pageToken=${nextPageToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log(`Error while calling People API: ${error}`);
  }
  return [];
};

export const getGoogleCalendarEvents = async (accessTokenGoogle: string) => {
  // Wrapper around the google events api that handles pagination
  const events = [];
  let nextPageToken = null;
  let counter = 0; // Limit how many times we call this loop

  while (counter++ < 10) {
    const result: any = await callGoogleCalendarEventsApi(
      accessTokenGoogle,
      nextPageToken,
    );
    if (result?.error?.code) {
      console.error("Error while getting events: " + result?.error?.message);
      break;
    } else {
      events.push(...(result?.items ?? []));
      nextPageToken = result?.nextPageToken;
      if (!nextPageToken) break;
    }
  }

  return events;
};

const callGoogleCalendarEventsApi = async (
  accessTokenGoogle: string,
  nextPageToken: string | null,
) => {
  let url =
    "https://www.googleapis.com/calendar/v3/calendars/primary/events?" +
    "maxResults=2500&" +
    "orderBy=startTime&" +
    "singleEvents=true&" +
    "timeMin=2017-01-01T00%3A00%3A00-00%3A00&" +
    "access_token=" +
    accessTokenGoogle;
  if (nextPageToken) {
    url += `&pageToken=${nextPageToken}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.log(`Error while calling Calendar API: ${error}`);
  }
  return [];
};
