import { Auth0Provider } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

const Auth0ProviderWithRedirectCallback = ({ ...props }) => {
  if (!(domain && clientId && redirectUri && audience)) {
    return <div>App Auth setup required</div>;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
      }}
      {...props}
    >
      <Outlet />
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallback;
