import { User } from "@auth0/auth0-react";
import { useInitAmplitude } from "src/hooks";

export const AmplitudeProvider = ({
  children,
  user,
}: {
  user: User | undefined;
  children: React.ReactElement;
}) => {
  useInitAmplitude(user);

  return children;
};
