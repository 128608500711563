import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  AppError,
  GenericApiResponse,
  PaginatedApiResponse,
} from "src/models/api-response";
import { Attendee, Event, EventStatus } from "src/models/events-api-response";
import { queryClient } from "src/providers/queryClient";
import { QUERY_KEYS } from "src/queries/query-keys";
import { updateEventAttendee } from "src/services/event.service";
import { useMe } from "../useMe";

type MutationVariables = {
  eventId: number;
  status: EventStatus;
  firstResponse?: boolean;
};

type MutationResult = Attendee | undefined | null;
type MutationError = AppError | null;
type ReturnType = {
  data: MutationResult;
  error: MutationError;
};

export const useEventsMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { data: me } = useMe();

  const mutationFn = async ({
    eventId,
    status,
  }: MutationVariables): Promise<ReturnType> => {
    const accessToken = await getAccessTokenSilently();
    return updateEventAttendee({ accessToken, eventId, status });
  };

  return useMutation({
    mutationFn,
    onSuccess: (result: ReturnType, variables: MutationVariables) => {
      toast.success("Successfully responded to the event");
      queryClient.setQueryData(
        [QUERY_KEYS.EVENTS],
        (prevData: GenericApiResponse<PaginatedApiResponse<Event>>) => {
          const updatedData = prevData.data.results.map((item: Event) => {
            if (item.id === variables.eventId) {
              return {
                ...item,
                is_current_user_attendee:
                  result?.data?.status === EventStatus.ATTENDING,
                attendees: variables.firstResponse // if user responded for the first time, he is not yet in the attendees list
                  ? [...item.attendees, result.data]
                  : item.attendees.map((attendee) => {
                      if (attendee.user.id === me?.data.id) {
                        return {
                          ...attendee,
                          status: result.data?.status,
                        };
                      }
                      return attendee;
                    }),
              };
            }
            return item;
          });
          return {
            ...prevData,
            data: {
              ...prevData.data,
              results: updatedData,
            },
          };
        },
      );
    },
    onError: () => {
      toast.error("Error responding to the event");
    },
  });
};
