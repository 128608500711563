import { TextInput } from "@50y/celestial";
import { useState } from "react";
import { SearchIcon } from "src/assets/icons/general/SearchIcon";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack, useDebouncedValue } from "src/hooks";

const defaultPlaceholder = "Search";

const Search = (props: {
  className?: string;
  defaultValue?: string;
  onChange: (search: string) => void;
  placeholder?: string;
  delay?: number;
  isLoading?: boolean;
}) => {
  const {
    defaultValue,
    className,
    onChange,
    placeholder = defaultPlaceholder,
    delay,
    isLoading = false,
  } = props;
  const [search, setSearch] = useState<string>(defaultValue || "");
  const { track } = useAmplitudeTrack();

  useDebouncedValue(search, {
    delay,
    onDebounce: () => onChange(search),
  });

  const handleFocus = () => {
    track(ActionEvents.SEARCH_BAR_CLICKED);
  };

  return (
    <div className="w-64">
      <TextInput
        className={className}
        isLoading={isLoading}
        leftContent={<SearchIcon />}
        placeholder={placeholder}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onFocus={handleFocus}
        inputProps="bg-inherit"
      />
    </div>
  );
};

export default Search;
