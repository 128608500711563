export const GoogleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    {...props}
  >
    <path
      d="M11.2195 9.00366V13.2558H17.1286C16.8691 14.6233 16.0904 15.7812 14.9226 16.5598L18.486 19.3247C20.5622 17.4083 21.76 14.5935 21.76 11.2496C21.76 10.4711 21.6902 9.72239 21.5604 9.00378L11.2195 9.00366Z"
      fill="#4285F4"
    />
    <path
      d="M5.06622 13.0898L4.26254 13.7051L1.41772 15.9209C3.22439 19.5043 6.92731 21.9798 11.2194 21.9798C14.1838 21.9798 16.6692 21.0016 18.4859 19.3247L14.9225 16.5598C13.9443 17.2186 12.6966 17.6179 11.2194 17.6179C8.36464 17.6179 5.93918 15.6914 5.07071 13.0962L5.06622 13.0898Z"
      fill="#34A853"
    />
    <path
      d="M1.41774 6.0791C0.669156 7.55632 0.23999 9.22328 0.23999 11C0.23999 12.7767 0.669156 14.4436 1.41774 15.9209C1.41774 15.9308 5.07108 13.0861 5.07108 13.0861C4.85148 12.4273 4.72168 11.7286 4.72168 10.9999C4.72168 10.2711 4.85148 9.57244 5.07108 8.91366L1.41774 6.0791Z"
      fill="#FBBC05"
    />
    <path
      d="M11.2196 4.39221C12.8366 4.39221 14.274 4.95117 15.4219 6.0292L18.566 2.88503C16.6595 1.10833 14.1842 0.0202637 11.2196 0.0202637C6.92753 0.0202637 3.22439 2.48572 1.41772 6.07912L5.07095 8.91391C5.9393 6.31865 8.36487 4.39221 11.2196 4.39221Z"
      fill="#EA4335"
    />
  </svg>
);
