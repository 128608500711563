import { Button, Pagination, Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Filters, { FilterReturnType } from "src/components/Filters";
import { ListsPopover } from "src/components/ListsPopover";
import { Loader } from "src/components/Loader";
import PageEmptyState from "src/components/PageEmptyState";
import { renderCompanyOrFirm } from "src/components/PersonRow";
import RequestIntroModal from "src/components/RequestIntroModal";
import Search from "src/components/Search";
import { StickyHeader } from "src/components/StickyHeader";
import { PersonPopover } from "src/components/table/PersonPopover";
import { SortableCell } from "src/components/table/SortableCell";
import { TagsWrapper } from "src/components/TagsWrapper";
import {
  ActionEvents,
  IRequestIntroClickEventParams,
  ModalEvents,
  NavigationEvents,
  RequestIntroEvents,
} from "src/config/amplitudeEvents";
import { CHROME_EXTENSION_LINK } from "src/config/constants";
import { Routes } from "src/config/routes";
import {
  useAmplitudeTrack,
  useSort,
  useTrackPageVisited,
  useTrackSearch,
} from "src/hooks";
import { Page, UserType } from "src/models/connections-api-response";
import { Investor } from "src/models/investors-api-response";
import { User } from "src/models/person-api-response";
import { useInvestor } from "src/queries/investors/useInvestor";
import { useInvestors } from "src/queries/investors/useInvestors";
import { QUERY_KEYS } from "src/queries/query-keys";
import { useMe } from "src/queries/useMe";
import { DEFAULT_INVESTOR_PAGE_SIZE } from "src/services/investor.service";
import { SortTypes } from "src/utils/constants";
import { getTitle, sortTagsByStrength } from "src/utils/helpers";

type LocationState = {
  investorName?: string;
};

const tw = {
  table:
    "grid grid-cols-investors-grid h-16 border-b border-elevation-outline-0 items-center",
  row: "group/item z-10 hover:bg-elevation-surface-1 transition-background-color duration-300 ease-in-out",
};

const InvestorsPage: React.FC = () => {
  const { track } = useAmplitudeTrack();
  const { id } = useParams<{ id: string }>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: currentUser } = useMe();
  const location = useLocation();
  const state: LocationState = location.state as LocationState;
  const [search, setSearch] = useState<string>(state?.investorName || "");
  const [selectedInvestor, setSelectedInvestor] = useState<
    Investor | undefined
  >();
  const { sort, getSort, sortHandler } = useSort(SortTypes.PERSON_NAME);
  const { data: investor, error: investorError } = useInvestor(id);
  const [selectedFilters, setSelectedFilters] = useState<FilterReturnType>({});
  const {
    data: investors,
    isLoading,
    error,
  } = useInvestors({
    page: currentPage,
    search,
    sort,
    selectedFilters,
  });
  const navigate = useNavigate();

  useTrackSearch({
    search,
    data: investors?.data,
    currentPage,
    scope: UserType.INVESTOR,
    error,
    isLoading,
  });

  useTrackPageVisited(NavigationEvents.INVESTORS_PAGE_VIEWED);

  useEffect(() => {
    if (investor?.data) {
      if (!investor.data.person.connection_strengths.length) {
        return navigate(Routes.INVESTORS, {
          replace: true,
          state: { investorName: investor.data.person.name },
        });
      }
      setSelectedInvestor(investor.data);
    }

    if (investorError?.response?.status === 404) {
      return navigate(Routes.INVESTORS, {
        replace: true,
      });
    }
  }, [navigate, investor, investorError]);

  const closeModal = () => {
    const eventContent = selectedInvestor
      ? {
          investor: selectedInvestor.person.name,
        }
      : {};
    track(ModalEvents.CLICKED_CLOSE, eventContent);
    setSelectedInvestor(undefined);
    if (id) {
      navigate(Routes.INVESTORS, { replace: true });
    }
  };

  const handleFilterChange = (values: { investors?: number[] }) => {
    setSelectedFilters(values);
  };

  const getIntroRequestTrackParams = (
    investor: Investor,
  ): IRequestIntroClickEventParams => {
    return {
      page: Page.INVESTORS,
      requester_id: (currentUser?.data as User).id,
      requester_name: `${(currentUser?.data as User).person.first_name} ${(
        currentUser?.data as User
      ).person.last_name!}`,
      contact_name: investor.person.name || "",
      contact_id: investor.id,
      connections_number: investor.person.connection_strengths.length,
    };
  };

  return (
    <div className="h-full relative overflow-y-auto">
      <StickyHeader>
        <Search
          defaultValue={search}
          onChange={(search) => {
            setSearch(search);
            setCurrentPage(1);
          }}
        />
        <Filters handleFilterChange={handleFilterChange} />
      </StickyHeader>
      {investors?.data?.count === 0 ? (
        search ||
        selectedFilters.investors?.length ||
        selectedFilters.funds?.length ? (
          <div className="flex justify-center p-8">
            <Typography size="body-4">No results found</Typography>
          </div>
        ) : (
          <PageEmptyState
            title="Start by adding your connections"
            description="First, import your LinkedIn connections with the extension."
            callToActionText="Add Connections"
            onClick={() => window.open(CHROME_EXTENSION_LINK, "_blank")}
          />
        )
      ) : (
        <div className="h-table-content flex flex-col justify-between relative">
          <div className="p-6 grid">
            <div className={tw.table}>
              <div className="px-3 py-2">
                <SortableCell
                  cellText="Name"
                  direction={getSort(SortTypes.PERSON_NAME)}
                  onSortClick={sortHandler(SortTypes.PERSON_NAME)}
                />
              </div>
              <div className="px-3 py-2">
                <SortableCell
                  cellText="Funds"
                  direction={getSort(
                    SortTypes.FIRM_AFFILATION_ORGANIZATION_NAME,
                  )}
                  onSortClick={sortHandler(
                    SortTypes.FIRM_AFFILATION_ORGANIZATION_NAME,
                  )}
                />
              </div>
              <div className="px-3 py-2">
                <Typography variant="prominent-1" size="label-3">
                  Connections
                </Typography>
              </div>
              <div className="px-3 py-2"></div>
              <div className="px-3 py-2"></div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              investors?.data?.results?.map((result: Investor) => (
                <div className={cx(tw.table, tw.row)} key={result.id}>
                  <div className="px-3 py-2">
                    <PersonPopover
                      person={result.person}
                      subtext={getTitle({ ...result.person, investor: result })}
                    />
                  </div>
                  <div className="px-3 py-2">
                    {renderCompanyOrFirm(result.firm_affiliation)}
                  </div>
                  <div className="px-3 py-2">
                    <TagsWrapper
                      tags={sortTagsByStrength(
                        result.person.connection_strengths,
                      )}
                      openRequestIntroModal={() => setSelectedInvestor(result)}
                      onClick={() => {
                        track(ActionEvents.CLICKED_USER_PROFILE_CHIP, {
                          user: currentUser,
                        });
                      }}
                    />
                  </div>
                  <ListsPopover
                    currentUserId={currentUser?.data.id}
                    itemId={result.id}
                    personId={result.person.id}
                    savedListIds={new Set(result.saved_list_ids)}
                    page={Page.INVESTORS}
                    queryKey={QUERY_KEYS.INVESTORS}
                    queryParams={{
                      page: currentPage,
                      search,
                      sort,
                      selectedFilters,
                    }}
                  />
                  <div className="px-3 py-2">
                    <Button
                      className="invisible group-hover/item:visible"
                      onPress={() => {
                        const params: IRequestIntroClickEventParams =
                          getIntroRequestTrackParams(result);
                        track(RequestIntroEvents.REQUEST_INTRO_CLICKED, params);
                        setSelectedInvestor(result);
                      }}
                    >
                      Request
                    </Button>
                  </div>
                </div>
              ))
            )}
          </div>
          {!isLoading && (
            <Pagination
              total={investors?.data?.count || 0}
              perPage={DEFAULT_INVESTOR_PAGE_SIZE}
              page={currentPage}
              onPageChange={(pageData) => {
                setCurrentPage(pageData as number);
              }}
            />
          )}
        </div>
      )}
      {selectedInvestor && (
        <RequestIntroModal
          currentUser={currentUser?.data as User}
          targetConnection={{
            ...selectedInvestor.person,
            investor: {
              ...selectedInvestor,
            },
          }}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default InvestorsPage;
