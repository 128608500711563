import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { SavedList } from "src/models/lists-api-response";
import { Person } from "src/models/person-api-response";
import { queryClient } from "src/providers/queryClient";
import { QUERY_KEYS } from "src/queries/query-keys";
import {
  addContactToList,
  getSavedListDetails,
  getSavedListPeople,
  removeContactFromList,
} from "src/services/lists.service";

export const useSavedListDetails = (id: number | string) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.LIST, { id }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getSavedListDetails(accessToken, id);
    },
  });

  return {
    data: data?.data,
    refetch,
    isLoading,
  };
};

export const useSavedList = (
  id: number | string,
  page: number,
  sort: string,
) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, refetch, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.LIST_PEOPLE, { id, page, sort }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getSavedListPeople(accessToken, id, page, sort);
    },
  });

  return {
    data: data,
    refetch,
    isLoading,
    isFetching,
  };
};

export interface ManagePersonFromListMutation {
  list: SavedList;
  page: string;
  person: Person;
  queryToInvalidate?: string;
  pageNumber?: number;
  sort?: string;
}

export const useAddPersonToListMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation({
    mutationFn: async ({ list, person }: ManagePersonFromListMutation) => {
      const accessToken = await getAccessTokenSilently();
      return addContactToList({
        accessToken,
        listId: list.id,
        personId: person.id,
      });
    },
    onSuccess: async (
      _,
      { list, page, queryToInvalidate, pageNumber, sort },
    ) => {
      track(ActionEvents.LIST_PERSON_ADDED, {
        list_name: list.name,
        page,
      });
      toast.success("Added person to list");
      // caching removed since now we need connection_strengths property and it's values
      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.LIST_PEOPLE,
          { id: list.id, page: pageNumber, sort },
        ],
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: [queryToInvalidate],
        refetchType: "all",
      });
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        return toast.error("Person is already in list");
      }
      return toast.error("Error adding person to list");
    },
  });
};

export const useRemovePeopleFromListMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation({
    mutationFn: async ({
      list,
      peopleIds,
    }: {
      peopleIds: number[];
      list: SavedList;
      page: string;
      queryInvalidate?: string;
      pageNumber?: number;
      queryParams?: any;
    }) => {
      const accessToken = await getAccessTokenSilently();
      return removeContactFromList({
        accessToken,
        listId: list?.id,
        peopleIds,
      });
    },
    onSuccess: async (_, { list, page, queryInvalidate, pageNumber }) => {
      track(ActionEvents.LIST_PERSON_REMOVED, {
        list_name: list.name,
        page,
      });
      toast.success("People successfully removed from list");
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LIST_PEOPLE, { id: list.id, page: pageNumber }],
        refetchType: "all",
      });
      await queryClient.invalidateQueries({
        queryKey: [queryInvalidate],
        refetchType: "all",
      });
    },
    onError: (e) => {
      toast.error("Error removing people from list");
    },
  });
};
