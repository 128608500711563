import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import { Routes } from "src/config/routes";

const HomePage = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return null;
  }

  return (
    <Navigate
      replace
      to={isAuthenticated ? Routes.CONNECTIONS : Routes.LOGIN}
    />
  );
};

export default HomePage;
