import { ActionModal, Article, Modal, Typography } from "@50y/celestial";
import Markdown from "markdown-to-jsx";
import { useState } from "react";
import { ChevronRightIcon } from "src/assets/icons/general/ChevronRight";
import dealsImage from "src/assets/images/deals.png";
import { CodaBlocks } from "src/models/strapi-api-response";
import { useMe } from "src/queries/useMe";

const tw = {
  codaCardsWrapper:
    "w-full mt-10 grid grid-cols-deals-grid justify-center gap-6 pb-24 m-auto",
  chip: "px-4 py-2 bg-interactive-tertiary-idle w-fit rounded-xl",
  dealsCTAWrapper: "flex items-center gap-icon-to-label-spacing cursor-pointer",
  dealsCTAIcon: "fill-interactive-text-text-b-primary w-6 h-6",
  dealModalContent: "p-10 grid gap-4 max-h-deal-modal overflow-y-auto",
  textPrimary: "text-interactive-text-text-b-primary",
};

const modalData = {
  title: "Let’s collaborate!",
  description: `As a 50Y founder, if you or someone you know have products or services that you’d 
    like to share with the community please put us in touch via email. You may also offer your own company’s products and services to the 50Y community. If there’s a service that you’d like to see added to this deal page please email us to request a deal.`,
  imageURL: dealsImage,
  primaryAction: {
    label: "Get in Touch",
  },
};

const markdownOptions = {
  overrides: {
    a: (props: any) => (
      <Typography
        as="a"
        {...props}
        target="_blank"
        className={tw.textPrimary}
      />
    ),
    p: (props: any) => (
      <Typography as="p" {...props} variant="default" size="body-4" />
    ),
    span: <Typography variant="default" size="body-4" />,
  },
};

export const DealsCTASection = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { data: me } = useMe();

  const handleDealsCTA = () => {
    const emailData = {
      to: "drew@50y.com",
      subject: "Universe Deals - new partnership request",
      body: `Hi Drew,%0D%0A %0D%0A I saw on Universe we can request new partnerships or deals from Fifty Years. Do you have a discount for [service you're interested in]? %0D%0A %0D%0A Thanks! %0D%0A ${
        me?.data.person.nickname || me?.data.person.name
      }`,
    };
    const href = `mailto:${emailData.to}?body=${emailData.body}&subject=${emailData.subject}`;
    window.open(href);
  };

  return (
    <>
      <div className={tw.dealsCTAWrapper}>
        <Typography
          size="label-2"
          variant="prominent-1"
          className={tw.textPrimary}
          onClick={() => setModalOpen(true)}
        >
          Partnership requests{" "}
        </Typography>
        <ChevronRightIcon className={tw.dealsCTAIcon} />
      </div>

      {modalOpen && (
        <ActionModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          imageURL={modalData.imageURL}
          heading={modalData.title}
          description={modalData.description}
          primaryAction={{
            label: "Get in Touch",
            onPress: handleDealsCTA,
          }}
          type="withImage"
        />
      )}
    </>
  );
};

export const Deals = ({ cards }: { cards: CodaBlocks[] }) => {
  const [modalData, setModalData] = useState<CodaBlocks>();

  return (
    <>
      <div className={tw.codaCardsWrapper}>
        {cards.map((card) => (
          <Article
            size="medium"
            title={card.title}
            image={{ url: card.image?.data.attributes.url ?? dealsImage }}
            key={card.title}
            onClick={() => setModalData(card)}
          >
            <Typography
              variant="default"
              size="body-4"
              className="line-clamp-2 mb-4"
              as="p"
            >
              {card.description}
            </Typography>
            <div className={tw.chip}>
              <Typography variant="default" size="label-3">
                {card.chip.text ?? "Collaboration"}
              </Typography>
            </div>
          </Article>
        ))}
      </div>
      {!!modalData && (
        <Modal
          isOpen={modalData !== undefined}
          onClose={() => setModalData(undefined)}
          type="withImage"
        >
          {modalData.image?.data.attributes.url && (
            <img
              src={modalData.image?.data.attributes.url}
              alt={modalData.title}
              className="w-full h-60 object-cover"
            />
          )}
          <div className={tw.dealModalContent}>
            <div className="grid gap-2">
              <Typography size="title-6" variant="prominent-1">
                {modalData.title}
              </Typography>
              <Typography size="body-4" variant="default">
                {modalData.description}
              </Typography>
            </div>
            {modalData.deal_access && (
              <div className="grid gap-2">
                <Typography size="title-6" variant="prominent-1">
                  How to access deal
                </Typography>
                <Markdown options={markdownOptions}>
                  {modalData?.deal_access || ""}
                </Markdown>
              </div>
            )}
            {modalData.deal_terms && (
              <div className="grid gap-2">
                <Typography size="title-6" variant="prominent-1">
                  Deal terms
                </Typography>
                <Markdown options={markdownOptions}>
                  {modalData?.deal_terms}
                </Markdown>
              </div>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};
