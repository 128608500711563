import {
  Avatar,
  Button,
  Dialog,
  Divider,
  TagButton,
  Typography,
} from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useState } from "react";
import {
  HighStrengthIcon,
  LowStrengthIcon,
  MediumStrengthIcon,
} from "src/assets/icons/general/ConnectionStrengthIcon";
import { ConnectionStrengthEnum } from "src/models/connections-api-response";
import { User } from "src/models/person-api-response";
import { useMe } from "src/queries/useMe";
import {
  connectionStrengthScoreToEnum,
  getDisplayName,
} from "src/utils/helpers";
import { ConnectionStrength } from "./ConnectionStrength";
import Socials from "./Socials";

interface IProps {
  user: User;
  connection_strength?: number;
  is_confident: boolean;
  connection_strength_context?: string;
  onClick?(): void;
  connection?: string;
  openRequestIntroModal: () => void;
}

export const groupHover =
  "group-hover/item:bg-elevation-background group-hover/item:border group-hover/item:border-elevation-outline-0 transition-all duration-300 ease-in-out";

const twClasses = {
  tagName: "flex items-center gap-1-5",
  ariaButton:
    "rounded-xl bg-elevation-surface-1 px-4 py-0-5 outline-none h-8 border border-transparent border-spacing-px group-hover/item:border-elevation-outline-0",
};

const UserTag = ({
  user,
  onClick,
  connection_strength,
  is_confident,
  openRequestIntroModal,
}: IProps) => {
  const { data: me } = useMe();
  const [open, setOpen] = useState(false);

  const connectionName =
    me?.data?.id === user.id
      ? "Me"
      : user.person.nickname
      ? getDisplayName(user.person)
      : user.person.name;

  const connectionEnum = connectionStrengthScoreToEnum(
    connection_strength ?? 0,
  );

  return (
    <Dialog
      isOpen={open}
      onOpenChange={setOpen}
      dialogButton={
        <TagButton
          onPress={() => {
            setOpen(true);
            onClick && onClick();
          }}
          className={cx(twClasses.ariaButton, groupHover)}
          {...(me &&
            me?.data?.id !== user.id &&
            is_confident && {
              icon:
                connectionEnum === ConnectionStrengthEnum.High
                  ? HighStrengthIcon
                  : connectionEnum === ConnectionStrengthEnum.Medium
                  ? MediumStrengthIcon
                  : LowStrengthIcon,
            })}
        >
          {connectionName}
        </TagButton>
      }
      popoverProps={{ className: "!max-w-80" }}
    >
      <div className="grid gap-6">
        <div className="grid gap-4">
          {user.person.profile_picture_url && (
            <Avatar size="large" src={user.person.profile_picture_url} />
          )}
          <div>
            <Typography size="title-5" variant="prominent-1" className="mb-2">
              {getDisplayName(user.person)}
            </Typography>
            <Typography size="body-4" variant="default">
              {user.role}
            </Typography>
            <Typography size="body-4" variant="prominent-2">
              @{user.companies[0].organization.name}
            </Typography>
          </div>
          <Divider />
          {connection_strength && (
            <div className="flex gap-1-5 items-center">
              <ConnectionStrength
                is_confident={is_confident}
                strength={connection_strength}
              />
              <Typography variant="prominent-1" size="body-5">
                {connectionStrengthScoreToEnum(connection_strength)}
              </Typography>
            </div>
          )}
          <Divider />
          {(user.person.linkedin_url || user.person.twitter_url) && (
            <Socials person={user.person} />
          )}
        </div>
        <Button
          onPress={() => {
            setOpen(false);
            openRequestIntroModal();
          }}
        >
          Request Intro
        </Button>
      </div>
    </Dialog>
  );
};

export default UserTag;
