export const SavedListsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="fill-current"
    {...props}
  >
    <path d="M16.2498 16.25H11.2498V17.5H16.2498V16.25Z" />
    <path d="M18.7498 13.75H11.2498V15H18.7498V13.75Z" />
    <path d="M18.7498 11.25H11.2498V12.5H18.7498V11.25Z" />
    <path d="M12.8429 7.01063L9.9998 1.25L7.15668 7.01063L0.799805 7.93437L5.39981 12.4188L4.31355 18.75L8.7498 16.4181V15.0056L5.97418 16.465L6.74293 11.9819L3.48605 8.80687L7.98668 8.15313L9.9998 4.07438L12.0129 8.15313L17.3217 8.92563L17.4998 7.6875L12.8429 7.01063Z" />
  </svg>
);

export const SavedListIconFilled = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 1.5L8.58746 8.415L0.959961 9.5175L6.47996 14.9025L5.17496 22.5L11.5 19.1776V11.5H21.0126L23.04 9.525L15.4125 8.415L12 1.5Z" />
    <path d="M13.5 13.5H22.5V15H13.5V13.5Z" />
    <path d="M22.5 16.5H13.5V18H22.5V16.5Z" />
    <path d="M19.5 19.5H13.5V21H19.5V19.5Z" />
  </svg>
);
