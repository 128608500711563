import { Button, CheckboxCheckmark, Typography } from "@50y/celestial";
import { FC, Fragment, PropsWithChildren, useState } from "react";
import { Link } from "react-aria-components";
import {
  IRequestIntroClickEventParams,
  ModalEvents,
  RequestIntroEvents,
} from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { CompanyPartial, Page } from "src/models/connections-api-response";
import { FirmAffiliation } from "src/models/investors-api-response";
import { Person, User } from "src/models/person-api-response";
import { useMe } from "src/queries/useMe";
import {
  getTitle,
  personIsInvestor,
  personIsLinkedInConnection,
  personIsUser,
  sortTagsByStrength,
} from "src/utils/helpers";
import RequestIntroModal from "./RequestIntroModal";
import { PersonPopover } from "./table/PersonPopover";
import { TagsWrapper } from "./TagsWrapper";

const tw = {
  row: "group/item grid grid-cols-list-grid h-16 border-b border-elevation-outline-0 items-center z-10 hover:bg-elevation-surface-1 transition-background-color duration-300 ease-in-out",
};

interface IPersonRowProps {
  person: Person;
  savedListId: number;
  isSelected: boolean;
  onRowClick: (id: number) => void;
}

export const renderCompanyOrFirm = (
  data: FirmAffiliation[] | CompanyPartial[],
) => (
  <div className="truncate flex items-center flex-wrap">
    {data?.map((item, index) => (
      <Fragment key={item.id}>
        {item.organization.website ? (
          <Link
            href={`//` + item.organization.website}
            target={"_blank"}
            className="text-interactive-text-text-b-primary focus-visible:outline-none"
          >
            <Typography
              variant="default"
              size="body-4"
              className="focus-visible:outline-none"
            >
              {item.organization.name}
            </Typography>
          </Link>
        ) : (
          <Typography
            variant="default"
            size="body-4"
            className="focus-visible:outline-none"
          >
            {item?.organization.display_name ?? item.organization.name}
          </Typography>
        )}
        {index !== data.length - 1 ? ", " : ""}
      </Fragment>
    ))}
  </div>
);

export const PersonRow: FC<PropsWithChildren<IPersonRowProps>> = (props) => {
  const { data: currentUser } = useMe();
  const { person, isSelected, onRowClick } = props;
  const { track } = useAmplitudeTrack();
  const [isRequestIntroModalVisible, setIsRequestIntroModalVisible] =
    useState(false);

  const isInvestor = personIsInvestor(person);
  const isLinkedInConnection = personIsLinkedInConnection(person);
  const isUser = personIsUser(person);

  const title = getTitle(person);

  const company = isInvestor
    ? renderCompanyOrFirm(
        person.investor!.firm_affiliation.length > 0
          ? person.investor!.firm_affiliation
          : ([
              { organization: person.current_organization },
            ] as CompanyPartial[]),
      )
    : isUser
    ? renderCompanyOrFirm(
        person.user!.companies.length > 0
          ? person.user!.companies
          : ([
              { organization: person.current_organization },
            ] as CompanyPartial[]),
      )
    : person.current_organization
    ? renderCompanyOrFirm([
        { organization: person.current_organization },
      ] as CompanyPartial[])
    : isLinkedInConnection
    ? null // We don't have company information on the Person or LinkedInConnection object.
    : null;

  const handleOnModalClick = () => {
    const params: IRequestIntroClickEventParams = {
      page: Page.SAVED_LISTS,
      requester_id: currentUser?.data?.id || 0,
      requester_name: `${(person as Person).first_name} ${
        (currentUser?.data as User).person.last_name
      }`,
      contact_name: `${person.first_name} ${person.last_name}`,
      contact_id: person.id,
    };

    track(RequestIntroEvents.REQUEST_INTRO_CLICKED, params);
  };

  const handleOnModalClose = () => {
    const eventContent = person
      ? {
          connection: `${person.first_name} ${person.last_name}`,
        }
      : {};
    track(ModalEvents.CLICKED_CLOSE, eventContent);
  };

  return (
    <>
      <div className={tw.row}>
        <div
          className="px-3 py-2 group cursor-pointer"
          onClick={() => onRowClick(person.id)}
          {...(isSelected && { "data-selected": true })}
        >
          <CheckboxCheckmark variant="default" />
        </div>
        <div className="px-3 py-2">
          <PersonPopover person={person} subtext={title} />
        </div>
        <div className="truncate">{company}</div>
        <div className="px-3 py-2">
          <TagsWrapper
            tags={sortTagsByStrength(person.connection_strengths)}
            openRequestIntroModal={() => {
              setIsRequestIntroModalVisible(true);
            }}
            onClick={() => {
              handleOnModalClick();
            }}
          />
        </div>
        <div className="px-3 py-2">{props.children}</div>
        <div className="px-3 py-2">
          <Button
            variant="primary"
            className="hidden group-hover/item:block"
            onPress={() => setIsRequestIntroModalVisible(true)}
          >
            Request
          </Button>
        </div>
      </div>
      {isRequestIntroModalVisible && (
        <RequestIntroModal
          currentUser={currentUser?.data as User}
          targetConnection={person}
          savedListId={props.savedListId}
          onClose={() => {
            setIsRequestIntroModalVisible(false);
            handleOnModalClose();
          }}
        />
      )}
    </>
  );
};
