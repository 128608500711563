import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { sendAmplitudeEvents } from "src/services/global.service";
import { IAmplitudeTrack } from "./useAmplitudeTrack";

export const useBackendAmplitude = () => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation({
    mutationFn: async (events: IAmplitudeTrack[]) => {
      const accessToken = await getAccessTokenSilently();
      return sendAmplitudeEvents({ accessToken, events });
    },
  });
};
