import { ThemeProvider } from "@50y/celestial";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Outlet } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { URLParamsProvider } from "src/context";
import { AmplitudeProvider } from "./AmplitudeProvider";

const theme = {
  primary: "#ff1c24ff",
  "primary-hover": "#ff6469ff",
  "primary-disabled": "#ffc5c7ff",
  "primary-pressed": "#ff9da0ff",
  "text-default": "#565c61ff",
  "icon-hover": "#c80d14ff",
  "icon-pressed": "#881418ff",
};

/**
 * All these providers are dependant on the Auth0Provider
 * @see https://auth0.com/docs/quickstart/spa/react/01-login#configure-auth0provider
 * @returns providers for the app
 */

const Providers: React.FC = () => {
  const { user } = useAuth0();
  return (
    <AmplitudeProvider user={user}>
      <URLParamsProvider>
        <ThemeProvider theme={theme}>
          <Outlet />
        </ThemeProvider>
      </URLParamsProvider>
    </AmplitudeProvider>
  );
};

export default Providers;
