import { AxiosRequestConfig } from "axios";
import {
  GenericApiResponse,
  PaginatedApiResponse,
} from "src/models/api-response";
import { Attendee, Event, EventStatus } from "src/models/events-api-response";
import { callExternalApi } from "./callExternalApi";
import { API_SERVER_URL, getHeaders } from "./global.service";

export const getEvents = async (
  accessToken: string,
): Promise<GenericApiResponse<PaginatedApiResponse<Event>>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/events`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  const { data, error } = (await callExternalApi({
    config,
  })) as GenericApiResponse<PaginatedApiResponse<Event>>;

  return {
    data,
    error,
  };
};

export const updateEventAttendee = async ({
  accessToken,
  eventId,
  status,
}: {
  accessToken: string;
  eventId: number;
  status: EventStatus;
}): Promise<GenericApiResponse<Attendee>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/events/${eventId}/attend/${status}`,
    method: "POST",
    headers: getHeaders(accessToken),
  };

  return (await callExternalApi({
    config,
  })) as GenericApiResponse<Attendee>;
};
