import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { FilterReturnType } from "src/components/Filters";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getInvestors } from "src/services/investor.service";

export const useInvestors = ({
  page,
  search,
  sort,
  selectedFilters,
}: {
  page: number;
  search: string;
  sort?: string;
  selectedFilters: FilterReturnType;
}) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [QUERY_KEYS.INVESTORS, { page, search, sort, selectedFilters }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getInvestors({
        accessToken,
        page,
        search,
        sort,
        investorIds: selectedFilters.investors,
        fundIds: selectedFilters.funds,
        connectionIds: selectedFilters.connections,
      });
    },
  });
};
