import { ActionModal, Typography } from "@50y/celestial";
import { useCallback, useState } from "react";
import dataImg from "src/assets/images/data.png";

const ModalDescription = () => {
  return (
    <div className="text-left">
      <Typography variant="default" size="body-4">
        This tool and community are built to serve 50Y portfolio founders.
      </Typography>
      <Typography variant="default" size="body-4">
        Our promise to you:
      </Typography>
      <ul className="mt-4 list-disc ml-4">
        <li>Connections data is only collected to improve the network graph</li>
        <li>You are in control, and can delete your data at any time</li>
        <li>We take privacy seriously. Your data is secure.</li>
      </ul>
    </div>
  );
};

const ConnectingStep = () => {
  const [showModal, setShowModal] = useState(false);
  const onClose = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <>
      {showModal && (
        <ActionModal
          isOpen={showModal}
          onClose={onClose}
          imageURL={dataImg}
          type="withImage"
          heading="How your data will be used"
          description={<ModalDescription />}
          primaryAction={{
            label: "Back",
            onPress: onClose,
          }}
        />
      )}
      <Typography variant="default" size="body-4">
        Share connections, find the right investors. Our collective network is a
        great asset — for fundraising, business development, and mentorship. By
        contributing contacts, we uncover opportunities for one another.{" "}
        <span
          className="text-interactive-text-text-b-primary cursor-pointer"
          onClick={() => setShowModal(true)}
        >
          Learn more
        </span>
      </Typography>
    </>
  );
};

export default ConnectingStep;
