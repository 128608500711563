import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { ModalType } from "src/components/RequestIntroModal";
import { queryClient } from "src/providers/queryClient";
import { QUERY_KEYS } from "src/queries/query-keys";
import { addIntroRequest } from "src/services/intro-request.service";

export type RequestIntroMutationProps = {
  type: ModalType | undefined;
  user_id: number;
  connection_id?: number;
  person_id?: number;
  subject: string;
  email_content: string;
  organization_id?: number;
  saved_list_id?: number;
};

export const useIntroRequestMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation({
    mutationFn: async (variables: RequestIntroMutationProps) => {
      const accessToken = await getAccessTokenSilently();
      addIntroRequest({
        accessToken,
        ...variables,
      });
    },
    onSuccess: (_, variables) => {
      if (variables.saved_list_id) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.LIST, { id: variables.saved_list_id }],
        });
      }
      if (variables.type) {
        queryClient.invalidateQueries({
          queryKey:
            variables.type === ModalType.INVESTOR
              ? [QUERY_KEYS.INVESTORS]
              : [QUERY_KEYS.CONNECTIONS],
        });
      } else {
        // If no type passed, invalidate both queries
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.INVESTORS],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.CONNECTIONS],
        });
      }
    },
  });
};
