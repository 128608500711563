import { Dialog, TextButton, Typography } from "@50y/celestial";
import { FC, useRef, useState } from "react";
import { CheckIcon } from "src/assets/icons/general/CheckIcon";
import { PlusIcon } from "src/assets/icons/general/PlusIcon";
import StarIconButton from "src/components/buttons/StarIconButton";
import { Page } from "src/models/connections-api-response";
import { useAddContactToListMutation } from "src/queries/lists/useAddContactToListMutation";
import { useCreateListMutation } from "src/queries/lists/useCreateListMutation";
import { useListsInfinite } from "src/queries/lists/useLists";
import { useRemovePeopleFromListMutation } from "src/queries/lists/useRemovePeopleFromListMutation";
import { FilterReturnType } from "./Filters/Filters";
import { ManageListModal } from "./modals/ManageListModal";

interface IProps {
  savedListIds: Set<number>;
  queryKey: string;
  queryParams: Record<string, string | number | FilterReturnType>;
  page: Page;
  currentUserId: number | undefined;
  itemId: number;
  personId: number;
  onChange?: () => void;
}

const tw = {
  lists: "max-h-80 overflow-y-auto no-scrollbar",
  list: "cursor-pointer px-4 py-2 hover:bg-interactive-text-text-hover flex gap-2 items-center border-t border-elevation-outline-1 first:border-t-0",
  loading: "px-4 py-2",
  createList:
    "!justify-start border-t border-elevation-outline-1 w-full text-interactive-text-text-b-primary px-4 h-10 sticky bottom-0 bg-elevation-background rounded-md rounded-t-none focus-visible:outline-none hover:bg-elevation-surface-1 transition-background-color duration-300 ease-in-out",
};

export const ListsPopover: FC<IProps> = ({
  savedListIds,
  queryKey,
  queryParams,
  page,
  currentUserId,
  itemId,
  personId,
  onChange,
}) => {
  const { data: lists, fetchNextPage, isFetchingNextPage } = useListsInfinite();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { reset, mutate: createListMutation } = useCreateListMutation();

  const { mutate: addContactToList } = useAddContactToListMutation(queryKey);

  const { mutate: removeContactFromList } = useRemovePeopleFromListMutation({
    shouldInvalidate: false,
    queryKey,
  });

  const handleListsUpdate = (id: number) => {
    setIsPopoverOpen(false);
    // selectedListName is used only for Amplitude tracking
    const selectedListName = lists?.pages
      ?.flatMap((page) => page.data.results)
      .find((list) => list.id === id)?.name;

    const params = {
      currentUserId: currentUserId as number,
      listId: id,
      personId,
      itemId,
      page,
      listName: selectedListName ?? "",
      queryParams,
    };

    if (savedListIds?.has(id)) {
      removeContactFromList({ ...params, peopleIds: [personId] });
    } else {
      addContactToList({ ...params });
    }

    if (onChange) onChange();
  };

  const handleNewListCreation = (name: string) => {
    createListMutation(
      { name },
      {
        onSuccess: (result, variables) => {
          if (result?.data?.id) {
            const params = {
              currentUserId: currentUserId as number,
              listId: result.data.id,
              personId,
              itemId,
              page,
              listName: variables.name ?? "",
              queryParams,
            };
            addContactToList({ ...params });
          }
        },
      },
    );
    setIsModalOpen(false);
  };

  const handleListScroll = (e: React.UIEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      fetchNextPage();
    }
  };

  const handleToggleModal = () =>
    setIsModalOpen((isModalOpen) => {
      if (!isModalOpen) reset();
      return !isModalOpen;
    });

  return (
    <>
      <Dialog
        dialogButton={
          <StarIconButton
            isSaved={!!savedListIds.size}
            onClick={() => setIsPopoverOpen(true)}
          />
        }
        popoverProps={{
          scrollRef,
          className: "py-0 px-0",
          isOpen: isPopoverOpen,
          onOpenChange: () => setIsPopoverOpen(false),
        }}
      >
        <div onScroll={handleListScroll} ref={scrollRef} className={tw.lists}>
          {lists?.pages.map((page) =>
            page.data.results.map((list) => (
              <div
                key={list.id}
                onClick={() => handleListsUpdate(list.id)}
                className={tw.list}
              >
                {savedListIds.has(list.id) ? (
                  <CheckIcon className="fill-interactive-text-text-b-primary" />
                ) : (
                  <div className="w-6 h-6" />
                )}
                <Typography
                  variant="default"
                  size="label-2"
                  className="text-interactive-text-text-b-secondary flex-1"
                >
                  {list.name}
                </Typography>
              </div>
            )),
          )}
          {isFetchingNextPage && (
            <Typography variant="default" size="body-5" className={tw.loading}>
              Loading...
            </Typography>
          )}
          <TextButton
            onPress={handleToggleModal}
            icon={PlusIcon}
            color="primary"
            className={tw.createList}
          >
            Create new list
          </TextButton>
        </div>
      </Dialog>
      {isModalOpen && (
        <ManageListModal
          manageList={handleNewListCreation}
          toggleOpenState={handleToggleModal}
        />
      )}
    </>
  );
};
