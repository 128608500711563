import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Entities } from "src/models/imports-api-response";
import { syncAirtable } from "src/services/imports.service";

type MutationVariables = {
  entity: Entities;
  title?: string;
};

export const useSyncAirtableMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  const mutationFn = async ({ entity, title }: MutationVariables) => {
    const accessToken = await getAccessTokenSilently();
    return syncAirtable(accessToken, entity);
  };

  return useMutation<
    AxiosResponse<{
      count: number;
      created: { count: number };
      updated: { count: number };
    }>,
    AxiosError,
    MutationVariables
  >({
    mutationFn,
    onSuccess({ data }, variables) {
      toast.success(
        `Synced with Airtable: ${variables.title}. Updated ${data.updated.count} and created ${data.created.count} ${variables.entity}`,
      );
    },
    onError(error, variables) {
      toast.error(
        `Error syncing ${variables.entity} with Airtable: ${error.message}`,
      );
    },
  });
};
