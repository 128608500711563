import { TextInput } from "@50y/celestial";
import { useMemo, useState } from "react";
import { SettingsEvents } from "src/config/amplitudeEvents";
import { useDebouncedEffect, useTrackFormContentChange } from "src/hooks";
import { Company } from "src/models/connections-api-response";
import { DEBOUNCE_TIMEOUT_MS } from "src/pages/SettingsPage/SettingsPage";
import { useCompanyMutation } from "src/queries/companies/useCompanyMutation";
import { useMe } from "src/queries/useMe";
import { getCompanyName } from "src/utils/helpers";

export interface CompanyProps {
  display_name?: string;
  blurb?: string;
  next_stage?: string;
  id: number;
}

export type CompanyFormProps = Omit<CompanyProps, "id">;

const CompanyForm = ({ company }: { company: Company }) => {
  const { refetch: refetchUser } = useMe();
  const [selectedCompany, setCompany] = useState<CompanyProps>({
    id: company.id ?? 0,
    display_name: getCompanyName(company),
    next_stage: company.next_stage ?? "",
    blurb: company.blurb ?? "",
  });
  const { mutate: handleSave } = useCompanyMutation();

  const isTouched = useMemo(() => {
    return (
      company.blurb !== selectedCompany.blurb ||
      getCompanyName(company) !== selectedCompany.display_name ||
      company.next_stage !== selectedCompany.next_stage
    );
  }, [company, selectedCompany]);

  useDebouncedEffect(
    () => {
      (async () => {
        if (isTouched && selectedCompany) {
          handleSave({
            id: selectedCompany.id,
            updates: {
              next_stage: selectedCompany.next_stage,
              display_name: selectedCompany.display_name,
            },
          });
          refetchUser();
        }
      })();
    },
    DEBOUNCE_TIMEOUT_MS,
    [isTouched, selectedCompany?.display_name, selectedCompany.next_stage],
  );

  const { onBlur: onSubjectBlur, onFocus: onSubjectFocus } =
    useTrackFormContentChange({
      initialValue: selectedCompany.display_name || "",
      value: selectedCompany.display_name as string,
      event: SettingsEvents.CHANGED_COMPANY_NAME,
    });

  const { onBlur: onNextStageBlur, onFocus: onNextStageFocus } =
    useTrackFormContentChange({
      initialValue: selectedCompany.next_stage || "",
      value: selectedCompany.next_stage as string,
      event: SettingsEvents.CHANGED_FUNDRAISE_ROUND,
    });

  return (
    <>
      <TextInput
        value={selectedCompany.display_name || ""}
        onChange={(e) => {
          setCompany((v) => ({ ...v, display_name: e.target.value }));
        }}
        onBlur={onSubjectBlur}
        onFocus={onSubjectFocus}
        variant="filled"
        label="Company Name"
      />
      <TextInput
        value={selectedCompany.next_stage || ""}
        onChange={(e) => {
          setCompany((v) => ({ ...v, next_stage: e.target.value }));
        }}
        onBlur={onNextStageBlur}
        onFocus={onNextStageFocus}
        variant="filled"
        label="Next Company Stage"
      />
    </>
  );
};

export default CompanyForm;
