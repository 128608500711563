import { Avatar, Divider, LazyImage, Modal, Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { CalendarIcon } from "src/assets/icons/general/CalendarIcon";
import { Event, EventTypes } from "src/models/events-api-response";
import { getEventDateAndTime } from "src/utils/helpers";
import { EventCardAttendance } from "./EventCardAttendance";
import { EventCTARow } from "./EventCTARow";
import { row } from "./styles";
import { useInvitedBy } from "./useInvitedBy";

export interface ExtendedEvent extends Event {
  isPastEvent: boolean;
}

interface IProps {
  onModalClose: () => void;
  event: ExtendedEvent;
  currentUserId: number;
}

const tw = {
  modalWrapper: "flex flex-col gap-4 overflow-y-auto",
  eventCardTime: "text-interactive-text-text-b-primary mb-2",
};

export const EventModal = ({ onModalClose, event, currentUserId }: IProps) => {
  const { invitedBy } = useInvitedBy({
    attendees: event.attendees,
    currentUserId,
  });

  return (
    <Modal
      isOpen={true}
      onClose={onModalClose}
      type={event.photo ? "withImage" : "default"}
    >
      {event.photo && (
        <div className="h-60 w-full overflow-hidden">
          <LazyImage imageSrc={event.photo} altText={event.name} />
        </div>
      )}
      <div
        className={cx(
          tw.modalWrapper,
          event.photo && "p-10 pb-2",
          event.photo ? "max-h-[60vh]" : "max-h-[90vh]",
        )}
      >
        <div>
          {event.isPastEvent ? (
            <EventCardAttendance event={event} className="mb-2" />
          ) : (
            <Typography
              size="label-3"
              variant="prominent-1"
              className={cx(tw.eventCardTime, row)}
            >
              <CalendarIcon className="w-5 h-5" />
              {getEventDateAndTime(event.start_datetime)}
            </Typography>
          )}

          <Typography size="title-4" variant="prominent-1">
            {event.name}
          </Typography>
        </div>
        <Typography size="body-4" variant="default">
          {event.details}
        </Typography>
        {event.isPastEvent && <Divider />}
        {event.isPastEvent && (
          <div>
            <Typography size="label-3" variant="prominent-1" className="mb-1">
              Event date
            </Typography>
            <Typography size="body-4" variant="prominent-2">
              {getEventDateAndTime(event.start_datetime)}
            </Typography>
          </div>
        )}

        <Divider />
        <div>
          <Typography size="label-3" variant="prominent-1" className="mb-1">
            Location
          </Typography>
          <a href={event.location_url} target="_blank" rel="noreferrer">
            <Typography
              size="body-4"
              variant="prominent-2"
              className="text-interactive-text-text-b-primary"
            >
              {event.event_type === EventTypes.DEFAULT
                ? event.location
                : "Virtual"}
            </Typography>
          </a>
        </div>
        {invitedBy && <Divider />}
        {invitedBy && (
          <div>
            <Typography size="label-3" variant="prominent-1" className="mb-1">
              Invited by
            </Typography>
            <Typography size="body-4" variant="prominent-2">
              {invitedBy.name}
            </Typography>
          </div>
        )}
        {!event.isPastEvent && (
          <EventCTARow
            onActionClick={onModalClose}
            event={event}
            className="mt-6"
          />
        )}
        {event.isPastEvent &&
          event.is_current_user_attendee &&
          !!event.attendees.length && (
            <>
              <Divider />
              <Typography size="label-3" variant="prominent-1" className="mb-1">
                Attended with
              </Typography>
              <div>
                {event.attendees
                  .filter((user) => user.user.id !== currentUserId)
                  .map((attendee, index) => (
                    <div
                      key={event.id + index}
                      className="flex flex-row gap-2 items-center py-1"
                    >
                      <Avatar
                        src={
                          attendee.user.person.profile_picture_url ?? undefined
                        }
                      />
                      <Typography size="body-4" variant="prominent-2">
                        {attendee.user.person.name}
                      </Typography>
                    </div>
                  ))}
              </div>
            </>
          )}
      </div>
    </Modal>
  );
};
