import { Article, Typography } from "@50y/celestial";
import React, { useContext, useEffect } from "react";
import { Link, Outlet, useLoaderData, useLocation } from "react-router-dom";
import connectingImage from "src/assets/images/connecting.png";
import { StickyHeader } from "src/components/StickyHeader";
import { Routes } from "src/config/routes";
import { GenericApiResponse } from "src/models/api-response";
import {
  FounderResources,
  GeneralStrapiResponse,
} from "src/models/strapi-api-response";
import {
  ActiveSectionContext,
  ActiveSectionProvider,
} from "./ResourcePage/context";
import { FounderResourcesSearch } from "./ResourcePage/FounderResourcesSearch";

export const tw = {
  container: "h-full bg-elevation-surface-1 overflow-y-auto",
  pageWrapper:
    "p-6 grid grid-cols-resources-grid gap-6 w-full justify-center m-auto",
  arrow: "w-6 h-6 ml-1 fill-interactive-text-text-b-primary",
};

const ResourcesPage: React.FC = () => {
  const { hash, pathname } = useLocation();
  const { setActiveSection } = useContext(ActiveSectionContext);
  const { data: founderContent } = useLoaderData() as GenericApiResponse<
    GeneralStrapiResponse<FounderResources>
  >;

  useEffect(() => {
    if (!hash) {
      setActiveSection("");
      window?.scrollTo({ top: 0 });
    }
  }, [hash, setActiveSection]);

  return (
    <div className={tw.container}>
      {pathname === Routes.RESOURCES && (
        <div>
          <StickyHeader>
            <FounderResourcesSearch />
          </StickyHeader>
          <div className={tw.pageWrapper}>
            {founderContent?.data.map((page) => (
              <Link to={page.attributes.slug} key={page.id}>
                <Article
                  size="default"
                  image={{
                    url:
                      page.attributes.image?.data?.attributes?.url ??
                      connectingImage,
                    alt: page.attributes.image?.data?.attributes
                      ?.alternativeText,
                  }}
                  title={page.attributes.name}
                >
                  <Typography
                    variant="default"
                    size="body-4"
                    className="pt-2 line-clamp-2"
                  >
                    {page.attributes.subtitle}
                  </Typography>
                </Article>
              </Link>
            ))}
          </div>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export const ResourcePageWithProvider = () => (
  <ActiveSectionProvider>
    <ResourcesPage />
  </ActiveSectionProvider>
);
