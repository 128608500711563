import type { SVGProps } from "react";

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <g clipPath="url(#clip0_5613_2109)">
      <path
        d="M16.05 16.5429L12.3536 12.8464L12 12.4929L11.6464 12.8464L7.95 16.5429L7.45711 16.05L11.1536 12.3536L11.5071 12L11.1536 11.6464L7.45711 7.95L7.95 7.45711L11.6464 11.1536L12 11.5071L12.3536 11.1536L16.05 7.45711L16.5429 7.95L12.8464 11.6464L12.4929 12L12.8464 12.3536L16.5429 16.05L16.05 16.5429ZM12 1C5.87386 1 1 5.87386 1 12C1 18.1261 5.87386 23 12 23C18.1261 23 23 18.1261 23 12C23 5.87386 18.1261 1 12 1Z"
        fill="#4A5054"
        stroke="#E7E7E9"
      />
    </g>
    <defs>
      <clipPath id="clip0_5613_2109">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
