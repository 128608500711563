import { Button, Pagination, Typography } from "@50y/celestial";
import { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ListsPopover } from "src/components/ListsPopover";
import { Loader } from "src/components/Loader";
import PageEmptyState from "src/components/PageEmptyState";
import RequestIntroModal from "src/components/RequestIntroModal";
import Search from "src/components/Search";
import { StickyHeader } from "src/components/StickyHeader";
import { PersonPopover } from "src/components/table/PersonPopover";
import { SortableCell } from "src/components/table/SortableCell";
import { TagsWrapper } from "src/components/TagsWrapper";
import {
  ActionEvents,
  IRequestIntroClickEventParams,
  ModalEvents,
  NavigationEvents,
  RequestIntroEvents,
} from "src/config/amplitudeEvents";
import { CHROME_EXTENSION_LINK } from "src/config/constants";
import {
  useAmplitudeTrack,
  useSort,
  useTrackPageVisited,
  useTrackSearch,
} from "src/hooks";
import {
  LinkedinConnection,
  Page,
  UserType,
} from "src/models/connections-api-response";
import { User } from "src/models/person-api-response";
import { useLinkedinConnections } from "src/queries/connections/useLinkedinConnections";
import { QUERY_KEYS } from "src/queries/query-keys";
import { useMe } from "src/queries/useMe";
import { DEFAULT_PAGE_SIZE } from "src/services/global.service";
import { SortTypes } from "src/utils/constants";
import { getUserFirstAndLastName } from "src/utils/getUserFirstAndLastName";
import { getTitle, sortTagsByStrength } from "src/utils/helpers";

const ConnectionsPage = () => {
  const { track } = useAmplitudeTrack();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [selectedConnection, setSelectedConnection] = useState<
    LinkedinConnection | undefined
  >();
  const { data: user } = useMe();
  const { sort, getSort, sortHandler } = useSort(SortTypes.PERSON_NAME);
  const {
    data: connections,
    refetch: refetchConnections,
    isLoading,
    error,
  } = useLinkedinConnections(currentPage, search, sort);

  useTrackSearch({
    search,
    data: connections?.data,
    currentPage,
    scope: UserType.CONNECTION,
    error,
    isLoading,
  });

  useTrackPageVisited(NavigationEvents.ALL_CONNECTIONS_PAGE_VIEWED);

  const onChangePage = (page: number | string) => {
    track(ActionEvents.CLICKED_PAGINATION, { page });
    setCurrentPage(Number(page));
  };

  const closeModal = () => {
    const eventContent = selectedConnection
      ? {
          connection: getUserFirstAndLastName(selectedConnection),
        }
      : {};
    track(ModalEvents.CLICKED_CLOSE, eventContent);
    setSelectedConnection(undefined);
  };

  return (
    <div className="h-full relative overflow-y-auto">
      <StickyHeader>
        <Search
          defaultValue={search}
          onChange={(search) => {
            setSearch(search);
            setCurrentPage(1);
          }}
        />
      </StickyHeader>
      {connections?.data.count === 0 ? (
        search ? (
          <div className="flex justify-center p-8">
            <Typography size="body-4">
              No results found for <strong>{search}</strong>
            </Typography>
          </div>
        ) : (
          <PageEmptyState
            title="Start by adding your connections"
            description="First, import your LinkedIn connections with the extension."
            callToActionText="Add Connections"
            onClick={() => window.open(CHROME_EXTENSION_LINK, "_blank")}
          />
        )
      ) : (
        <div className="h-table-content flex flex-col justify-between relative">
          <div className="p-6 grid">
            <div className="grid grid-cols-connections-grid h-16 border-b border-elevation-outline-0 items-center">
              <div className="px-3 py-2">
                <SortableCell
                  cellText="Name"
                  direction={getSort(SortTypes.PERSON_NAME)}
                  onSortClick={sortHandler(SortTypes.PERSON_NAME)}
                />
              </div>
              <div className="px-3 py-2">
                <Typography variant="prominent-1" size="label-3">
                  Connections
                </Typography>
              </div>
              <div className="px-3 py-2"></div>
              <div className="px-3 py-2"></div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              connections?.data.results.map((result: LinkedinConnection) => (
                <div
                  className="group/item grid grid-cols-connections-grid h-16 border-b border-elevation-outline-0 items-center z-10 hover:bg-elevation-surface-1 transition-background-color duration-300 ease-in-out"
                  key={result.id}
                >
                  <div className="px-3 py-2">
                    <PersonPopover
                      person={result.person}
                      subtext={getTitle({
                        ...result.person,
                        linkedinconnection: result,
                      })}
                    />
                  </div>
                  <div className="px-3 py-2">
                    <TagsWrapper
                      tags={sortTagsByStrength(
                        result.person.connection_strengths,
                      )}
                      openRequestIntroModal={() =>
                        setSelectedConnection(result)
                      }
                      onClick={() => {
                        track(ActionEvents.CLICKED_USER_PROFILE_CHIP, {
                          user,
                        });
                      }}
                    />
                  </div>
                  <ListsPopover
                    savedListIds={new Set(result.saved_list_ids)}
                    currentUserId={user?.data.id}
                    queryKey={QUERY_KEYS.CONNECTIONS}
                    page={Page.ALL_CONNECTIONS}
                    itemId={result.id}
                    personId={result.person.id}
                    onChange={() => {
                      refetchConnections();
                    }}
                    queryParams={{
                      page: currentPage,
                      search,
                      sort,
                    }}
                  />
                  <div className="px-3 py-2">
                    <Button
                      className="invisible group-hover/item:visible"
                      onPress={() => {
                        const params: IRequestIntroClickEventParams = {
                          page: Page.ALL_CONNECTIONS,
                          requester_id: user?.data.id as number,
                          requester_name: getUserFirstAndLastName(
                            user?.data as User,
                          ),
                          contact_name: getUserFirstAndLastName(result),
                          contact_id: result.id,
                          connections_number:
                            result.person.connection_strengths.length,
                        };

                        track(RequestIntroEvents.REQUEST_INTRO_CLICKED, params);
                        setSelectedConnection(result);
                      }}
                    >
                      Request
                    </Button>
                  </div>
                </div>
              ))
            )}
          </div>
          {!isLoading && (
            <Pagination
              total={connections?.data?.count || 0}
              perPage={DEFAULT_PAGE_SIZE}
              page={currentPage}
              onPageChange={onChangePage}
            />
          )}
        </div>
      )}
      {selectedConnection && (
        <RequestIntroModal
          currentUser={user?.data as User}
          targetConnection={selectedConnection.person}
          onClose={closeModal}
        ></RequestIntroModal>
      )}
    </div>
  );
};

export default ConnectionsPage;
