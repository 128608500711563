import { useEffect, useState } from "react";
import { debounce } from "src/utils/debounce";

export const useWindowSize = () => {
  function getSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize());

  useEffect(() => {
    // Debounce to avoid the function firing multiple times
    const handleResizeDebounced = debounce(() => {
      setWindowSize(getSize());
    }, 1000);

    window.addEventListener("resize", handleResizeDebounced);
    return () => window.removeEventListener("resize", handleResizeDebounced);
  }, []);

  return { windowSize };
};
