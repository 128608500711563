import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { queryClient } from "src/providers/queryClient";
import { createList } from "src/services/lists.service";
import { QUERY_KEYS } from "../query-keys";

type Variables = { name: string };

export const useCreateListMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation<
    AxiosResponse<{ id: number; message: string }>,
    AxiosError<{ message: string }>,
    Variables
  >({
    mutationFn: async ({ name }) => {
      const accessToken = await getAccessTokenSilently();
      return createList({ accessToken, name });
    },
    onSuccess: async (result, variables) => {
      track(ActionEvents.LIST_CREATED, { list_name: variables.name });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LISTS, { page: 1 }],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.LISTS_INFINITE],
      });
      toast.success(result.data.message || "New list successfully created");
    },
    onError: () => {
      toast.error("List creation failed");
    },
  });
};
