import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { useDebouncedValue, usePageURLParams } from "src/hooks";
import { URLParams } from "src/utils/constants";

export type URLParamsContextType = {
  searchValue: string;
  setSearchValue: (id: string) => void;
};

export const URLParamsContext = createContext<URLParamsContextType>({
  searchValue: "",
  setSearchValue: () => null,
});

export const URLParamsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { setSearchParams, getSearchParam } = usePageURLParams();
  const [searchValue, setSearchValue] = useState<string>("");
  const { pathname } = useLocation();

  const searchParam = getSearchParam(URLParams.SEARCH);

  useEffect(() => {
    setSearchValue(""); // clear search value on route change
  }, [pathname]);

  useDebouncedValue(searchValue, {
    delay: 500,
    onDebounce: () => {
      if (searchParam !== searchValue)
        setSearchParams({ [URLParams.SEARCH]: searchValue }, { replace: true });
    },
  });

  const providerValue = {
    searchValue,
    setSearchValue,
  };

  return (
    <URLParamsContext.Provider value={providerValue}>
      {children}
    </URLParamsContext.Provider>
  );
};
