import { Layout } from "src/components/Layout";
import { PageError } from "src/components/PageError";
import { ProtectedRoute } from "src/components/ProtectedRoute";
import {
  founderResourcesLoader,
  singleFounderResourceLoader,
} from "src/loaders/founderResourcesLoaders";
import AddConnectionsPage from "src/pages/AddConnectionsPage";
import { CallbackPage } from "src/pages/CallbackPage";
import ConnectionsPage from "src/pages/ConnectionsPage";
import EmailFollowupPage from "src/pages/EmailFollowupPage";
import ErrorPage from "src/pages/ErrorPage";
import { EventsPage } from "src/pages/EventsPage";
import HomePage from "src/pages/HomePage";
import InvestorsPage from "src/pages/InvestorsPage";
import ListPage from "src/pages/ListPage";
import ListsPage from "src/pages/ListsPage";
import LoginPage from "src/pages/LoginPage";
import { MobilePage } from "src/pages/MobilePage";
import NotAllowedPage from "src/pages/NotAllowedPage";
import { NotFoundPage } from "src/pages/NotFoundPage";
import { ResourcePage } from "src/pages/ResourcePage/ResourcePage";
import { ResourcePageWithProvider } from "src/pages/ResourcesPage";
import SettingsPage from "src/pages/SettingsPage/SettingsPage";
import TermsPage from "src/pages/TermsPage";
import Auth0ProviderWithRedirectCallback from "src/providers/AuthLayer";
import Providers from "src/providers/Providers";
import { queryClient } from "src/providers/queryClient";

/**
 * Enum representing the available routes in the application.
 */
export enum Routes {
  HOME_PAGE = "/",
  LOGIN = "/login",
  NOT_ALLOWED = "/not-allowed",
  CALLBACK = "/callback",
  NOT_FOUND = "/not-found",
  NOT_EXISTING_PAGE = "*",
  CONNECTIONS = "/connections",
  INVESTORS = "/investors",
  INVESTORS_ID_INTRO = ":id/intro",
  TERMS = "/tac",
  SETTINGS = "/settings",
  RESOURCES = "/resources",
  RESOURCES_COMPANY_BLURB = "/resources/fundraising-playbook/templates/emails/company-blurb",
  ADD_CONNECTIONS = "/add-connections",
  EVENTS = "/events",
  LISTS = "/lists",
  LIST = "/lists/:list_id",
  EMAIL_FOLLOWUP = "/email_followup",
  MOBILE = "/mobile",
}

export const routeNames = {
  [Routes.HOME_PAGE]: {
    name: "Home",
  },
  [Routes.NOT_ALLOWED]: {
    name: "Not Allowed",
  },
  [Routes.NOT_FOUND]: {
    name: "Not Found",
  },
  [Routes.CONNECTIONS]: {
    name: "All Connections",
  },
  [Routes.INVESTORS]: {
    name: "Investors",
  },
  [Routes.TERMS]: {
    name: "Terms And Conditions",
  },
  [Routes.SETTINGS]: {
    name: "Settings",
  },
  [Routes.RESOURCES]: {
    name: "Founder Resources",
  },
  [Routes.EVENTS]: {
    name: "Events",
  },
  [Routes.NOT_EXISTING_PAGE]: {
    name: "Page Not Found",
  },
  [Routes.LISTS]: {
    name: "Lists",
  },
  [Routes.EMAIL_FOLLOWUP]: {
    name: "Email Followup",
  },
  [Routes.ADD_CONNECTIONS]: {
    name: "Add Connections",
  },
  [Routes.MOBILE]: {
    name: "",
  },
};

/**
 * Array of route objects that define the routes and their corresponding components.
 */
export const routes = [
  {
    element: <Auth0ProviderWithRedirectCallback />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <Providers />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: Routes.LOGIN,
            element: <LoginPage />,
          },
          {
            path: Routes.CALLBACK,
            element: <CallbackPage />,
          },
          {
            path: Routes.NOT_ALLOWED,
            element: <NotAllowedPage />,
          },
          {
            path: Routes.MOBILE,
            element: <ProtectedRoute component={MobilePage} />,
          },
          {
            element: <Layout />,
            children: [
              {
                path: Routes.CONNECTIONS,
                element: <ProtectedRoute component={ConnectionsPage} />,
              },
              {
                path: Routes.INVESTORS,
                children: [
                  {
                    index: true,
                    element: <ProtectedRoute component={InvestorsPage} />,
                  },
                  {
                    path: Routes.INVESTORS_ID_INTRO,
                    element: <ProtectedRoute component={InvestorsPage} />,
                  },
                ],
              },
              {
                path: Routes.RESOURCES,
                element: (
                  <ProtectedRoute component={ResourcePageWithProvider} />
                ),
                loader: () => founderResourcesLoader(queryClient),
                children: [
                  {
                    path: "*",
                    element: <ProtectedRoute component={ResourcePage} />,
                    loader: async ({ params }: any) => {
                      const slug = params["*"]?.replaceAll("/", ".");
                      return singleFounderResourceLoader(
                        queryClient,
                        slug ?? "",
                      );
                    },
                    errorELement: <PageError />,
                  },
                ],
              },
              {
                path: Routes.TERMS,
                element: <TermsPage />,
              },
              {
                path: Routes.SETTINGS,
                element: <ProtectedRoute component={SettingsPage} />,
              },
              {
                path: Routes.LISTS,
                element: <ProtectedRoute component={ListsPage} />,
              },
              {
                path: Routes.LIST,
                element: <ProtectedRoute component={ListPage} />,
              },
              {
                path: Routes.ADD_CONNECTIONS,
                element: <ProtectedRoute component={AddConnectionsPage} />,
              },
              {
                path: Routes.EVENTS,
                element: <ProtectedRoute component={EventsPage} />,
              },
              {
                path: Routes.NOT_FOUND,
                element: <NotFoundPage />,
              },
              {
                path: Routes.NOT_EXISTING_PAGE,
                element: <NotFoundPage />,
              },
              {
                path: Routes.EMAIL_FOLLOWUP,
                element: <ProtectedRoute component={EmailFollowupPage} />,
              },
              {
                path: Routes.HOME_PAGE,
                element: <HomePage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
