import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { CompanyFormProps } from "src/components/CompanyForm";
import { updateCompany } from "src/services/connection.service";

type MutationVariables = {
  id: number;
  updates: CompanyFormProps;
};

export const useCompanyMutation = () => {
  const { getAccessTokenSilently } = useAuth0();
  const mutationFn = async ({ id, updates }: MutationVariables) => {
    const accessToken = await getAccessTokenSilently();
    return updateCompany(accessToken, id, updates);
  };

  return useMutation({
    mutationFn,
    onSuccess: () => {
      toast.success("Settings successfully updated");
    },
  });
};
