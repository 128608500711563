import { cx } from "class-variance-authority";

export type SortDirection = "asc" | "desc";

interface IProps {
  direction?: SortDirection;
  className?: string;
}

const UnsortedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="fill-current"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.226027 8.84968C0.470105 8.60561 0.865833 8.60561 1.10991 8.84968L4.0013 11.7411L6.89269 8.84968C7.13677 8.60561 7.5325 8.60561 7.77658 8.84968C8.02065 9.09376 8.02065 9.48949 7.77658 9.73357L4.44324 13.0669C4.19917 13.311 3.80344 13.311 3.55936 13.0669L0.226027 9.73357C-0.0180507 9.48949 -0.0180507 9.09376 0.226027 8.84968Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77788 5.15032C7.5338 5.39439 7.13807 5.39439 6.894 5.15032L4.0026 2.25892L1.11121 5.15032C0.867135 5.39439 0.471406 5.39439 0.227328 5.15032C-0.0167488 4.90624 -0.0167487 4.51051 0.227328 4.26643L3.56066 0.933098C3.80474 0.68902 4.20047 0.68902 4.44455 0.933098L7.77788 4.26643C8.02196 4.51051 8.02196 4.90624 7.77788 5.15032Z"
    />
  </svg>
);

const AscIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="fill-current"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.226027 8.84968C0.470105 8.60561 0.865833 8.60561 1.10991 8.84968L4.0013 11.7411L6.89269 8.84968C7.13677 8.60561 7.5325 8.60561 7.77658 8.84968C8.02066 9.09376 8.02066 9.48949 7.77658 9.73357L4.44324 13.0669C4.19917 13.311 3.80344 13.311 3.55936 13.0669L0.226027 9.73357C-0.0180507 9.48949 -0.0180507 9.09376 0.226027 8.84968Z"
      fill="#ABADB5"
    />
    <path
      d="M7.33592 5.33337C7.58871 5.33337 7.81661 5.1811 7.91334 4.94755C8.01008 4.714 7.95661 4.44518 7.77786 4.26643L4.44447 0.933096C4.2004 0.689021 3.80467 0.689023 3.56059 0.933099L0.22726 4.26643C0.0485111 4.44518 -0.00496144 4.714 0.0917769 4.94755C0.188515 5.1811 0.416412 5.33337 0.669201 5.33337L7.33592 5.33337Z"
      fill="#FF1C24"
    />
  </svg>
);

export const DescIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="fill-current"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.77397 5.15019C7.5299 5.39427 7.13417 5.39427 6.89009 5.15019L3.9987 2.2588L1.10731 5.15019C0.863229 5.39427 0.4675 5.39427 0.223423 5.15019C-0.0206546 4.90612 -0.0206546 4.51039 0.223423 4.26631L3.55676 0.932976C3.80083 0.688898 4.19656 0.688898 4.44064 0.932976L7.77397 4.26631C8.01805 4.51039 8.01805 4.90612 7.77397 5.15019Z"
      fill="#ABADB5"
    />
    <path
      d="M0.664081 8.69251C0.411291 8.69251 0.183393 8.84478 0.0866551 9.07833C-0.0100824 9.31188 0.0433916 9.5807 0.222143 9.75945L3.55553 13.0928C3.7996 13.3369 4.19533 13.3369 4.43941 13.0928L7.77274 9.75945C7.95149 9.5807 8.00496 9.31187 7.90822 9.07833C7.81148 8.84478 7.58359 8.6925 7.3308 8.6925L0.664081 8.69251Z"
      fill="#FF1C24"
    />
  </svg>
);

export const SortIcon = ({ direction, className }: IProps) => {
  switch (direction) {
    case "asc":
      return <AscIcon />;
    case "desc":
      return <DescIcon />;
    default:
      return <UnsortedIcon className={cx(className, "invisible")} />; // make sure to add visible classname based on group name
  }
};
