export const RoundedQuestionmarkIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="fill-current"
    {...props}
  >
    <path d="M12.667 1.5C10.5903 1.5 8.56023 2.11581 6.83351 3.26957C5.10679 4.42332 3.76098 6.0632 2.96626 7.98182C2.17154 9.90045 1.96361 12.0116 2.36875 14.0484C2.7739 16.0852 3.77392 17.9562 5.24238 19.4246C6.71083 20.8931 8.58175 21.8931 10.6185 22.2982C12.6553 22.7034 14.7665 22.4955 16.6852 21.7007C18.6038 20.906 20.2437 19.5602 21.3974 17.8335C22.5512 16.1068 23.167 14.0767 23.167 12C23.167 9.21523 22.0607 6.54451 20.0916 4.57538C18.1225 2.60625 15.4518 1.5 12.667 1.5ZM12.667 21C10.887 21 9.14691 20.4722 7.66686 19.4832C6.18682 18.4943 5.03327 17.0887 4.35208 15.4442C3.67089 13.7996 3.49266 11.99 3.83993 10.2442C4.1872 8.49836 5.04436 6.89471 6.30304 5.63604C7.56171 4.37737 9.16536 3.5202 10.9112 3.17293C12.657 2.82567 14.4666 3.0039 16.1111 3.68508C17.7557 4.36627 19.1613 5.51983 20.1502 6.99987C21.1392 8.47991 21.667 10.22 21.667 12C21.667 14.3869 20.7188 16.6761 19.031 18.364C17.3431 20.0518 15.0539 21 12.667 21Z" />
    <path d="M12.667 18.75C13.2883 18.75 13.792 18.2463 13.792 17.625C13.792 17.0037 13.2883 16.5 12.667 16.5C12.0457 16.5 11.542 17.0037 11.542 17.625C11.542 18.2463 12.0457 18.75 12.667 18.75Z" />
    <path d="M13.417 6H12.292C11.8485 5.99901 11.4092 6.08563 10.9993 6.25489C10.5894 6.42415 10.2169 6.67272 9.90331 6.98631C9.58971 7.2999 9.34115 7.67235 9.17189 8.08227C9.00263 8.4922 8.91601 8.93151 8.917 9.375V9.75H10.417V9.375C10.417 8.87772 10.6145 8.40081 10.9662 8.04917C11.3178 7.69754 11.7947 7.5 12.292 7.5H13.417C13.9143 7.5 14.3912 7.69754 14.7428 8.04917C15.0945 8.40081 15.292 8.87772 15.292 9.375C15.292 9.87228 15.0945 10.3492 14.7428 10.7008C14.3912 11.0525 13.9143 11.25 13.417 11.25H11.917V14.625H13.417V12.75C14.3121 12.75 15.1705 12.3944 15.8035 11.7615C16.4364 11.1285 16.792 10.2701 16.792 9.375C16.792 8.47989 16.4364 7.62145 15.8035 6.98851C15.1705 6.35558 14.3121 6 13.417 6Z" />
  </svg>
);
