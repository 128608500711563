import { cx } from "class-variance-authority";
import { EmailIcon } from "src/assets/icons/general/EmailIcon";
import { LinkedInIcon } from "src/assets/icons/ui/LinkedInIcon";
import { XIcon } from "src/assets/icons/ui/XIcon";
import { ActionEvents, ISocialEventParams } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { Person } from "src/models/person-api-response";
import { getDefaultEmail } from "src/utils/helpers";

const tw = {
  iconWrapper: "flex flex-row gap-3 items-center justify-start",
  iconSize: "w-6 h-6",
  iconDisabled: "fill-interactive-icon-disabled",
};

const Socials = ({ person }: { person: Person }) => {
  const { track } = useAmplitudeTrack();
  const email = getDefaultEmail(person.emails);
  return (
    <div className={tw.iconWrapper}>
      {person.linkedin_url ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={person.linkedin_url}
          onClick={() => {
            const params: ISocialEventParams = {
              channel: "linkedin",
              url: person.linkedin_url,
            };
            track(ActionEvents.USER_PROFILE_LINK_CLICKED, params);
          }}
        >
          <LinkedInIcon className={tw.iconSize} />
        </a>
      ) : (
        <LinkedInIcon className={cx(tw.iconSize, tw.iconDisabled)} />
      )}
      {person.twitter_url ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={person.twitter_url}
          onClick={() => {
            const params: ISocialEventParams = {
              channel: "twitter",
              url: person.twitter_url,
            };
            track(ActionEvents.USER_PROFILE_LINK_CLICKED, params);
          }}
        >
          <XIcon className={tw.iconSize} />
        </a>
      ) : (
        <XIcon className={cx(tw.iconSize, tw.iconDisabled)} />
      )}
      {person.emails.length ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${email}`}
          onClick={() => {
            const params: ISocialEventParams = {
              channel: "email",
              email,
            };
            track(ActionEvents.USER_PROFILE_LINK_CLICKED, params);
          }}
        >
          <EmailIcon className={tw.iconSize} />
        </a>
      ) : (
        <EmailIcon className={cx(tw.iconSize, tw.iconDisabled)} />
      )}
    </div>
  );
};

export default Socials;
