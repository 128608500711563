export const AppleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M20.7283 18.0415C20.4012 18.7973 20.0139 19.4929 19.5653 20.1325C18.9538 21.0043 18.4531 21.6078 18.0672 21.943C17.4691 22.4931 16.8282 22.7748 16.1419 22.7908C15.6492 22.7908 15.0551 22.6506 14.3634 22.3662C13.6695 22.0832 13.0318 21.943 12.4488 21.943C11.8372 21.943 11.1814 22.0832 10.4799 22.3662C9.77731 22.6506 9.21133 22.7988 8.77859 22.8135C8.12048 22.8416 7.4645 22.5518 6.80972 21.943C6.39181 21.5785 5.86908 20.9536 5.24287 20.0684C4.57101 19.123 4.01864 18.0269 3.5859 16.7771C3.12246 15.4272 2.89014 14.1201 2.89014 12.8546C2.89014 11.405 3.20337 10.1547 3.83078 9.10697C4.32386 8.2654 4.97984 7.60154 5.80085 7.11419C6.62186 6.62685 7.50896 6.3785 8.46429 6.36261C8.98701 6.36261 9.6725 6.52431 10.5244 6.84208C11.3738 7.16093 11.9192 7.32262 12.1584 7.32262C12.3371 7.32262 12.943 7.13355 13.9702 6.75663C14.9416 6.40708 15.7614 6.26234 16.433 6.31935C18.2528 6.46622 19.6201 7.18362 20.5293 8.47609C18.9017 9.46226 18.0966 10.8435 18.1126 12.6155C18.1273 13.9956 18.628 15.1442 19.6121 16.0561C20.058 16.4794 20.556 16.8065 21.1101 17.0388C20.99 17.3873 20.8631 17.7211 20.7283 18.0415V18.0415ZM16.5545 1.61734C16.5545 2.69913 16.1593 3.70919 15.3715 4.64409C14.4208 5.7555 13.271 6.39773 12.024 6.29639C12.0081 6.16661 11.9989 6.03002 11.9989 5.88648C11.9989 4.84797 12.451 3.73656 13.2539 2.82782C13.6547 2.36772 14.1645 1.98514 14.7827 1.67996C15.3995 1.37933 15.983 1.21307 16.5318 1.1846C16.5478 1.32922 16.5545 1.47385 16.5545 1.61733V1.61734Z"
      fill="black"
    />
  </svg>
);
