export const UserFilledIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M20.0617 18.6975C21.1385 17.4016 21.8873 15.8655 22.2449 14.219C22.6025 12.5725 22.5583 10.864 22.1161 9.23822C21.6738 7.61241 20.8465 6.11702 19.7041 4.87854C18.5617 3.64005 17.1379 2.69491 15.553 2.12305C13.9681 1.55119 12.2688 1.36943 10.5989 1.59315C8.92889 1.81686 7.33736 2.43948 5.95888 3.40832C4.5804 4.37717 3.45553 5.66375 2.67941 7.15925C1.90329 8.65474 1.49875 10.3152 1.5 12C1.50059 14.4497 2.36386 16.8209 3.93833 18.6975L3.92333 18.71C3.97583 18.7734 4.03583 18.8275 4.08998 18.8894C4.15748 18.9671 4.22978 19.0394 4.29998 19.115C4.50998 19.3425 4.72553 19.5614 4.95248 19.7675C5.0211 19.8298 5.09228 19.8884 5.16248 19.9488C5.40248 20.1557 5.64878 20.3517 5.90498 20.5347C5.93805 20.5581 5.96738 20.5867 6.00068 20.6102V20.6007C7.75726 21.8368 9.85276 22.5002 12.0007 22.5002C14.1486 22.5002 16.2441 21.8368 18.0007 20.6007V20.61C18.034 20.5866 18.0633 20.558 18.0964 20.5346C18.3523 20.3515 18.5989 20.1555 18.8389 19.9486C18.9091 19.8886 18.9803 19.8297 19.0489 19.7673C19.2758 19.5612 19.4914 19.3422 19.7014 19.1148C19.7714 19.0394 19.8434 18.9669 19.9114 18.8892C19.9653 18.8274 20.0255 18.7732 20.078 18.7098L20.0617 18.6975ZM12 6.00005C12.6675 6.00005 13.32 6.19799 13.8751 6.56884C14.4301 6.93969 14.8626 7.46679 15.1181 8.08349C15.3735 8.70019 15.4404 9.37879 15.3102 10.0335C15.1799 10.6882 14.8585 11.2895 14.3865 11.7615C13.9145 12.2335 13.3131 12.555 12.6584 12.6852C12.0037 12.8154 11.3251 12.7486 10.7084 12.4931C10.0917 12.2377 9.56464 11.8051 9.19379 11.2501C8.82294 10.6951 8.625 10.0426 8.625 9.37505C8.625 8.47994 8.98058 7.6215 9.61352 6.98856C10.2465 6.35563 11.1049 6.00005 12 6.00005ZM6.00533 18.6975C6.01828 17.7128 6.41845 16.7727 7.11926 16.0807C7.82006 15.3887 8.76514 15.0005 9.75 15H14.25C15.2349 15.0005 16.1799 15.3888 16.8807 16.0807C17.5815 16.7727 17.9817 17.7128 17.9947 18.6975C16.3498 20.1798 14.2142 21 12 21C9.78584 21 7.65019 20.1798 6.00533 18.6975Z" />
  </svg>
);
