export const LinkedInIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M21.3715 1H2.62422C1.72617 1 1 1.70898 1 2.58555V21.4102C1 22.2867 1.72617 23 2.62422 23H21.3715C22.2695 23 23 22.2867 23 21.4145V2.58555C23 1.70898 22.2695 1 21.3715 1ZM7.52695 19.7473H4.26133V9.2457H7.52695V19.7473ZM5.89414 7.81484C4.8457 7.81484 3.99922 6.96836 3.99922 5.92422C3.99922 4.88008 4.8457 4.03359 5.89414 4.03359C6.93828 4.03359 7.78477 4.88008 7.78477 5.92422C7.78477 6.96406 6.93828 7.81484 5.89414 7.81484ZM19.7473 19.7473H16.4859V14.6426C16.4859 13.4266 16.4645 11.8582 14.7887 11.8582C13.0914 11.8582 12.8336 13.1859 12.8336 14.5566V19.7473H9.57656V9.2457H12.7047V10.6809H12.7477C13.1816 9.85586 14.2473 8.98359 15.8328 8.98359C19.1371 8.98359 19.7473 11.1578 19.7473 13.9852V19.7473Z" />
  </svg>
);
