import { TextArea, Typography } from "@50y/celestial";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { FilesIcon } from "src/assets/icons/general/FilesIcon";
import { CompanyFormProps } from "src/components/CompanyForm";
import { Section } from "src/components/Section";
import { SettingsEvents } from "src/config/amplitudeEvents";
import { Routes } from "src/config/routes";
import { useDebouncedEffect, useTrackFormContentChange } from "src/hooks";
import { useCompanyMutation } from "src/queries/companies/useCompanyMutation";
import { useMe } from "src/queries/useMe";
import { DEBOUNCE_TIMEOUT_MS } from "./SettingsPage";
import { tw } from "./styles";

export const TemplatesTab = () => {
  const { data: me, refetch: refetchUser } = useMe();
  const [updates, setUpdates] = useState<CompanyFormProps>({
    blurb: me?.data.companies[0]?.blurb || "",
  });
  const { mutate: handleSave } = useCompanyMutation();
  const { onBlur: onBlurbBlur, onFocus: onBlurbFocus } =
    useTrackFormContentChange({
      initialValue: me?.data.companies[0].blurb || "",
      value: me?.data.companies[0].blurb as string,
      event: SettingsEvents?.CHANGED_COMPANY_BLURB,
    });

  const isTouched = useMemo(() => {
    return updates.blurb !== me?.data.companies[0].blurb || "";
  }, [updates, me?.data.companies]);

  useDebouncedEffect(
    () => {
      (async () => {
        if (isTouched && me?.data.companies[0]) {
          handleSave({ id: me?.data.companies[0].id, updates });
          refetchUser();
        }
      })();
    },
    DEBOUNCE_TIMEOUT_MS,
    [isTouched, updates.blurb, updates?.display_name, updates.next_stage],
  );
  return (
    <Section>
      <div className={tw.sectionTitle}>
        <FilesIcon className={tw.sectionIcon} />
        <Typography variant="prominent-1" size="title-6">
          Templates
        </Typography>
      </div>
      <div>
        <Typography variant="prominent-1" size="label-3">
          Company Blurb
        </Typography>
        <Typography variant="default" size="label-2">
          Read our guide on{" "}
          <Link
            to={Routes.RESOURCES_COMPANY_BLURB}
            target="_blank"
            className="text-interactive-text-text-b-primary"
          >
            how to write blurbs
          </Link>
        </Typography>
        <TextArea
          value={updates.blurb}
          placeholder="Write a company blurb"
          rows={7}
          className="mt-1 rounded-input-rounding text-text-default-default-primary"
          onChange={(e) => {
            setUpdates((v) => ({ ...v, blurb: e.target.value }));
          }}
          onBlur={onBlurbBlur}
          onFocus={onBlurbFocus}
        />
      </div>
    </Section>
  );
};
