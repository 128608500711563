import { useMemo } from "react";
import { Attendee } from "src/models/events-api-response";

export const useInvitedBy = ({
  attendees,
  currentUserId,
}: {
  attendees: Attendee[];
  currentUserId: number;
}) => {
  const invitedBy = useMemo(
    () =>
      attendees.find((attendee) => attendee.user.id === currentUserId)
        ?.invited_by,
    [attendees, currentUserId],
  );
  return {
    invitedBy,
  };
};
