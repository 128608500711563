import { Button } from "@50y/celestial";
import { useCallback, useState } from "react";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { Entities } from "src/models/imports-api-response";
import { useSyncAirtableMutation } from "src/queries/useSyncAirtableMutation";

const SyncFromAirtableButton = () => {
  const { track } = useAmplitudeTrack();
  const [savingEntity, setSavingEntity] = useState<null | Entities>(null);

  const { mutateAsync: syncWithAirtable } = useSyncAirtableMutation();

  const saveCb = useCallback(async () => {
    track(ActionEvents.CLICKED_AIRTABLE_SYNC);
    if (savingEntity) return;
    setSavingEntity(Entities.COMPANIES);
    await syncWithAirtable({
      entity: Entities.COMPANIES,
      title: "1/4",
    });

    setSavingEntity(Entities.USERS);
    await syncWithAirtable({ entity: Entities.USERS, title: "2/4" });

    setSavingEntity(Entities.FUNDS);
    await syncWithAirtable({ entity: Entities.FUNDS, title: "3/4" });

    setSavingEntity(Entities.INVESTORS);
    await syncWithAirtable({ entity: Entities.INVESTORS, title: "4/4" });

    setSavingEntity(null);
  }, [syncWithAirtable, savingEntity, track]);

  return (
    <div className="w-fit">
      <Button onPress={saveCb} variant="secondary" isLoading={!!savingEntity}>
        {savingEntity ? `Syncing ${savingEntity}...` : "Sync with Airtable"}
      </Button>
    </div>
  );
};

export default SyncFromAirtableButton;
