import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { ActionEvents, RequestIntroEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { updateFollowUpEmail } from "src/services/intro-request.service";

export const useClosingTheLoopMutation = () => {
  const { track } = useAmplitudeTrack();
  const { getAccessTokenSilently } = useAuth0();
  const mutationFn = async (params: { id?: string; answer?: string }) => {
    const accessToken = await getAccessTokenSilently();
    return updateFollowUpEmail({ ...params, accessToken });
  };
  return useMutation({
    mutationFn,
    onSuccess: (_, params) => {
      track(RequestIntroEvents.REQUEST_INTRO_EMAIL_RECEIVED, {
        ...params,
      });
    },
    onError: (error, params) => {
      track(ActionEvents.CLOSE_THE_LOOP_FOLLOWUP_EMAIL_ERROR, {
        ...params,
        error,
      });
    },
  });
};
