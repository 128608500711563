import {
  HighStrengthIcon,
  LowStrengthIcon,
  MediumStrengthIcon,
} from "src/assets/icons/general/ConnectionStrengthIcon";
import { ConnectionStrengthEnum } from "src/models/connections-api-response";
import { connectionStrengthScoreToEnum } from "src/utils/helpers";

export const ConnectionStrength = ({
  strength,
  is_confident = true,
}: {
  strength?: number;
  is_confident: boolean;
}) => {
  if (!is_confident) {
    return <></>;
  }
  const connectionEnum = connectionStrengthScoreToEnum(strength ?? 0);
  if (connectionEnum === ConnectionStrengthEnum.High) {
    return <HighStrengthIcon />;
  }
  if (connectionEnum === ConnectionStrengthEnum.Medium) {
    return <MediumStrengthIcon />;
  }
  if (connectionEnum === ConnectionStrengthEnum.Low) {
    return <LowStrengthIcon />;
  }
  return <></>;
};
