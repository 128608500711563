import { useState } from "react";
import { useAmplitudeTrack } from "src/hooks";
import { Page } from "src/models/connections-api-response";

const useTrackFormContentChange = ({
  value,
  initialValue,
  event,
  type,
}: {
  value: string;
  initialValue: string;
  event: string;
  type?: Page;
}): { onBlur(): void; onFocus(): void } => {
  const { track } = useAmplitudeTrack();
  const [previousValue, setPreviousValue] = useState<string>(initialValue);

  const onBlur = () => {
    if (previousValue !== value) {
      const typeProp = type ? { type } : {};
      track(event, { old: previousValue, new: value, ...typeProp });
      setPreviousValue(value);
    }
  };

  const onFocus = () => {
    setPreviousValue(previousValue);
  };

  return { onBlur, onFocus };
};

export default useTrackFormContentChange;
