export enum TableCellBreakpoints {
  LARGE_SCREEN = 1800,
  DESKTOP_SCREEN = 1400,
  MEDIUM_SCREEN = 1000,
  DEFAULT_SCREEN = 800,
  TABLET_SCREEN = 600,
  DEFAULT_TABLET_SCREEN = 450,
  SMALL_TABLET_SCREEN = 300,
  MOBILE_SCREEN = 200,
}

export enum SortTypes {
  NAME = "name",
  PERSON_NAME = "person__first_name,person__last_name",
  PERSON_ADDED_LAST = "-person_added",
  ROLE = "role",
  CONNECTIONS = "connection_strengths",
  COMPANY = "current_company",
  STATUS = "status",
  FIRM_AFFILATION_ORGANIZATION_NAME = "firm_affiliation__organization__name",
}

export enum ScreenBreakpoints {
  DESKTOP = 1280,
  TABLET = 768,
  MOBILE = 480,
}
export enum URLParams {
  PAGE = "page",
  SEARCH = "search",
}
