import { Typography } from "@50y/celestial";
import { DealsCTASection } from "src/components/Deals";

interface IProps {
  name: string;
  subtitle?: string;
  isDealsPage?: boolean;
}

const dealsPageExcerpt =
  "50Y is seeking partnerships to offer credits, discounts, and personalized support for products our community loves.";

export const HeroSection = ({
  name,
  subtitle,
  isDealsPage = false,
}: IProps) => (
  <div className="bg-white p-6 mb-6 rounded-s shadow-card-shadow grid gap-2">
    <Typography size="title-3" variant="prominent-1" className="max-w-xl">
      {isDealsPage ? subtitle : name}
    </Typography>
    <Typography size="body-4" variant="default" className="max-w-xl">
      {isDealsPage ? dealsPageExcerpt : subtitle}
    </Typography>
    {isDealsPage && <DealsCTASection />}
  </div>
);
