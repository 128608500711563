import { getPage, getPages } from "src/services/founder-resources.service";
import { QUERY_KEYS } from "./query-keys";

export const founderResourcesQuery = ({ pageParam = 1 }) => ({
  queryKey: [QUERY_KEYS.FOUNDER_RESOURCES],
  queryFn: async () => await getPages({ pageParam }),
  staleTime: Infinity,
});

export const singleFounderResourceQuery = ({ slug }: { slug: string }) => ({
  queryKey: [QUERY_KEYS.FOUNDER_RESOURCE, { slug }],
  queryFn: async () => await getPage({ slug }),
});
