import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { Investor } from "src/models/investors-api-response";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getInvestor } from "src/services/investor.service";

export const useInvestor = (id: string | undefined) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery<AxiosResponse<Investor>, AxiosError>({
    queryKey: [QUERY_KEYS.INVESTOR, id],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getInvestor(accessToken, id);
    },
    enabled: !!id,
  });
};
