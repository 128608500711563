import { Typography } from "@50y/celestial";
import { NavLink } from "react-router-dom";
import { ArrowRightIcon } from "src/assets/icons/general/ArrowRight";
import { BreadcrumbArrow } from "src/assets/icons/general/BreadcrumbArrow";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";

interface IProps {
  breadcrumbs: {
    link: string;
    title: string;
  }[];
}

const twClasses = {
  wrapper:
    "py-2 px-6 w-full justify-start sticky top-0 border-b border-elevation-outline-0 flex items-center gap-2 bg-white z-10",
  breadcrumbWrapper: "flex gap-2 items-center",
};

const BreadcrumbComponent = ({ breadcrumbs }: IProps) => {
  const { track } = useAmplitudeTrack();

  if (!breadcrumbs.length) {
    return null;
  }

  return (
    <div className={twClasses.wrapper}>
      {breadcrumbs.map(({ link, title }, index) => {
        const innerContent = (
          <>
            {index === 0 ? (
              <BreadcrumbArrow className="w-6 h-6" />
            ) : (
              <ArrowRightIcon className="w-4 h-4" />
            )}
            <Typography variant="default" size="label-2" className="capitalize">
              {title}
            </Typography>
          </>
        );
        if (breadcrumbs.length - 1 === index) {
          return (
            <div key={link} className={twClasses.breadcrumbWrapper}>
              {innerContent}
            </div>
          );
        }
        return (
          <NavLink
            to={link}
            key={link}
            className={twClasses.breadcrumbWrapper}
            onClick={() => {
              track(ActionEvents.CLICKED_BACK_BUTTON, {
                page: link,
              });
            }}
          >
            {innerContent}
          </NavLink>
        );
      })}
    </div>
  );
};

export default BreadcrumbComponent;
