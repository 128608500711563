import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getEvents } from "src/services/event.service";

export const useEvents = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [QUERY_KEYS.EVENTS],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getEvents(accessToken);
    },
  });
};
