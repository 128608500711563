import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { PaginatedApiResponse } from "src/models/api-response";
import { LinkedinConnection } from "src/models/connections-api-response";
import { Investor } from "src/models/investors-api-response";
import { queryClient } from "src/providers/queryClient";
import { removeContactFromList } from "src/services/lists.service";
import { QUERY_KEYS } from "../query-keys";
import { ListContactVariables } from "./useAddContactToListMutation";

interface ListContactVariablesExtended extends ListContactVariables {
  peopleIds: number[];
}

export const useRemovePeopleFromListMutation = ({
  shouldInvalidate = true,
  queryKey,
}: {
  shouldInvalidate?: boolean;
  queryKey?: string;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();
  return useMutation({
    mutationFn: async (variables: ListContactVariablesExtended) => {
      const accessToken = await getAccessTokenSilently();
      return removeContactFromList({ accessToken, ...variables });
    },
    onSuccess: (_, { listName, page, queryParams, listId, peopleIds }) => {
      track(ActionEvents.LIST_PERSON_REMOVED, {
        list_name: listName,
        page: page,
      });
      toast.success(
        `${
          peopleIds.length > 1 ? "People" : "Person"
        } successfully removed from list`,
      );
      if (queryKey) {
        queryClient.setQueryData(
          [queryKey, { ...queryParams }],
          (
            oldData: AxiosResponse<
              PaginatedApiResponse<LinkedinConnection | Investor>
            >,
          ) => {
            const updatedData = oldData.data.results.map((person) => {
              if (person.person.id === peopleIds[0]) {
                const savedLists = person.saved_list_ids.filter(
                  (list) => list !== listId,
                );
                return {
                  ...person,
                  saved_list_ids: savedLists,
                };
              }
              return person;
            });
            return {
              ...oldData,
              data: {
                ...oldData.data,
                results: updatedData,
              },
            };
          },
        );
      }

      if (shouldInvalidate) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.LIST_PEOPLE, { ...queryParams }],
        });
      }
    },
    onError: () => {
      toast.error("Failed to remove people from the list");
    },
  });
};
