import { Article, TextInput, Typography } from "@50y/celestial";
import { useRef, useState } from "react";
import { Popover } from "react-aria-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "src/assets/icons/general/SearchIcon";
import connectingImage from "src/assets/images/connecting.png";
import { useDebouncedValue } from "src/hooks";
import { useFounderResourcesSearch } from "src/queries/founder-resources/useFounderResources";
import { slugToLink } from "src/utils/founderResourcesHelpers";

const tw = {
  popover:
    "z-50 bg-elevation-background border border-elevation-outline-1 rounded-md shadow-xl w-96",
  item: "flex items-center justify-between cursor-pointer",
};

export const FounderResourcesSearch = () => {
  const [search, setSearch] = useState("");
  const searchQuery = useDebouncedValue(search, { delay: 500 });
  const [isOpen, setOpen] = useState(false);
  const triggerRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const { data: searchResults, isLoading: resourcesLoading } =
    useFounderResourcesSearch({
      searchTerm: searchQuery,
    });

  return (
    <div className="w-64">
      <TextInput
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setOpen(true);
        }}
        placeholder="Search"
        onFocus={() => setOpen(true)}
        ref={triggerRef}
        leftContent={<SearchIcon />}
        id="resources-search"
        inputProps="bg-inherit"
      />

      <Popover
        className={tw.popover}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={() => {
          setOpen(!isOpen);
          triggerRef.current?.blur();
        }}
        placement="bottom start"
        shouldCloseOnInteractOutside={(element) => {
          // Close the popover when user clicks outside the popover but the trigger element (input).
          return !(element.id === triggerRef.current?.id);
        }}
        offset={20}
        crossOffset={-42}
      >
        <div className="max-h-80 overflow-y-auto no-scrollbar">
          {resourcesLoading && <Skeleton count={5} height={40} />}
          {searchResults?.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                navigate(slugToLink(item.attributes.slug));
              }}
              className={tw.item}
            >
              <Article
                size="links"
                title={item.attributes.name}
                image={{
                  url:
                    item.attributes.image?.data?.attributes?.url ??
                    connectingImage,
                  alt: item.attributes.image?.data?.attributes?.alternativeText,
                }}
                className="w-full"
              />
            </div>
          ))}
          {!!search.length &&
            searchResults?.length === 0 &&
            !resourcesLoading && (
              <div className="p-2">
                <Typography variant="default" size="body-4">
                  No match for this search criteria
                </Typography>
              </div>
            )}
        </div>
      </Popover>
    </div>
  );
};
