import { Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import Markdown from "markdown-to-jsx";
import React from "react";
import {
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader,
} from "react-aria-components";
import { Link } from "react-router-dom";
import { MarkdownHeadings, WrappedHeading } from "./TrackedHeading";

interface IProps {
  codaMarkdown: string;
  parentWidth: number;
}

const twClasses = {
  tableWrapper: "my-5 overflow-x-auto w-full max-w-2xl",
  paragraph: "my-1",
  table: "m-auto",
  blockquote: "border-l-4 pl-6 border-elevation-outline-0",
  head: "font-bold text-left border-b border-elevation-outline-0",
  li: "py-1 pl-1 ml-6",
  row: "border-b border-elevation-outline-0",
  cell: "break-all max-w-80 min-w-table-cell px-3 py-2-5 align-baseline",
  tableData: "line-clamp-4 text-text-default-text-default-secondary",
  navlink: "text-interactive-text-text-b-primary",
  maxWidth: "max-w-2xl w-full",
  pre: "whitespace-pre-wrap",
  code: "text-text-default-text-default-secondary",
};

const StyledAnchor = ({
  href,
  children,
}: {
  href?: string;
  children: string;
}) => {
  if (href?.startsWith("/") || href?.startsWith("#")) {
    return (
      <Link
        className={cx(twClasses.navlink, twClasses.maxWidth)}
        to={href ?? "#"}
      >
        {children}
      </Link>
    );
  }
  return (
    <a
      rel="noreferrer"
      href={
        href?.startsWith("http") ||
        href?.startsWith("mailto:") ||
        href?.startsWith("tel:")
          ? href
          : `https://${href}`
      }
      target="_blank"
    >
      <Typography
        as="span"
        size="body-4"
        variant="default"
        className={cx(twClasses.navlink, twClasses.maxWidth)}
      >
        {children}
      </Typography>
    </a>
  );
};

const WrapperDiv = (props: any) => (
  <div className="flex flex-col justify-center items-center m-auto">
    {props.children}
  </div>
);

export const MarkdownContent: React.FC<IProps> = ({
  codaMarkdown,
  parentWidth,
}) => {
  // Calculating max table width based on container size minus padding
  const maxTableWidth = Math.ceil(parentWidth) - 64;

  return (
    <div>
      <Markdown
        options={{
          wrapper: WrapperDiv,
          overrides: {
            h1: (props) => (
              <WrappedHeading {...props} el={MarkdownHeadings.h1} />
            ),
            h2: (props) => (
              <WrappedHeading {...props} el={MarkdownHeadings.h2} />
            ),
            h3: (props) => (
              <WrappedHeading {...props} el={MarkdownHeadings.h3} />
            ),
            p: (props) => (
              <Typography
                {...props}
                variant="prominent-1"
                size="body-4"
                className={cx(twClasses.paragraph, twClasses.maxWidth)}
              />
            ),
            a: StyledAnchor,
            span: (props) => (
              <Typography
                {...props}
                variant="default"
                size="body-4"
                className={cx(twClasses.paragraph, twClasses.maxWidth)}
              />
            ),
            ol: (props) => (
              <ol
                {...props}
                className={cx(twClasses.maxWidth, "list-decimal")}
              />
            ),
            ul: (props) => (
              <ul {...props} className={cx(twClasses.maxWidth, "list-disc")} />
            ),
            li: (props) => (
              <li className={cx(twClasses.li, "markdown-list")}>
                <Typography
                  {...props}
                  variant="default"
                  size="body-4"
                ></Typography>
              </li>
            ),
            blockquote: (props) => (
              <div className={cx(twClasses.blockquote, twClasses.maxWidth)}>
                <Typography
                  {...props}
                  variant="default-italic"
                  size="body-4"
                  className={cx(twClasses.paragraph, twClasses.maxWidth)}
                />
              </div>
            ),
            table: (props) => (
              <div
                className={cx(twClasses.tableWrapper)}
                style={{ maxWidth: maxTableWidth }}
              >
                <Table
                  aria-label="markdown-table"
                  {...props}
                  className={twClasses.table}
                />
              </div>
            ),
            thead: (props) => (
              <TableHeader {...props} className={twClasses.head} />
            ),
            tbody: TableBody,
            th: (props) => (
              <Column className={twClasses.cell} isRowHeader>
                <Typography
                  {...props}
                  variant="prominent-1"
                  size="label-3"
                  className="truncate"
                />
              </Column>
            ),
            tr: (props) => <Row {...props} className={twClasses.row} />,
            td: (props) => (
              <Cell className={twClasses.cell}>
                <Typography
                  {...props}
                  variant="default"
                  size="body-4"
                  className={twClasses.tableData}
                />
              </Cell>
            ),
            hr: (props) => (
              <hr {...props} className={cx("my-8", twClasses.maxWidth)} />
            ),
            pre: (props) => (
              <pre
                {...props}
                className={cx(twClasses.maxWidth, twClasses.pre)}
              />
            ),
            code: (props) => <code {...props} className={twClasses.code} />,
            u: (props) => <u {...props} className={twClasses.maxWidth} />,
            strong: (props) => (
              <strong {...props} className="font-labels-prominent-1" />
            ),
          },
        }}
      >
        {codaMarkdown}
      </Markdown>
    </div>
  );
};
