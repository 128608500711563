import { Button, Typography } from "@50y/celestial";
import React from "react";
import { useNavigate } from "react-router-dom";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { useTrackPageVisited } from "src/hooks";

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  useTrackPageVisited(NavigationEvents.NOT_FOUND_PAGE_VIEWED);

  return (
    <>
      <div className="h-full flex flex-col justify-center items-center gap-4">
        <Typography
          variant="prominent-1"
          size="headline-1"
          className="text-slate-400"
        >
          404
        </Typography>
        <Typography variant="default" size="body-3">
          The page you are looking for does not exist!
        </Typography>
        <Button variant="primary" onPress={() => navigate(-1)} width="m">
          Go back
        </Button>
      </div>
    </>
  );
};
