import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SavedList } from "src/models/lists-api-response";

import {
  Button,
  Pagination,
  Popover,
  TextInput,
  Typography,
} from "@50y/celestial";
import { cx } from "class-variance-authority";
import { EditIcon } from "src/assets/icons/general/EditIcon";
import { GarbageIcon } from "src/assets/icons/general/GarbageIcon";
import { SearchIcon } from "src/assets/icons/general/SearchIcon";
import { ThreeDots } from "src/assets/icons/general/ThreeDots";
import { Loader } from "src/components/Loader";
import DeleteModal from "src/components/modals/DeleteModal";
import { ManageListModal } from "src/components/modals/ManageListModal";
import PageEmptyState from "src/components/PageEmptyState";
import { StickyHeader } from "src/components/StickyHeader";
import { ActionEvents, NavigationEvents } from "src/config/amplitudeEvents";
import { URLParamsContext } from "src/context";
import {
  useAmplitudeTrack,
  usePageURLParams,
  useTrackPageVisited,
} from "src/hooks";
import { useCreateListMutation } from "src/queries/lists/useCreateListMutation";
import {
  useDeleteList,
  useEditList,
  useLists,
} from "src/queries/lists/useLists";
import { useMe } from "src/queries/useMe";
import { LISTS_PAGE_SIZE } from "src/services/lists.service";
import { URLParams } from "src/utils/constants";

const twClasses = {
  table:
    "grid grid-cols-lists-grid h-16 border-b border-elevation-outline-0 items-center",
  tableRow:
    "grid grid-cols-lists-grid group/item h-16 border-b border-elevation-outline-0 items-center z-10 hover:bg-elevation-surface-1 transition-background-color duration-300 ease-in-out cursor-pointer",
  listItem: "flex flex-col gap-2 px-4 py-2",
  listItemText:
    "cursor-pointer flex gap-2 items-center text-interactive-text-text-b-secondary",
  listItemIcon: "h-6 w-6 fill-interactive-text-text-b-secondary",
};

const ListRow = ({
  savedList,
  deleteSavedList,
  onListEdit,
}: {
  savedList: SavedList;
  deleteSavedList: (list: SavedList) => void;
  onListEdit: ({ name, list }: { name: string; list: SavedList }) => void;
}) => {
  const { data: me } = useMe();
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  useTrackPageVisited(NavigationEvents.LISTS_PAGE_VIEWED);

  const deleteList = async () => {
    setDeleteModalOpen(false);
    deleteSavedList(savedList);
  };

  const modalText = `Are you sure you want to delete ${savedList.name}? You will lose all data and will not be able to recover it`;

  const handleListMutation = (name: string) => {
    onListEdit({ name, list: savedList });
    setEditModalOpen(false);
  };

  return (
    <>
      <div
        key={savedList.id}
        onClick={() => navigate(`${savedList.id}`)}
        className={twClasses.tableRow}
      >
        <Typography
          variant="prominent-2"
          size="body-4"
          className="truncate px-3 py-2"
        >
          {savedList.name}
        </Typography>
        <Typography variant="default" size="body-4" className="px-3 py-2">
          {me?.data?.id === savedList.owners[0]?.id
            ? "Me"
            : savedList.owners[0]?.person.nickname}
        </Typography>
        <Typography variant="default" size="body-4" className="px-3 py-2">
          {savedList?.people_count || 0}
        </Typography>
        <div
          className="text-end px-3 py-4"
          onClick={(e) => e.stopPropagation()}
        >
          <Popover hideArrow={true} icon={<ThreeDots />} className="!p-0">
            <div
              className={cx(
                twClasses.listItem,
                "border-b border-elevation-outline-1",
              )}
            >
              <Typography
                variant="default"
                size="label-2"
                className={twClasses.listItemText}
                onClick={() => {
                  setEditModalOpen(true);
                }}
              >
                <EditIcon className={twClasses.listItemIcon} />
                Edit list
              </Typography>
            </div>
            <div className={twClasses.listItem}>
              <Typography
                variant="default"
                size="label-2"
                className={twClasses.listItemText}
                onClick={() => {
                  setDeleteModalOpen(true);
                }}
              >
                <GarbageIcon className={twClasses.listItemIcon} />
                Delete list
              </Typography>
            </div>
          </Popover>
        </div>
      </div>
      {isDeleteModalOpen && (
        <>
          {/* This is a dummy input to prevent Chrome from auto-focusing the first button */}
          <input type="text" autoFocus />
          <DeleteModal
            headerText="Delete list"
            bodyText={modalText}
            onClose={() => setDeleteModalOpen(false)}
            onDelete={() => deleteList()}
            deleteButtonText="Yes, Delete it"
            cancelButtonText="No, keep it"
          />
        </>
      )}
      {isEditModalOpen && (
        <ManageListModal
          manageList={handleListMutation}
          toggleOpenState={setEditModalOpen}
          listName={savedList.name}
          isEdit
        />
      )}
    </>
  );
};

const ListsPage: FC = () => {
  const { getSearchParam, setSearchParams } = usePageURLParams();
  const navigate = useNavigate();
  const [isNewListModalOpen, setIsNewListModalOpen] = useState(false);
  const { mutate: deleteSavedList } = useDeleteList();
  const { mutate: onListEdit } = useEditList();
  const { track } = useAmplitudeTrack();
  const { setSearchValue, searchValue } = useContext(URLParamsContext);

  const currentPage = Number(getSearchParam(URLParams.PAGE) || 1);
  const search = (getSearchParam(URLParams.SEARCH) as string) || "";

  const {
    data,
    isLoading: savedListsDataIsLoading,
    isFetching,
    count,
  } = useLists(currentPage, search);

  const {
    isSuccess: createListIsSuccess,
    data: createdListData,
    mutate: createListMutation,
  } = useCreateListMutation();

  useEffect(() => {
    if (createListIsSuccess && createdListData) {
      navigate(`/lists/${createdListData.data.id}`);
    }
  }, [createListIsSuccess, createdListData, navigate]);

  const handleListDelete = (list: SavedList) => {
    deleteSavedList({ list, page: currentPage, search });
    if (data?.results?.length === 1) {
      const page = (currentPage > 1 ? currentPage - 1 : currentPage).toString();
      setSearchParams({ [URLParams.PAGE]: page }, { replace: true });
    }
  };

  const savedListsData = useMemo(() => data?.results, [data]);

  const hasSavedLists = !!savedListsData?.length;

  const handleNewListCreation = (name: string) => {
    createListMutation({ name });
    setIsNewListModalOpen(false);
  };

  return (
    <div className="h-full relative overflow-y-auto">
      {hasSavedLists && (
        <StickyHeader className="justify-between">
          <div className="w-80">
            <div className="w-64">
              <TextInput
                leftContent={<SearchIcon />}
                placeholder="Search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onFocus={() => track(ActionEvents.SEARCH_BAR_CLICKED)}
                inputProps="bg-inherit"
              />
            </div>
          </div>
          <div>
            <Button onPress={() => setIsNewListModalOpen(true)}>
              Create new
            </Button>
          </div>
        </StickyHeader>
      )}
      {hasSavedLists && (
        <div className="h-table-content flex flex-col justify-between">
          <div className="p-6 grid">
            <div className={twClasses.table}>
              <div className="px-3 py-2">
                <Typography variant="prominent-1" size="label-3">
                  Name
                </Typography>
              </div>
              <div className="px-3 py-2">
                <Typography variant="prominent-1" size="label-3">
                  Owners
                </Typography>
              </div>
              <div className="px-3 py-2">
                <Typography variant="prominent-1" size="label-3">
                  People on list
                </Typography>
              </div>
              <div className="px-3 py-2"></div>
            </div>
            {data?.results.map((savedList) => {
              return (
                <ListRow
                  savedList={savedList}
                  key={savedList.id}
                  deleteSavedList={handleListDelete}
                  onListEdit={({ name, list }) =>
                    onListEdit({
                      list: { ...list, name },
                      page: currentPage,
                      search: searchValue,
                    })
                  }
                />
              );
            })}
          </div>
          {!savedListsDataIsLoading && (
            <Pagination
              total={count || 0}
              perPage={LISTS_PAGE_SIZE}
              page={currentPage}
              onPageChange={(page) =>
                setSearchParams(
                  {
                    [URLParams.PAGE]: page.toString(),
                    [URLParams.SEARCH]: searchValue,
                  },
                  { replace: true },
                )
              }
            />
          )}
        </div>
      )}
      {savedListsDataIsLoading && <Loader />}
      {isNewListModalOpen && (
        <ManageListModal
          manageList={handleNewListCreation}
          toggleOpenState={setIsNewListModalOpen}
        />
      )}
      {!hasSavedLists && !savedListsDataIsLoading && !isFetching && (
        <PageEmptyState
          title="You don't have any lists"
          description="Create a list to organize your contacts"
          callToActionText="Create new list"
          onClick={() => {
            setIsNewListModalOpen(true);
          }}
        />
      )}
    </div>
  );
};

export default ListsPage;
