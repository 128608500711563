import { useEffect, useState } from "react";
import { FilterReturnType } from "src/components/Filters/Filters";

type UseProvideDataProps = {
  selectedInvestorIds: number[];
  selectedFundIds: number[];
};

export const useProvideData = ({
  selectedInvestorIds,
  selectedFundIds,
}: UseProvideDataProps): [values: FilterReturnType] => {
  const [values, setValues] = useState<FilterReturnType>({});
  useEffect(() => {
    const values: FilterReturnType = {};
    if (selectedInvestorIds.length) {
      values.investors = selectedInvestorIds;
    }
    if (selectedFundIds.length) {
      values.funds = selectedFundIds;
    }

    setValues(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInvestorIds.length, selectedFundIds.length]);

  return [values];
};
