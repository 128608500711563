import { Button, Tooltip, Typography } from "@50y/celestial";
import { useContext, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack, useCopyToClipboard } from "src/hooks";
import { ActiveSectionContext } from "src/pages/ResourcePage/context";

export enum MarkdownHeadings {
  "h1" = "h1",
  "h2" = "h2",
  "h3" = "h3",
}

const tw = {
  hash: "font-medium underline invisible group-hover/heading:visible transition-colors duration-500 ease-in-out cursor-pointer ml-2 !text-interactive-text-text-b-primary",
  row: "flex justify-start align-center m-0 max-w-2xl w-full group/heading",
  h1: "mt-8 py-1",
  h2: "mt-4 py-1 flex items-center w-full group/heading",
  h3: "mt-2 py-1",
};

export const WrappedHeading = (props: any) => {
  const { ref, inView } = useInView({
    rootMargin: "-50px 0% -70% 0px",
    threshold: 1,
  });
  const { setActiveSection, activeSection, contentLoaded, setContentLoaded } =
    useContext(ActiveSectionContext);
  const { hash, pathname } = useLocation();
  const [value, copy] = useCopyToClipboard();
  const { track } = useAmplitudeTrack();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (hash && hash.slice(1) === props.id) {
      const heading = document.getElementById(hash.slice(1));
      timeout = setTimeout(() => {
        heading?.scrollIntoView({ block: "start" });
      }, 100);
    }
    return () => clearTimeout(timeout);
    // we want to run it only on mount
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!contentLoaded) {
      setContentLoaded(true);
    }
  }, [contentLoaded, setContentLoaded]);

  useEffect(() => {
    if (!contentLoaded) {
      return;
    }
    if (
      inView &&
      activeSection !== props.id &&
      (props.el === MarkdownHeadings.h1 || props.el === MarkdownHeadings.h2) // only h1 and h2 belong to TOC
    ) {
      setActiveSection(props.id);
    }
    // only when intersecting
    // eslint-disable-next-line
  }, [inView, contentLoaded]);

  const clipboardURL = useMemo(
    () => `${window.location.origin}${pathname}#${props.id}`,
    [props.id, pathname],
  );

  const tooltipText = useMemo(() => {
    if (value.error) {
      return value.error;
    }
    if (value.message) {
      return "URL copied";
    }
    return "Copy URL to clipboard";
  }, [value]);

  const handleCopyToClipboard = () => {
    track(ActionEvents.RESOURCE_LINK_COPIED, { page: clipboardURL });
    copy(clipboardURL);
  };

  const HashTooltip = ({ element }: { element: "h1" | "h2" | "h3" }) => (
    <Tooltip text={tooltipText} placement="bottom" offset={15}>
      <Button
        variant="unstyled"
        size="unstyled"
        width="auto"
        className={tw.hash}
        onPress={handleCopyToClipboard}
        textProps={{ ...typographyProps[element] }}
      >
        #
      </Button>
    </Tooltip>
  );

  const typographyProps = {
    h1: {
      size: "title-2",
      variant: "prominent-1",
    },
    h2: {
      size: "title-3",
      variant: "prominent-1",
    },
    h3: {
      size: "title-4",
      variant: "default",
    },
  } as const;

  return (
    <div className={tw.row}>
      {props.el === MarkdownHeadings.h1 && (
        <Typography
          id={props.id}
          className={tw.h1}
          ref={ref}
          as="h1"
          {...typographyProps.h1}
        >
          {props.children}
          <HashTooltip element="h1" />
        </Typography>
      )}
      {props.el === MarkdownHeadings.h2 && (
        <Typography
          id={props.id}
          className={tw.h2}
          ref={ref}
          as="h2"
          {...typographyProps.h2}
        >
          {props.children}
          <HashTooltip element="h2" />
        </Typography>
      )}
      {props.el === MarkdownHeadings.h3 && (
        <Typography
          id={props.id}
          className={tw.h3}
          ref={ref}
          as="h4"
          {...typographyProps.h3}
        >
          {props.children}
          <HashTooltip element="h3" />
        </Typography>
      )}
    </div>
  );
};
