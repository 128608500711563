import { Button, Modal, TextInput, Typography } from "@50y/celestial";
import { useState } from "react";
import { FocusScope } from "react-aria";

const createListData = {
  title: "Create a list",
  description:
    "Name your new list to easily identify and organize your contacts.",
  buttonText: "Create list",
};

const editListData = {
  title: "Edit name",
  description: "Type your new change in the input field below.",
  buttonText: "Save Changes",
};

interface IModalProps {
  toggleOpenState: (isOpen: boolean) => void;
  manageList: (listName: string) => void;
  isEdit?: boolean;
  listName?: string;
}

export const ManageListModal = ({
  toggleOpenState,
  manageList,
  isEdit = false,
  listName,
}: IModalProps) => {
  const [newListName, setNewListName] = useState(listName || "");

  return (
    <Modal isOpen={true} onClose={() => toggleOpenState(false)}>
      <FocusScope autoFocus>
        <div className="grid gap-4 mb-8">
          <Typography variant="prominent-1" size="title-4">
            {isEdit ? editListData.title : createListData.title}
          </Typography>
          <Typography variant="default" size="body-4">
            {isEdit ? editListData.description : createListData.description}
          </Typography>
          <TextInput
            autoFocus
            placeholder="Type here"
            label="List Name"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
            onKeyUp={(e) => {
              if (e.key === "Enter" && newListName.length) {
                manageList(newListName);
              }
            }}
            inputProps="bg-inherit"
          />
        </div>
      </FocusScope>
      <Button
        isDisabled={!newListName.length}
        onPress={() => manageList(newListName)}
      >
        {isEdit ? editListData.buttonText : createListData.buttonText}
      </Button>
    </Modal>
  );
};
