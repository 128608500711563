import type { SVGProps } from "react";

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M12.75 16.5V10.5H9.75V12H11.25V16.5H9V18H15V16.5H12.75Z" />
    <path d="M12 6C11.7775 6 11.56 6.06598 11.375 6.1896C11.19 6.31322 11.0458 6.48892 10.9606 6.69449C10.8755 6.90005 10.8532 7.12625 10.8966 7.34448C10.94 7.56271 11.0472 7.76317 11.2045 7.9205C11.3618 8.07783 11.5623 8.18498 11.7805 8.22839C11.9988 8.2718 12.225 8.24952 12.4305 8.16437C12.6361 8.07922 12.8118 7.93503 12.9354 7.75002C13.059 7.56502 13.125 7.34751 13.125 7.125C13.125 6.82664 13.0065 6.54049 12.7955 6.32951C12.5845 6.11853 12.2984 6 12 6Z" />
    <path d="M12 22.5C9.9233 22.5 7.89323 21.8842 6.16652 20.7304C4.4398 19.5767 3.09399 17.9368 2.29927 16.0182C1.50455 14.0996 1.29661 11.9884 1.70176 9.95156C2.1069 7.91476 3.10693 6.04383 4.57538 4.57538C6.04383 3.10693 7.91476 2.1069 9.95156 1.70176C11.9884 1.29661 14.0996 1.50455 16.0182 2.29927C17.9368 3.09399 19.5767 4.4398 20.7304 6.16652C21.8842 7.89323 22.5 9.9233 22.5 12C22.5 14.7848 21.3938 17.4555 19.4246 19.4246C17.4555 21.3938 14.7848 22.5 12 22.5ZM12 3C10.22 3 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C21 9.61306 20.0518 7.32387 18.364 5.63604C16.6761 3.94822 14.387 3 12 3Z" />
  </svg>
);
