import { track as trackAmplitude } from "@amplitude/analytics-browser";
import { useCallback } from "react";
import { useMe } from "src/queries/useMe";
import {
  getUserAmplitudeProperties,
  UserProperties,
} from "src/utils/getUserAmplitudeProperties";
import { useBackendAmplitude } from "./useBackendAmplitude";
import { HAS_BLOCKER_ACTIVE } from "./useInitAmplitude";

export interface IAmplitudeTrack {
  user_properties: UserProperties;
  event_type: string;
  event_properties?: any;
}

export const useAmplitudeTrack = () => {
  const { mutate } = useBackendAmplitude();
  const { data: me } = useMe();

  const track = useCallback(
    (eventName: string, eventProperties?: any) => {
      if (localStorage.getItem(HAS_BLOCKER_ACTIVE)) {
        const user_properties = getUserAmplitudeProperties(me?.data);
        const events = [
          {
            user_properties,
            event_type: eventName,
            event_properties: eventProperties,
          },
        ];
        return mutate(events);
      }
      trackAmplitude(eventName, eventProperties);
    },
    [me, mutate],
  );
  return { track };
};
