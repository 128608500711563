export const PlusCalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M18.75 15.75H16.5V13.5H15V15.75H12.75V17.25H15V19.5H16.5V17.25H18.75V15.75Z" />
    <path d="M15.75 22.5C12.45 22.5 9.75 19.8 9.75 16.5C9.75 13.2 12.45 10.5 15.75 10.5C19.05 10.5 21.75 13.2 21.75 16.5C21.75 19.8 19.05 22.5 15.75 22.5ZM15.75 12C13.275 12 11.25 14.025 11.25 16.5C11.25 18.975 13.275 21 15.75 21C18.225 21 20.25 18.975 20.25 16.5C20.25 14.025 18.225 12 15.75 12Z" />
    <path d="M20.25 4.5C20.25 3.675 19.575 3 18.75 3H15.75V1.5H14.25V3H8.25V1.5H6.75V3H3.75C2.925 3 2.25 3.675 2.25 4.5V19.5C2.25 20.325 2.925 21 3.75 21H8.25V19.5H3.75V4.5H6.75V6H8.25V4.5H14.25V6H15.75V4.5H18.75V9H20.25V4.5Z" />
  </svg>
);
