import { Typography } from "@50y/celestial";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "src/assets/icons/Logo";
import { useWindowSize } from "src/hooks";
import { ScreenBreakpoints } from "src/utils/constants";

export const MobilePage = () => {
  const { windowSize } = useWindowSize();
  const navigate = useNavigate();

  useEffect(() => {
    if (windowSize.width > ScreenBreakpoints.TABLET) {
      navigate(-1);
    }
  }, [windowSize.width, navigate]);
  return (
    <div className="w-full h-screen bg-elevation-background">
      <div className="flex py-5 justify-center border-b border-elevation-outline-1">
        <Logo />
      </div>

      <div className="px-5 w-80 m-[0_auto] absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center flex-col ">
        <Typography variant="prominent-1" size="title-1">
          Oh no...
        </Typography>
        <Typography variant="default" size="body-2" className="text-center">
          Looks like this page isn’t really mobile friendly. Try using your
          desktop instead for a better experience.
        </Typography>
      </div>
    </div>
  );
};
