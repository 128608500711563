export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <g clipPath="url(#clip0_2795_6357)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4146 6.63582C16.5624 8.7837 16.5624 12.2661 14.4146 14.414C12.2667 16.5619 8.78427 16.5619 6.63638 14.414C4.4885 12.2661 4.4885 8.7837 6.63638 6.63582C8.78427 4.48793 12.2667 4.48793 14.4146 6.63582ZM15.9775 14.9162C18.1971 12.1673 18.0297 8.12967 15.4752 5.57515C12.7415 2.84148 8.30939 2.84148 5.57572 5.57515C2.84205 8.30882 2.84205 12.741 5.57572 15.4746C8.13023 18.0292 12.1679 18.1966 14.9168 15.9769L19.8946 20.9547L20.9553 19.8941L15.9775 14.9162Z"
        fill="#171717"
      />
    </g>
    <defs>
      <clipPath id="clip0_2795_6357">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
