export const ArrowRoundedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="fill-current"
    {...props}
  >
    <path
      d="M12.1339 5.88387L8.01787 10L12.1339 14.1161L11.25 15L6.25 10L11.25 5L12.1339 5.88387Z"
      fill="#161616"
    />
    <path
      d="M10 18.75C5.17519 18.75 1.25 14.8248 1.25 10C1.25 5.17519 5.17519 1.25 10 1.25C14.8248 1.25 18.75 5.17519 18.75 10C18.75 14.8248 14.8248 18.75 10 18.75ZM10 2.5C5.86456 2.5 2.5 5.86456 2.5 10C2.5 14.1354 5.86456 17.5 10 17.5C14.1354 17.5 17.5 14.1354 17.5 10C17.5 5.86456 14.1354 2.5 10 2.5Z"
      fill="#161616"
    />
  </svg>
);
