import type { SVGProps } from "react";

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M9.75 18L5 13.25L6.0605 12.1895L9.75 15.8782L18.9395 6.6895L20 7.75L9.75 18Z" />
  </svg>
);
