import { AxiosRequestConfig } from "axios";
import qs from "qs";
import { GenericApiResponse } from "src/models/api-response";
import {
  FounderResources,
  GeneralStrapiResponse,
} from "src/models/strapi-api-response";
import { getHeaders, getRequestData } from "./global.service";

const apiServerUrl = process.env.REACT_APP_API_STRAPI_URL as string;
const accessToken = process.env.REACT_APP_STRAPI_TOKEN as string;

export const getPages = async ({ pageParam = 1 }: { pageParam?: number }) => {
  const queryObject = {
    populate: {
      children: {
        name: "",
      },
      parent: {
        name: "",
      },
      image: "*",
      icon: "*",
      coda_blocks: {
        populate: "*",
      },
    },
    sort: "id",
    pagination: {
      pageSize: 100,
      page: pageParam,
    },
    filters: {
      show_in_nav: {
        $eq: true,
      },
    },
  };
  const query = qs.stringify(queryObject);
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/pages?${query}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  const { data, error } = (await getRequestData(config)) as GenericApiResponse<
    GeneralStrapiResponse<FounderResources>
  >;
  return {
    data,
    error,
  };
};

export const getPage = async ({ slug }: { slug: string }) => {
  const queryObject = {
    populate: {
      children: {
        populate: ["image"],
      },
      parent: {
        name: "",
      },
      image: "*",
      icon: "*",
      coda_blocks: {
        populate: "*",
      },
    },
    filters: {
      slug: {
        $eqi: slug,
      },
    },
    sort: "id",
  };
  const query = qs.stringify(queryObject);
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/pages?${query}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };
  const { data, error } = (await getRequestData(config)) as GenericApiResponse<
    GeneralStrapiResponse<FounderResources>
  >;
  return {
    data,
    error,
  };
};

export const searchResources = async ({
  searchTerm,
}: {
  searchTerm: string;
}) => {
  const queryObject = {
    filters: {
      $or: [
        {
          name: {
            $containsi: searchTerm,
          },
        },
        {
          coda_markdown: {
            $containsi: searchTerm,
          },
        },
      ],
    },
  };
  const query = qs.stringify(queryObject);
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/pages?${query}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };
  const { data, error } = (await getRequestData(config)) as GenericApiResponse<
    GeneralStrapiResponse<FounderResources>
  >;
  return {
    data,
    error,
  };
};

export const getPageIcon = async ({ url }: { url: string }) => {
  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    responseType: "text",
  };
  const { data, error } = await getRequestData(config);
  return {
    data,
    error,
  };
};
