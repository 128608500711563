import { Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useMemo, useState } from "react";
import { CalendarIcon } from "src/assets/icons/general/CalendarIcon";
import EventCard from "src/components/EventComponents";
import { EventCardAttendance } from "src/components/EventComponents/EventCardAttendance";
import {
  EventModal,
  ExtendedEvent,
} from "src/components/EventComponents/EventModal";
import { Loader } from "src/components/Loader";
import PageEmptyState from "src/components/PageEmptyState";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { PACIFIC_TIMEZONE } from "src/config/constants";
import dayjs from "src/config/dayjs";
import { useTrackPageVisited } from "src/hooks";
import { Event, EventTypes } from "src/models/events-api-response";
import { useEvents } from "src/queries/events/useEvents";
import { useMe } from "src/queries/useMe";

const tw = {
  page: "p-6 h-vh overflow-y-auto bg-elevation-surface-1 relative flex flex-row justify-center gap-6",
  eventsContainer: "flex flex-col w-full gap-4 max-w-event-card",
  noFutureEvents:
    "bg-elevation-surface-2 px-12 py-6 flex items-center justify-center flex-col rounded-s h-[80vh]",
  pastEventCard:
    "w-full bg-white rounded-s shadow-base p-6 flex gap-2 flex-col cursor-pointer",
};

export const EventsPage = () => {
  const { data, isLoading: eventsLoading } = useEvents();
  const [selectedEvent, setSelectedEvent] = useState<ExtendedEvent>();
  const { data: currentUser } = useMe();

  useTrackPageVisited(NavigationEvents.EVENTS_PAGE_VIEWED);

  const filterEventByDate = (item: Event) => {
    return dayjs(item.start_datetime)
      .tz(PACIFIC_TIMEZONE)
      .isSameOrAfter(dayjs().tz(PACIFIC_TIMEZONE), "hour");
  };

  const upcomingEvents = useMemo(
    () => (data?.data?.results || []).filter((item) => filterEventByDate(item)),
    [data],
  );

  const pastEvents = useMemo(
    () =>
      (data?.data?.results || [])
        .filter((item) => !filterEventByDate(item))
        .reverse(),
    [data],
  );

  if (!eventsLoading && !data) {
    // description TBD
    return (
      <PageEmptyState
        title="No events"
        description="There are no events yet!"
      />
    );
  }

  if (eventsLoading) {
    return <Loader />;
  }

  return (
    <div className={tw.page}>
      <div className={tw.eventsContainer}>
        <Typography size="title-4" variant="prominent-1">
          Upcoming
        </Typography>
        {upcomingEvents.map((item) => (
          <EventCard
            key={item.id}
            event={item}
            onEventSelect={() =>
              setSelectedEvent({ ...item, isPastEvent: false })
            }
            currentUserId={currentUser?.data.id ?? 0}
          />
        ))}
        {upcomingEvents.length === 0 && (
          <div className={tw.noFutureEvents}>
            <CalendarIcon className="w-12 h-12 text-interactive-secondary-idle" />
            <Typography
              size="title-5"
              variant="prominent-1"
              className="mt-4 mb-2"
            >
              No planned events
            </Typography>
            <Typography size="body-4" variant="default" className="text-center">
              Looks like there are no planned events right now. Stay tuned, or
              reach out to a teammate to suggest one.
            </Typography>
          </div>
        )}
      </div>
      <div className={tw.eventsContainer}>
        <Typography size="title-4" variant="prominent-1">
          Past
        </Typography>
        {pastEvents.map((item) => (
          <div
            key={item.id}
            className={tw.pastEventCard}
            onClick={() => setSelectedEvent({ ...item, isPastEvent: true })}
          >
            <EventCardAttendance event={item} />
            <Typography variant="prominent-1" size="title-5">
              {item.name}
            </Typography>
            <a
              href={item.location_url}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Typography
                variant="prominent-2"
                size="body-4"
                className={cx(
                  "text-interactive-text-text-b-primary",
                  item.location_url ? "cursor-pointer" : "cursor-default",
                )}
              >
                {item.event_type === EventTypes.DEFAULT
                  ? item.location
                  : "Virtual"}
              </Typography>
            </a>
          </div>
        ))}
      </div>
      {selectedEvent && currentUser && (
        <EventModal
          event={selectedEvent}
          onModalClose={() => setSelectedEvent(undefined)}
          currentUserId={currentUser.data.id}
        />
      )}
    </div>
  );
};
