import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ListStatusEnum } from "src/components/ListStatusDropdown";
import { ActionEvents, RequestIntroEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { GenericApiResponse } from "src/models/api-response";
import { SavedList } from "src/models/lists-api-response";
import { queryClient } from "src/providers/queryClient";
import { QUERY_KEYS } from "src/queries/query-keys";
import { updatePersonStatusForList } from "src/services/lists.service";

export type UpdatePersonStatusForListVariables = {
  listId: number;
  personId: number;
  status: number;
  listName?: string;
};

export const useUpdatePersonStatusForList = (listId: number) => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation({
    mutationFn: async (variables: UpdatePersonStatusForListVariables) => {
      const accessToken = await getAccessTokenSilently();
      return updatePersonStatusForList({
        accessToken,
        ...variables,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        [QUERY_KEYS.LIST, { id: listId }],
        (oldData: GenericApiResponse<SavedList>) => {
          return {
            ...oldData,
            data: {
              ...oldData.data,
              saved_list_person_statuses: [
                ...oldData.data.saved_list_person_statuses.filter(
                  (user) => user.person !== variables.personId,
                ),
                { person: variables.personId, status: variables.status },
              ],
            },
          };
        },
      );
      const params = {
        list_name: variables.listName,
        list_id: variables.listId,
        person_id: variables.personId,
        person_status: ListStatusEnum[variables.status],
      };
      if (variables.status === 3) {
        track(RequestIntroEvents.REQUEST_INTRO_MADE, { ...params });
      } else if (variables.status === 4) {
        track(RequestIntroEvents.REQUEST_INTRO_DECLINED, { ...params });
      }
      track(ActionEvents.LIST_PERSON_STATUS_CHANGED, { ...params });
    },
    onError: () => {
      toast.error("Unable to update status for person in list");
    },
  });
};
