import * as amplitude from "@amplitude/analytics-browser";
import { User } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef } from "react";
import { ActionEvents, IErrorEventParams } from "src/config/amplitudeEvents";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useMe } from "src/queries/useMe";
import { getDefaultEmail } from "src/utils/helpers";

export const HAS_BLOCKER_ACTIVE = "hasBlockerActive";

export enum Screen {
  VIEWPORT_WIDTH = "viewport_width",
  VIEWPORT_HEIGHT = "viewport_height",
  SCREEN_RESOLUTION = "screen_resolution",
}

const useInitAmplitude = (user: User | undefined) => {
  const { data: currentUser } = useMe();
  const identityRef = useRef<amplitude.Identify>();

  const { windowSize } = useWindowSize();

  useEffect(() => {
    // Clear local storage on mount
    localStorage.removeItem(HAS_BLOCKER_ACTIVE);
  }, []);

  useEffect(() => {
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API!, undefined, {
      defaultTracking: true,
      loggerProvider: {
        enable: () => null,
        error: () => {
          // set local storage when amplitude fails and is not already set
          // after this, we will log activity over backend for the user session
          if (localStorage.getItem(HAS_BLOCKER_ACTIVE) === null) {
            localStorage.setItem(HAS_BLOCKER_ACTIVE, "true");
          }
        },
        disable: () => null,
        log: () => null,
        warn: () => null,
        debug: () => null,
      },
      logLevel: amplitude.Types.LogLevel.Verbose,
    });

    const handleWindowError = (event: ErrorEvent) => {
      const params: IErrorEventParams = {
        message: event.message,
        stack: event.error?.stack,
      };
      amplitude.track(ActionEvents.GLOBAL_ERROR, params);
    };

    window.addEventListener("error", handleWindowError);

    return () => {
      window.removeEventListener("error", handleWindowError);
    };
  }, []);

  useEffect(() => {
    if (user) {
      amplitude.setUserId(user.email);
    }
  }, [user]);

  useEffect(() => {
    if (windowSize && identityRef?.current) {
      identityRef.current
        .setOnce(Screen.VIEWPORT_WIDTH, windowSize.width)
        .setOnce(Screen.VIEWPORT_HEIGHT, windowSize.height);

      amplitude.identify(identityRef.current as amplitude.Identify);
    }
  }, [windowSize]);

  useEffect(() => {
    const addEventPlugin = () => {
      const name = "add-event-founder";
      const type = amplitude.Types.PluginType.ENRICHMENT;
      let amplitudeConfig; // eslint-disable-line @typescript-eslint/no-unused-vars

      const setup = (config: any) => {
        amplitudeConfig = config; // eslint-disable-line @typescript-eslint/no-unused-vars
      };

      const execute = (
        event: Event & {
          event_properties: Record<
            string,
            string | number | boolean | undefined
          >;
        },
      ) => {
        event.event_properties = {
          ...(event.event_properties || {}),
        };

        return event;
      };

      return {
        name,
        type,
        setup,
        execute,
      };
    };

    if (!currentUser?.data) return;
    amplitude.add(addEventPlugin() as any);
  }, [currentUser]);

  const getScreenResolution = useCallback(() => {
    const swidth = window.screen.width;
    const sheight = window.screen.height;

    return `${swidth}x${sheight} `;
  }, []);

  useEffect(() => {
    if (!currentUser?.data?.person.emails.length) return;
    const { person } = currentUser.data;

    const identify = new amplitude.Identify()
      .setOnce("user", person.id)
      .setOnce("user_email", getDefaultEmail(person.emails))
      .setOnce("is_founder", currentUser.data.is_founder)
      .setOnce(Screen.SCREEN_RESOLUTION, getScreenResolution())
      .setOnce(Screen.VIEWPORT_WIDTH, window.innerWidth)
      .setOnce(Screen.VIEWPORT_HEIGHT, window.innerHeight);

    identityRef.current = identify;
    amplitude.identify(identify);
  }, [currentUser, getScreenResolution]);
};

export default useInitAmplitude;
