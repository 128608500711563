import { Typography } from "@50y/celestial";
import qs from "qs";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import thankYouForTheFeedback from "src/assets/no-saved-lists.svg";
import { useClosingTheLoopMutation } from "src/queries/useClosingTheLoopMutation";

const IntroPage = () => {
  const { search } = useLocation();
  const { mutate } = useClosingTheLoopMutation();

  useEffect(() => {
    if (search) {
      const params = qs.parse(search.substring(1));
      mutate(params);
    }
  }, [search, mutate]);

  return (
    <>
      <div className="flex flex-col justify-center items-center translate-y-2/4 gap-3">
        <img
          alt="Thank you for the feedback"
          src={thankYouForTheFeedback}
        ></img>
        <Typography variant="prominent-1" size="title-2">
          Thank you for the feedback
        </Typography>
      </div>
    </>
  );
};

export default IntroPage;
