export const GoogleCalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    {...props}
  >
    <path
      d="M18.4925 0.94574H15.2412V5.68255H19.9987V2.2814C20 2.2814 19.7636 1.07491 18.4925 0.94574Z"
      fill="#1967D2"
    />
    <path
      d="M15.2427 21.0321V21.0438V21.0541L20.0002 16.3173H19.9575L15.2427 21.0321Z"
      fill="#1967D2"
    />
    <path
      d="M20.0001 16.3173V16.2747L19.9575 16.3173H20.0001Z"
      fill="#FBBC05"
    />
    <path
      d="M20.0002 5.68253H15.2427V16.2748H20.0002V5.68253Z"
      fill="#FBBC05"
    />
    <path
      d="M19.9575 16.3173H15.2427V21.0321L19.9575 16.3173Z"
      fill="#EA4335"
    />
    <path
      d="M15.2427 16.3173H19.9575L20.0002 16.2747H15.2427V16.3173Z"
      fill="#EA4335"
    />
    <path d="M15.231 21.0439H15.2426V21.0323L15.231 21.0439Z" fill="#34A853" />
    <path
      d="M4.65039 16.2747V21.0438H15.231L15.2427 16.2747H4.65039Z"
      fill="#34A853"
    />
    <path
      d="M15.2426 16.3173V16.2747L15.231 21.0438L15.2426 21.0321V16.3173Z"
      fill="#34A853"
    />
    <path
      d="M0 16.2747V19.5906C0.0426274 20.6666 1.20002 21.0438 1.20002 21.0438H4.65026V16.2747H0Z"
      fill="#188038"
    />
    <path
      d="M4.65026 5.68255H15.2425V0.94574H1.33437C1.33437 0.94574 0.0852548 1.07491 0 2.40928V16.2748H4.65026V5.68255Z"
      fill="#4285F4"
    />
    <path
      d="M8.11551 14.1307C7.87318 14.1307 7.6395 14.0989 7.41448 14.0355C7.19523 13.972 6.99329 13.8768 6.80865 13.7499C6.62402 13.6172 6.45958 13.4527 6.31534 13.2565C6.17686 13.0604 6.07012 12.8325 5.99512 12.5728L7.05964 12.1487C7.13465 12.4372 7.26158 12.6565 7.44044 12.8065C7.61931 12.9507 7.84433 13.0229 8.11551 13.0229C8.23667 13.0229 8.35207 13.0056 8.46169 12.9709C8.57132 12.9305 8.66652 12.8757 8.7473 12.8065C8.82807 12.7373 8.89154 12.6565 8.9377 12.5642C8.98963 12.4661 9.01559 12.3565 9.01559 12.2353C9.01559 11.9814 8.92039 11.7824 8.72999 11.6381C8.54535 11.4939 8.2886 11.4218 7.95972 11.4218H7.4491V10.3919H7.91645C8.03185 10.3919 8.14436 10.3774 8.25398 10.3486C8.36361 10.3197 8.45881 10.2765 8.53959 10.2188C8.62613 10.1553 8.69248 10.0774 8.73864 9.98508C8.79057 9.887 8.81653 9.77449 8.81653 9.64755C8.81653 9.45138 8.7473 9.29271 8.60882 9.17154C8.47035 9.04461 8.28283 8.98114 8.04627 8.98114C7.7924 8.98114 7.59623 9.05038 7.45775 9.18885C7.32505 9.32156 7.23273 9.47157 7.1808 9.6389L6.14225 9.21482C6.19417 9.07057 6.27207 8.92344 6.37592 8.77343C6.47978 8.61765 6.6096 8.47917 6.76538 8.35801C6.92693 8.23107 7.11445 8.1301 7.32793 8.05509C7.54142 7.97432 7.78663 7.93393 8.06358 7.93393C8.3463 7.93393 8.60305 7.97432 8.83384 8.05509C9.0704 8.13587 9.27235 8.24838 9.43967 8.39263C9.60699 8.5311 9.73681 8.69842 9.82913 8.8946C9.92144 9.085 9.9676 9.29271 9.9676 9.51773C9.9676 9.69082 9.94452 9.84661 9.89837 9.98508C9.85798 10.1236 9.80316 10.2476 9.73393 10.3572C9.66469 10.4669 9.58391 10.5621 9.4916 10.6428C9.40505 10.7178 9.31562 10.7784 9.2233 10.8246V10.8938C9.50025 11.0034 9.72816 11.1794 9.90702 11.4218C10.0917 11.6641 10.184 11.9699 10.184 12.3391C10.184 12.5988 10.1349 12.8382 10.0368 13.0575C9.93875 13.271 9.79739 13.4585 9.61276 13.62C9.4339 13.7816 9.21753 13.9056 8.96366 13.9922C8.70979 14.0845 8.42707 14.1307 8.11551 14.1307Z"
      fill="#4285F4"
    />
    <path
      d="M12.2507 13.9922V9.40522L11.2035 9.84661L10.7881 8.88594L12.5277 8.0724H13.3845V13.9922H12.2507Z"
      fill="#4285F4"
    />
  </svg>
);
