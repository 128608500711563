import { Button, Modal, Typography } from "@50y/celestial";
import {
  DEFAULT_CANCEL_BUTTON_TEXT,
  DEFAULT_DELETE_BUTTON_TEXT,
  DEFAULT_HEADER,
} from "./constants";

interface IProps {
  onDelete: () => void;
  onClose: () => void;
  headerText?: string;
  bodyText?: string;
  deleteButtonText?: string;
  cancelButtonText?: string;
}

const DeleteModal = ({
  onDelete,
  onClose,
  headerText = DEFAULT_HEADER,
  bodyText,
  deleteButtonText = DEFAULT_DELETE_BUTTON_TEXT,
  cancelButtonText = DEFAULT_CANCEL_BUTTON_TEXT,
}: IProps) => (
  <Modal isOpen={true} onClose={onClose}>
    <div className="grid gap-4 mb-8">
      <Typography variant="prominent-1" size="title-4">
        {headerText}
      </Typography>
      <Typography variant="default" size="body-4">
        {bodyText}
      </Typography>
    </div>
    <div className="flex gap-4">
      <Button onPress={onClose} variant="outlined">
        {cancelButtonText}
      </Button>
      <Button variant="primary" onPress={onDelete}>
        {deleteButtonText}
      </Button>
    </div>
  </Modal>
);

export default DeleteModal;
