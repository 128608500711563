import { Routes } from "src/config/routes";
import {
  FounderResources,
  StrapiInterface,
} from "src/models/strapi-api-response";

export const getHeadings = (markdown: string) => {
  const regex = /#{1,6} .+(?=\n)/g;
  return markdown.match(regex)?.filter((reg) => !reg.startsWith("###"));
};

export const generateBreadcrumbs = (
  pathname: string,
  data: StrapiInterface<FounderResources>[],
) => {
  const breadcrumbsList = pathname
    .replace(Routes.RESOURCES, "")
    .split("/")
    .filter((route) => route);

  if (data[0].attributes.parent_unpublished && breadcrumbsList.length > 1) {
    breadcrumbsList.splice(breadcrumbsList.length - 2, 1);
  }

  let currentRoute = `${Routes.RESOURCES}`;

  return breadcrumbsList.map((slug, index) => {
    currentRoute = currentRoute + `/${slug}`;
    return {
      link: currentRoute,
      title:
        index + 1 === breadcrumbsList.length
          ? data[0].attributes.name
          : slug.replaceAll("-", " "),
    };
  });
};

export const slugToLink = (slug: string) => {
  return slug.replaceAll(".", "/");
};
