import { useEffect, useRef } from "react";
import { useAmplitudeTrack } from "src/hooks";

export const useTrackPageVisited = (pageEvent: string) => {
  const { track } = useAmplitudeTrack();
  const captured = useRef(false);

  useEffect(() => {
    if (captured.current) return;
    captured.current = true;
    track(pageEvent);
  }, [pageEvent, track]);
};

export default useTrackPageVisited;
