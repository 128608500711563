import { IconButton } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { Ref } from "react";
import {
  SavedListIconFilled,
  SavedListsIcon,
} from "src/assets/icons/ui/SavedListsIcon";

type StarIconButtonProps = {
  isSaved: boolean;
  buttonRef?: Ref<HTMLButtonElement> | undefined;
  onClick?: () => void;
};

const StarIconButton = ({
  isSaved,
  buttonRef,
  onClick,
  ...props
}: StarIconButtonProps) => {
  return (
    <IconButton
      {...props}
      onPress={onClick}
      icon={isSaved ? SavedListIconFilled : SavedListsIcon}
      label="Save list"
      className={cx(
        isSaved ? "fill-interactive-text-text-b-primary" : "fill-current",
      )}
    />
  );
};

export default StarIconButton;
