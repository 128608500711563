export enum QUERY_KEYS {
  FOUNDER_RESOURCES = "FOUNDER_RESOURCES", // all resources
  FOUNDER_RESOURCE = "FOUNDER_RESOURCE", // single resource
  STRAPI_ICON = "STRAPI_ICON", // icon for navigation
  COMPANY = "COMPANY",
  EVENTS = "EVENTS",
  INVESTOR = "INVESTOR",
  INVESTOR_50Y_CONNECTIONS = "INVESTOR_50Y_CONNECTIONS",
  CURENT_USER = "CURENT_USER",
  PERSON_STATUSES = "PERSON_STATUSES",
  PERSONS = "PERSONS",
  USER = "USER",
  FUNDS = "FUNDS",
  INVESTOR_TITLES = "INVESTOR_TITLES",
  IMPORTS = "IMPORTS",
  INVESTORS = "INVESTORS",
  CONNECTIONS = "CONNECTIONS",
  LISTS = "LISTS",
  LISTS_INFINITE = "LISTS_INFINITE",
  LIST = "LIST",
  LIST_PEOPLE = "LIST_PEOPLE",
  GOOGLE_TOKEN = "GOOGLE_TOKEN",
}
