import { QueryClient } from "@tanstack/react-query";
import {
  founderResourcesQuery,
  singleFounderResourceQuery,
} from "src/queries/founderResourcesQueries";

export const founderResourcesLoader = async (queryClient: QueryClient) => {
  const query = founderResourcesQuery({ pageParam: 1 });
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};

export const singleFounderResourceLoader = async (
  queryClient: QueryClient,
  slug: string,
) => {
  const query = singleFounderResourceQuery({ slug });
  return (
    queryClient.getQueryData(query.queryKey) ??
    (await queryClient.fetchQuery(query))
  );
};
