import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { PaginatedApiResponse } from "src/models/api-response";
import { Fund } from "src/models/funds-api-response";
import { getIntersectionElements } from "src/utils/getIntersectionElements";
import {
  API_SERVER_URL,
  DEFAULT_PAGE_SIZE,
  getHeaders,
} from "./global.service";

const FUNDS_FILTER_LIMIT = 1000;

export const getFunds = async ({
  accessToken,
  investorIds,
  ordering,
  page,
}: {
  accessToken: string;
  investorIds: number[];
  ordering?: string;
  page: number;
}): Promise<AxiosResponse<PaginatedApiResponse<Fund>>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/funds`,
    method: "GET",
    headers: getHeaders(accessToken),
    params: {
      limit: FUNDS_FILTER_LIMIT,
      offset: (page - 1) * DEFAULT_PAGE_SIZE,
      investorIds: getIntersectionElements(investorIds).join(","),
      ordering,
    },
    paramsSerializer: {
      indexes: true,
    },
  };

  return axios(config);
};
