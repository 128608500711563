import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { GenericApiResponse } from "src/models/api-response";
import {
  Entities,
  GoogleConnectionsImportHistory,
  Import,
  ImportGmailThreadsResult,
  ImportGoogleContactsResult,
  ImportGoogleEventsResult,
} from "src/models/imports-api-response";
import { ThreadSummary } from "./callGoogleApis";
import { API_SERVER_URL, getHeaders, getRequestData } from "./global.service";

export const importGoogleContacts = async (
  accessToken: string,
  email: string,
  connections: any,
  batchId: string,
): Promise<GenericApiResponse<ImportGoogleContactsResult>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/connections/google/import`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: { connections, email, batchId },
  };

  const { data, error } = (await getRequestData(
    config,
  )) as GenericApiResponse<ImportGoogleContactsResult>;

  return {
    data,
    error,
  };
};

export const importGoogleEvents = async (
  accessToken: string,
  email: string,
  events: any,
  batchId: string,
): Promise<GenericApiResponse<ImportGoogleEventsResult>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/events/google/import`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: { events, email, batchId },
  };

  const { data, error } = (await getRequestData(
    config,
  )) as GenericApiResponse<ImportGoogleEventsResult>;

  return {
    data,
    error,
  };
};

export const importGmailThreads = async (
  accessToken: string,
  email: string,
  threadSummaries: ThreadSummary[],
  batchId: string,
): Promise<GenericApiResponse<ImportGmailThreadsResult>> => {
  // Transform threadSummaries to json acceptable threads for API
  const threads = threadSummaries.map((summary: ThreadSummary) => {
    return {
      ...summary,
      participants: Array.from(summary.participants),
      recipients: Array.from(summary.recipients),
    };
  });

  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/threads/gmail/import`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: { threads, email, batchId },
  };

  const { data, error } = (await getRequestData(
    config,
  )) as GenericApiResponse<ImportGmailThreadsResult>;

  return {
    data,
    error,
  };
};

export const getGoogleImportHistory = async (
  accessToken: string,
): Promise<GenericApiResponse<GoogleConnectionsImportHistory>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/google/import_history`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  const { data, error } = (await getRequestData(
    config,
  )) as GenericApiResponse<GoogleConnectionsImportHistory>;

  return {
    data,
    error,
  };
};

export const updateGmailThreadsImport = async (
  accessToken: string,
): Promise<
  AxiosResponse<{
    message: string;
  }>
> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/threads/gmail/import`,
    method: "PUT",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const syncAirtable = async (
  accessToken: string,
  entity: Entities,
): Promise<
  AxiosResponse<{
    count: number;
    created: { count: number };
    updated: { count: number };
  }>
> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/airtable/${entity}/import`,
    method: "POST",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getImports = async (
  accessToken: string,
): Promise<AxiosResponse<Import[]>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/connections/linkedin/import`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};
