import type { SVGProps } from "react";

export const ArrowDropDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="fill-current"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25105 9.75105C6.58579 9.41632 7.1285 9.41632 7.46323 9.75105L12 14.2878L16.5368 9.75105C16.8715 9.41632 17.4142 9.41632 17.7489 9.75105C18.0837 10.0858 18.0837 10.6285 17.7489 10.9632L12.6061 16.1061C12.2714 16.4408 11.7286 16.4408 11.3939 16.1061L6.25105 10.9632C5.91632 10.6285 5.91632 10.0858 6.25105 9.75105Z"
    />
  </svg>
);
