export const Logo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="fill-current"
    {...props}
  >
    <g clipPath="url(#clip0_4311_9949)">
      <path
        d="M0 0V32H32V0H0ZM10.9 24.3076C10.9 24.9086 10.8457 25.4182 10.7512 25.7462C10.3875 27.1849 9.25826 28.2226 7.3108 28.2226C4.87089 28.2226 3.72384 26.6017 3.72384 24.6721H5.6899C5.6899 25.8005 6.2731 26.4745 7.32642 26.4745C8.10972 26.4745 8.61928 26.1107 8.81938 25.4732C8.87368 25.2724 8.94658 24.9815 8.94658 24.3076V23.6344C8.94658 22.8518 8.89228 22.6331 8.78293 22.3237C8.56497 21.7769 8.25478 21.4496 7.36287 21.4496C6.72786 21.449 6.10254 21.6053 5.54261 21.9049L3.92245 21.3767L4.8151 15.277H10.4589V17.1158H6.30806L5.87066 20.1196C6.34451 19.8652 7.01771 19.7008 7.76382 19.7008C9.23892 19.7008 10.2037 20.3383 10.6411 21.5761C10.8412 22.1407 10.8955 22.7782 10.8955 23.4514L10.9 24.3076ZM19.619 23.8709C19.619 24.8179 19.5446 25.5461 19.2731 26.1836C18.7449 27.4944 17.4706 28.2226 15.9412 28.2226C14.4118 28.2226 13.1197 27.4944 12.5938 26.1836C12.3208 25.5461 12.2657 24.8179 12.2657 23.8709V19.5379C12.2657 18.5909 12.3401 17.8448 12.6117 17.2259C13.122 15.9695 14.3419 15.1869 15.9435 15.1869C17.545 15.1869 18.7836 15.9695 19.2909 17.2259C19.5646 17.8448 19.619 18.5909 19.619 19.5379V23.8709ZM27.0733 8.00558V10.3689H25.5811V8.00558L23.8873 3.61672H25.5618L26.1167 5.28151L26.3659 6.45832L26.624 5.28151L27.1789 3.61672H28.7582L27.0733 8.00558Z"
        fill="#FF1C24"
      />
      <path
        d="M15.9426 16.9341C15.105 16.9341 14.559 17.408 14.3805 17.9354C14.2525 18.2091 14.2161 18.6093 14.2161 19.3376V24.053C14.2161 24.8177 14.2711 25.2 14.3805 25.4544C14.5798 26.019 15.0901 26.4743 15.9426 26.4743C16.7951 26.4743 17.3084 26.019 17.5048 25.4544C17.632 25.2 17.6684 24.8177 17.6684 24.053V19.3376C17.6684 18.6093 17.6141 18.2091 17.5048 17.9354C17.3262 17.408 16.7802 16.9341 15.9426 16.9341Z"
        fill="#FF1C24"
      />
    </g>
    <defs>
      <clipPath id="clip0_4311_9949">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
