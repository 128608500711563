import { useState } from "react";

type CopiedValue = {
  message: string | null;
  error: string | null;
};
type CopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>({
    message: null,
    error: null,
  });

  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      setCopiedText({
        message: null,
        error: "Copy option not supported!",
      });
      return false;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopiedText({
        message: text,
        error: null,
      });
      return true;
    } catch (error) {
      setCopiedText({
        message: null,
        error: "Coping URL failed!",
      });
      return false;
    }
  };

  return [copiedText, copy];
}
