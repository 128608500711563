import { Button, Dialog, IconButton, Typography } from "@50y/celestial";
import { google, ics } from "calendar-link";
import { cx } from "class-variance-authority";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { AppleIcon } from "src/assets/icons/general/AppleIcon";
import { ArrowDropDownIcon } from "src/assets/icons/general/ArrowDropDown";
import { CheckIcon } from "src/assets/icons/general/CheckIcon";
import { FilledCheckIcon } from "src/assets/icons/general/FilledCheckIcon";
import { GoogleCalendarIcon } from "src/assets/icons/general/GoogleCalendarIcon";
import { PlusCalendarIcon } from "src/assets/icons/general/PlusCalendarIcon";
import { RoundedCheckIcon } from "src/assets/icons/general/RoundedCheckIcon";
import { RoundedQuestionmarkIcon } from "src/assets/icons/general/RoundedQuestionmarkIcon";
import { RoundedXIcon } from "src/assets/icons/general/RoundedXIcon";
import { PointerHorizontalIcon } from "src/assets/icons/ui/PointerHorizontalIcon";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { Event, EventStatus, EventTypes } from "src/models/events-api-response";
import { useEventsMutation } from "src/queries/events/useEventsMutation";
import { useMe } from "src/queries/useMe";
import { row } from "./styles";

const tw = {
  buttonSuccess:
    "!bg-informational-success/20 text-interactive-text-text-b-secondary",
  buttonError:
    "!bg-informational-error/20 text-interactive-text-text-b-secondary fill-informational-error",
  buttonWarning:
    "!bg-interactive-text-text-b-secondary/20 text-interactive-text-text-b-secondary",
  calendarIcon:
    "hover:fill-interactive-text-text-b-primary active:text-inherit flex-none",
  borderTop: "border-t border-elevation-outline-1 first:border-t-0",
  dropdownItem: "px-4 py-2 cursor-pointer",
  fillPrimary: "fill-interactive-text-text-b-primary",
};

enum RSVPStatus {
  YES = "yes",
  NO = "no",
  MAYBE = "maybe",
}

const ArrowDropDownIconRight = () => (
  <ArrowDropDownIcon className="rotate-270 fill-current" />
);

export const EventCTARow = ({
  event,
  className,
  onActionClick,
}: {
  event: Event;
  className?: string;
  onActionClick?: () => void;
}) => {
  const { mutate: handleUpdateEvent } = useEventsMutation();
  const { track } = useAmplitudeTrack();
  const { data: currentUser } = useMe();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const updateEvent = (status: EventStatus, firstResponse?: boolean) => {
    handleUpdateEvent({
      eventId: event.id,
      status,
      firstResponse,
    });
    track(ActionEvents.EVENT_CARD_RSVP_CLICKED, {
      rsvp_status:
        status === EventStatus.ATTENDING
          ? RSVPStatus.YES
          : status === EventStatus.MAYBE_ATTENDING
          ? RSVPStatus.MAYBE
          : RSVPStatus.NO,
      event_id: event.id,
      event_name: event.name,
    });
    onActionClick && onActionClick();
  };

  const getEventDuration = (event: Event) => {
    const start = dayjs(event.start_datetime);
    const end = dayjs(event.end_datetime);
    return end.diff(start, "minute");
  };

  const eventCalendarData = {
    title: event.name,
    description: event.details,
    start: event.start_datetime,
    duration: [getEventDuration(event), "minutes"] as [number, "minutes"],
  };

  const userAttendanceStatus = event.attendees.find(
    (user) => user.user.id === currentUser?.data.id,
  )?.status;

  const userResponseDetails = useMemo(() => {
    return {
      status:
        userAttendanceStatus === EventStatus.ATTENDING
          ? "Going"
          : userAttendanceStatus === EventStatus.MAYBE_ATTENDING
          ? "Maybe"
          : "Not going",
      buttonStyles:
        userAttendanceStatus === EventStatus.ATTENDING
          ? tw.buttonSuccess
          : userAttendanceStatus === EventStatus.MAYBE_ATTENDING
          ? tw.buttonWarning
          : tw.buttonError,
      icon:
        userAttendanceStatus === EventStatus.ATTENDING
          ? () => (
              <FilledCheckIcon className="w-6 h-6 fill-informational-success" />
            )
          : userAttendanceStatus === EventStatus.MAYBE_ATTENDING
          ? () => <RoundedQuestionmarkIcon className="w-6 h-6" />
          : () => <RoundedXIcon className="w-6 h-6 fill-informational-error" />,
    };
  }, [userAttendanceStatus]);
  return (
    <div className={className}>
      {event.event_type === EventTypes.DEFAULT ? (
        event.is_current_user_attendee !== null ? (
          <div className="flex flex-row gap-2-5">
            <Button
              variant="primary"
              className={cx(
                "cursor-default grow",
                userResponseDetails.buttonStyles,
              )}
              size="small"
              icon={userResponseDetails.icon}
            >
              {userResponseDetails.status}
            </Button>
            <Dialog
              dialogButton={
                <IconButton
                  variant="outlined"
                  label="arrow"
                  className={tw.calendarIcon}
                  icon={PointerHorizontalIcon}
                  onPress={() => setIsPopoverOpen(true)}
                />
              }
              popoverProps={{
                className: "!p-0",
                isOpen: isPopoverOpen,
                onOpenChange: () => setIsPopoverOpen(false),
              }}
            >
              <div
                className={cx(tw.dropdownItem, row, tw.borderTop)}
                onClick={(e) => {
                  e.stopPropagation();
                  updateEvent(EventStatus.ATTENDING);
                  setIsPopoverOpen(false);
                }}
              >
                {event.is_current_user_attendee ? (
                  <CheckIcon className={tw.fillPrimary} />
                ) : (
                  <div className="w-6 h-6"></div>
                )}
                <Typography size="label-2" variant="default">
                  Going
                </Typography>
              </div>
              <div
                className={cx(tw.dropdownItem, row, tw.borderTop)}
                onClick={(e) => {
                  e.stopPropagation();
                  updateEvent(EventStatus.NOT_ATTENDING);
                  setIsPopoverOpen(false);
                }}
              >
                {userAttendanceStatus === EventStatus.NOT_ATTENDING ? (
                  <CheckIcon className={tw.fillPrimary} />
                ) : (
                  <div className="w-6 h-6"></div>
                )}
                <Typography size="label-2" variant="default">
                  Not going
                </Typography>
              </div>
              <div
                className={cx(tw.dropdownItem, row, tw.borderTop)}
                onClick={(e) => {
                  e.stopPropagation();
                  updateEvent(EventStatus.MAYBE_ATTENDING);
                  setIsPopoverOpen(false);
                }}
              >
                {userAttendanceStatus === EventStatus.MAYBE_ATTENDING ? (
                  <CheckIcon className={tw.fillPrimary} />
                ) : (
                  <div className="w-6 h-6"></div>
                )}
                <Typography size="label-2" variant="default">
                  Maybe
                </Typography>
              </div>
            </Dialog>
            <Dialog
              dialogButton={
                <IconButton
                  variant="outlined"
                  label="arrow"
                  icon={PlusCalendarIcon}
                  className={tw.calendarIcon}
                />
              }
              popoverProps={{
                className: "!p-0",
              }}
            >
              <div
                className="px-4 py-2 cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              >
                <a
                  href={google(eventCalendarData)}
                  target="_blank"
                  rel="noreferrer"
                  className={row}
                >
                  <GoogleCalendarIcon />
                  <Typography size="label-2" variant="default">
                    Google Calendar
                  </Typography>
                </a>
              </div>
              <div
                className={cx(row, tw.borderTop, "px-4 py-2 cursor-pointer")}
                onClick={(e) => e.stopPropagation()}
              >
                <a
                  href={ics(eventCalendarData)}
                  target="_blank"
                  rel="noreferrer"
                  className={row}
                >
                  <AppleIcon />
                  <Typography size="label-2" variant="default">
                    iCal
                  </Typography>
                </a>
              </div>
            </Dialog>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-2-5">
            <Button
              size="small"
              variant="outlined"
              className="capitalize"
              icon={RoundedCheckIcon}
              onPress={() => updateEvent(EventStatus.ATTENDING, true)}
            >
              {RSVPStatus.YES}
            </Button>
            <Button
              size="small"
              variant="outlined"
              className="capitalize"
              icon={RoundedXIcon}
              onPress={() => updateEvent(EventStatus.NOT_ATTENDING, true)}
            >
              {RSVPStatus.NO}
            </Button>
            <Button
              size="small"
              variant="outlined"
              className="capitalize"
              icon={RoundedQuestionmarkIcon}
              onPress={() => updateEvent(EventStatus.MAYBE_ATTENDING, true)}
            >
              {RSVPStatus.MAYBE}
            </Button>
          </div>
        )
      ) : (
        <a href={event.location_url} target="_blank" rel="noreferrer">
          <Button
            variant="outlined"
            icon={ArrowDropDownIconRight}
            iconPosition="right"
          >
            Go to event
          </Button>
        </a>
      )}
    </div>
  );
};
