import { createContext, FC, PropsWithChildren, useState } from "react";

export type ActiveSectionContextType = {
  activeSection: string;
  setActiveSection: (id: string) => void;
  contentLoaded: boolean;
  setContentLoaded: (contentLoaded: boolean) => void;
};

export const ActiveSectionContext = createContext<ActiveSectionContextType>({
  activeSection: "",
  setActiveSection: () => null,
  setContentLoaded: () => null,
  contentLoaded: false,
});

export const ActiveSectionProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeSection, setActiveSection] = useState<string>("");
  const [contentLoaded, setContentLoaded] = useState<boolean>(false);

  const providerValue = {
    activeSection,
    setActiveSection,
    contentLoaded,
    setContentLoaded,
  };

  return (
    <ActiveSectionContext.Provider value={providerValue}>
      {children}
    </ActiveSectionContext.Provider>
  );
};
