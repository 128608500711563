import { TextArea, TextInput, Typography } from "@50y/celestial";
import { InfoIcon } from "src/assets/icons/general/InfoIcon";
import { PCIcon } from "src/assets/icons/ui/PCIcon";
import CompanyForm from "src/components/CompanyForm";
import { Section } from "src/components/Section";
import { useMe } from "src/queries/useMe";
import { tw } from "./styles";

export const CompanySettingsTab = () => {
  const { data: me } = useMe();

  return (
    <div className="flex flex-col gap-6">
      {me?.data.companies.length === 0 && (
        <div className="flex justify-center items-center h-full">
          <Typography variant="prominent-1" size="title-3">
            You aren&apos;t part of any companies. Reach out to the 50Y team if
            this is incorrect.
          </Typography>
        </div>
      )}
      {me?.data.companies.map((company) => (
        <Section key={company.id}>
          <div className={tw.sectionTitle}>
            <InfoIcon className={tw.sectionIcon} />
            <Typography variant="prominent-1" size="title-6">
              Company Information
            </Typography>
          </div>
          <div className={tw.inputsRow}>
            <CompanyForm company={company} />
          </div>
          <TextInput
            value={company.stage ?? ""}
            variant="filled"
            label="Stage"
            tooltipText="Contact 50y team to update stage information."
            placeholder="Company stage"
            helperText="Your company's current stage"
            disabled
          />
          <TextArea
            value={company.tagline ?? ""}
            variant="filled"
            label="Tagline"
            placeholder="Company tagline"
            tooltipText="Contact 50y team to update tagline information."
            rows={2}
            disabled
          />
          <TextInput
            value={company.organization.website ?? ""}
            variant="filled"
            label="Website"
            placeholder="Company website"
            tooltipText="Contact 50y team to update website information."
            leftContent={<PCIcon className="w-6 h-6" />}
            disabled
          />
        </Section>
      ))}
    </div>
  );
};
