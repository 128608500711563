export const AdminSettingsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M23 7.28571V5.71429H21.3491C21.2478 5.22248 21.0526 4.75484 20.7741 4.33701L21.9448 3.16629L20.8338 2.05529L19.6631 3.22601C19.2452 2.94748 18.7776 2.75219 18.2857 2.65086V1H16.7143V2.65086C16.2225 2.75218 15.7548 2.94744 15.337 3.22593L14.1663 2.05521L13.0553 3.16621L14.226 4.33693C13.9475 4.75478 13.7522 5.22244 13.6509 5.71429H12V7.28571H13.6509C13.7522 7.77752 13.9474 8.24516 14.2259 8.66299L13.0552 9.83371L14.1662 10.9447L15.3369 9.77399C15.7548 10.0525 16.2224 10.2478 16.7143 10.3491V12H18.2857V10.3491C18.7775 10.2478 19.2452 10.0526 19.663 9.77407L20.8337 10.9448L21.9447 9.83379L20.774 8.66307C21.0525 8.24522 21.2478 7.77756 21.3491 7.28571H23ZM17.5 8.85714C17.0338 8.85714 16.5781 8.7189 16.1904 8.45989C15.8028 8.20089 15.5007 7.83275 15.3223 7.40204C15.1439 6.97133 15.0972 6.49739 15.1881 6.04014C15.2791 5.5829 15.5036 5.1629 15.8332 4.83325C16.1629 4.5036 16.5829 4.2791 17.0401 4.18815C17.4974 4.0972 17.9713 4.14388 18.402 4.32228C18.8328 4.50069 19.2009 4.80281 19.4599 5.19044C19.7189 5.57807 19.8571 6.0338 19.8571 6.5C19.8565 7.12494 19.6079 7.72409 19.166 8.16599C18.7241 8.60789 18.1249 8.85646 17.5 8.85714Z" />
    <path d="M12 23H10.4286V19.0714C10.4279 18.4465 10.1793 17.8473 9.73742 17.4054C9.29552 16.9635 8.69637 16.715 8.07143 16.7143H4.92857C4.30363 16.715 3.70448 16.9635 3.26258 17.4054C2.82068 17.8473 2.57211 18.4465 2.57143 19.0714V23H1V19.0714C1.00123 18.0299 1.41552 17.0313 2.15201 16.2949C2.88849 15.5584 3.88703 15.1441 4.92857 15.1429H8.07143C9.11297 15.1441 10.1115 15.5584 10.848 16.2949C11.5845 17.0313 11.9988 18.0299 12 19.0714V23Z" />
    <path d="M6.5 7.28571C6.9662 7.28571 7.42193 7.42396 7.80956 7.68296C8.19719 7.94197 8.49931 8.31011 8.67772 8.74082C8.85612 9.17153 8.9028 9.64547 8.81185 10.1027C8.7209 10.56 8.4964 10.98 8.16675 11.3096C7.8371 11.6393 7.4171 11.8638 6.95986 11.9547C6.50261 12.0457 6.02867 11.999 5.59796 11.8206C5.16725 11.6422 4.79911 11.34 4.54011 10.9524C4.2811 10.5648 4.14286 10.1091 4.14286 9.64286C4.14286 9.0177 4.3912 8.41816 4.83325 7.97611C5.2753 7.53405 5.87485 7.28571 6.5 7.28571ZM6.5 5.71429C5.723 5.71429 4.96345 5.94469 4.3174 6.37637C3.67135 6.80805 3.16782 7.42161 2.87047 8.13946C2.57313 8.85731 2.49533 9.64721 2.64691 10.4093C2.7985 11.1714 3.17266 11.8714 3.72208 12.4208C4.2715 12.9702 4.97151 13.3444 5.73357 13.4959C6.49564 13.6475 7.28555 13.5697 8.0034 13.2724C8.72125 12.975 9.33481 12.4715 9.76649 11.8255C10.1982 11.1794 10.4286 10.4199 10.4286 9.64286C10.4286 9.12695 10.327 8.61609 10.1295 8.13946C9.9321 7.66282 9.64272 7.22974 9.27792 6.86494C8.91312 6.50014 8.48003 6.21076 8.0034 6.01333C7.52676 5.8159 7.01591 5.71429 6.5 5.71429Z" />
  </svg>
);
