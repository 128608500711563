import { useEffect, useState } from "react";

export const useElementSize = (wrapperRef: React.RefObject<Element>) => {
  const [wrapperSize, setWrapperSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      const { width, height } = wrapperRef.current!.getBoundingClientRect();
      setWrapperSize({ width, height });
    });
    if (wrapperRef.current) {
      resizeObserver.observe(wrapperRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [wrapperRef]);

  return { wrapperSize };
};
