export const tw = {
  col: "!basis-[420px] !grow-0",
  lg: "!font-extrabold mb-4",
  select: "!w-[400px] !flex",
  tab: "w-full cursor-pointer",
  activeTab:
    "text-interactive-text-text-b-primary transition-colors duration-200 ease-in-out",
  tabNav:
    "w-cards px-6 py-8 flex flex-col gap-3 border-r border-elevation-outline-0",
  tabContent: "flex-1 p-6 bg-elevation-surface-1 overflow-y-auto",
  sectionTitle:
    "flex items-center gap-4 text-text-default-default-primary fill-text-default-default-primary",
  sectionIcon: "w-6 h-6 fill-interactive-secondary-idle",
  inputsRow: "flex t:flex-row flex-col gap-4",
  infoBox:
    "bg-elevation-background border border-informational-warning-outline p-height-s rounded-sm flex gap-3 items-center",
};
