import { Typography } from "@50y/celestial";
import { ConnectionStrength as ConnectionStrengthType } from "src/models/connections-api-response";
import { ConnectionStrength } from "./ConnectionStrength";

export const ConnectedUser = ({
  connection,
}: {
  connection: ConnectionStrengthType;
}) => (
  <div className="flex flex-row items-center justify-between w-full">
    <Typography
      size="label-2"
      variant="default"
      as="span"
      className="text-interactive-text-text-b-secondary"
    >
      {connection.user.person.name}
    </Typography>
    <ConnectionStrength
      is_confident={!!connection.is_confident}
      strength={connection.connection_strength}
    />
  </div>
);
