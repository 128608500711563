export const RoundedCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="fill-current"
    {...props}
  >
    <path d="M11.3335 16.0605L7.5835 12.3097L8.64325 11.25L11.3335 13.9395L17.0222 8.25L18.0835 9.31125L11.3335 16.0605Z" />
    <path d="M12.8335 1.5C10.7568 1.5 8.72673 2.11581 7.00001 3.26957C5.2733 4.42332 3.92749 6.0632 3.13277 7.98182C2.33805 9.90045 2.13011 12.0116 2.53526 14.0484C2.9404 16.0852 3.94043 17.9562 5.40888 19.4246C6.87733 20.8931 8.74825 21.8931 10.7851 22.2982C12.8219 22.7034 14.9331 22.4955 16.8517 21.7007C18.7703 20.906 20.4102 19.5602 21.5639 17.8335C22.7177 16.1068 23.3335 14.0767 23.3335 12C23.3335 9.21523 22.2273 6.54451 20.2581 4.57538C18.289 2.60625 15.6183 1.5 12.8335 1.5ZM12.8335 21C11.0535 21 9.31341 20.4722 7.83337 19.4832C6.35333 18.4943 5.19977 17.0887 4.51858 15.4442C3.8374 13.7996 3.65917 11.99 4.00643 10.2442C4.3537 8.49836 5.21087 6.89471 6.46954 5.63604C7.72821 4.37737 9.33186 3.5202 11.0777 3.17293C12.8235 2.82567 14.6331 3.0039 16.2777 3.68508C17.9222 4.36627 19.3278 5.51983 20.3167 6.99987C21.3057 8.47991 21.8335 10.22 21.8335 12C21.8335 14.3869 20.8853 16.6761 19.1975 18.364C17.5096 20.0518 15.2204 21 12.8335 21Z" />
  </svg>
);
