import { Screen } from "src/hooks/useInitAmplitude";
import { User } from "src/models/person-api-response";
import { getDefaultEmail } from "src/utils/helpers";

export interface UserProperties {
  user?: number;
  user_email?: string;
  is_founder?: boolean;
  [Screen.SCREEN_RESOLUTION]?: string;
  [Screen.VIEWPORT_WIDTH]?: number;
  [Screen.VIEWPORT_HEIGHT]?: number;
}

export function getUserAmplitudeProperties(me?: User): UserProperties {
  if (!me) {
    return {};
  }
  return {
    user: me?.id,
    user_email: getDefaultEmail(me?.person.emails),
    is_founder: me?.is_founder,
    [Screen.SCREEN_RESOLUTION]: `${window.screen.width}x${window.screen.height}`,
    [Screen.VIEWPORT_WIDTH]: window.innerWidth,
    [Screen.VIEWPORT_HEIGHT]: window.innerHeight,
  };
}
