import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { QUERY_KEYS } from "src/queries/query-keys";
import {
  getImports,
  updateGmailThreadsImport,
} from "src/services/imports.service";

export const useImports = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [QUERY_KEYS.IMPORTS],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getImports(accessToken);
    },
  });
};

export const useUpdateGmailThreadsImport = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError<{ message: string }>
  >({
    mutationFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return updateGmailThreadsImport(accessToken);
    },
    onSuccess: async (result) => {
      toast.success(result.data.message || "Initiated Gmail Import");
    },
    onError: () => {
      toast.error("Error initiating Gmail Import");
    },
  });
};
