import { useLocation, useSearchParams } from "react-router-dom";

export const usePageURLParams = () => {
  const { search } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(search),
  );

  function getSearchParam<T>(key: string): T | null {
    return searchParams.get(key) as T | null;
  }

  return { setSearchParams, getSearchParam };
};
