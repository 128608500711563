import { Divider, Typography } from "@50y/celestial";
import { cva, cx } from "class-variance-authority";
import { useEffect, useState } from "react";
import { Button } from "react-aria-components";
import { NavLink } from "react-router-dom";
import { ArrowRoundedIcon } from "src/assets/icons/general/ArrowRoundedIcon";
import { Logo } from "src/assets/icons/Logo";
import { AddConnectionIcon } from "src/assets/icons/ui/AddConnectionIcon";
import { EventsIcon } from "src/assets/icons/ui/EventsIcon";
import { FounderResourcesIcon } from "src/assets/icons/ui/FounderResourcesIcon";
import { InvestIcon } from "src/assets/icons/ui/InvestorIcon";
import { LinkedInIcon } from "src/assets/icons/ui/LinkedInIcon";
import { SavedListsIcon } from "src/assets/icons/ui/SavedListsIcon";
import { ActionEvents } from "src/config/amplitudeEvents";
import { Routes } from "src/config/routes";
import { useAmplitudeTrack, useWindowSize } from "src/hooks";
import { ScreenBreakpoints } from "src/utils/constants";

const tw = {
  sideNav:
    "bg-white border-r border-elevation-outline-0 max-w-navbar-width overflow-y-auto h-screen flex flex-col justify-between transition-all duration-300 ease-in-out",
  navItem:
    "group p-2 rounded-xs flex gap-3 items-center hover:bg-gray-100 cursor-pointer hover:text-interactive-text-text-b-primary hover:fill-interactive-text-text-b-primary transition-all duration-200 ease-in-out",
  navLinkActive:
    "text-interactive-text-text-b-primary fill-interactive-primary-idle bg-gray-100 rounded-xs w-full",
  collapseIcon: "w-6 h-6",
  navIcon:
    "w-5 h-5 group-data-[collapsed=true]:w-6 group-data-[collapsed=true]:h-6",
  divider: "bg-elevation-outline-0 w-auto h-px mx-3 w-auto",
};

const button = cva(
  "flex items-center bg-elevation-surface-1 p-5 transition-all duration-300 ease-in-out hover:bg-elevation-surface-2 cursor-pointer focus-within:outline-none focus-visible:outline-none",
  {
    variants: {
      variant: {
        collapsed: "flex-col gap-1 px-3 py-2",
        expanded: "flex-row gap-3",
      },
    },
  },
);

const NavItem = ({
  icon,
  text,
  collapsed,
  route,
  onClick,
}: {
  icon: JSX.Element;
  text: string;
  collapsed: boolean;
  route: string;
  onClick?: () => void;
}) => (
  <NavLink
    to={route}
    className={({ isActive }) => (isActive ? `${tw.navLinkActive}` : "w-full")}
    onClick={onClick}
  >
    <div
      data-collapsed={collapsed}
      className={cx(tw.navItem, collapsed ? "flex-col" : "flex-row")}
    >
      {icon}
      <Typography
        size={collapsed ? "body-5" : "label-2"}
        variant={collapsed ? "prominent-2" : "default"}
        className={cx(
          collapsed ? "prominent-2 text-center" : "default",
          "truncate w-full flex-1",
        )}
      >
        {text}
      </Typography>
    </div>
  </NavLink>
);

export const SideNav = () => {
  const { track } = useAmplitudeTrack();
  const [collapsedNav, setCollapsedNav] = useState(false);

  const {
    windowSize: { width },
  } = useWindowSize();

  useEffect(() => {
    if (width <= ScreenBreakpoints.DESKTOP) {
      setCollapsedNav(true);
    }
  }, [width]);

  return (
    <div className={cx(tw.sideNav, collapsedNav ? "w-24" : "w-56")}>
      <div className="flex flex-col">
        <div className={cx("pl-5 pt-4 pb-4 pr-4", collapsedNav && "m-auto")}>
          <Logo />
        </div>
        <div className="px-3 py-2 flex gap-3 flex-col">
          <NavItem
            text="All Connections"
            icon={<LinkedInIcon className={tw.navIcon} />}
            collapsed={collapsedNav}
            route={Routes.CONNECTIONS}
          />
          <NavItem
            text="Investors"
            icon={<InvestIcon className={tw.navIcon} />}
            collapsed={collapsedNav}
            route={Routes.INVESTORS}
          />
          <NavItem
            text="Saved Lists"
            icon={<SavedListsIcon className={tw.navIcon} />}
            collapsed={collapsedNav}
            route={Routes.LISTS}
          />
        </div>
        <Divider className={tw.divider} />
        <div className="px-3 py-2 flex gap-3 flex-col">
          <NavItem
            text="Events"
            icon={<EventsIcon className={tw.navIcon} />}
            collapsed={collapsedNav}
            route={Routes.EVENTS}
          />
          <NavItem
            text="Founder Resources"
            icon={<FounderResourcesIcon className={tw.navIcon} />}
            collapsed={collapsedNav}
            route={Routes.RESOURCES}
            onClick={() =>
              track(ActionEvents.CLICKED_RESOURCES_NAV, {
                page: Routes.RESOURCES,
              })
            }
          />
          <NavItem
            text="Add Connections"
            icon={<AddConnectionIcon className={tw.navIcon} />}
            collapsed={collapsedNav}
            route={Routes.ADD_CONNECTIONS}
          />
        </div>
      </div>
      <Button
        onPress={() => setCollapsedNav((collapsedNav) => !collapsedNav)}
        className={button({ variant: collapsedNav ? "collapsed" : "expanded" })}
      >
        <ArrowRoundedIcon
          className={cx(tw.collapseIcon, collapsedNav && "rotate-180")}
        />
        <Typography size="label-2" variant="default">
          {collapsedNav ? "Expand" : "Collapse"}
        </Typography>
      </Button>
    </div>
  );
};
