export const HighStrengthIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M5.68421 6.31579L11.6586 0.341421C11.7846 0.215428 12 0.304662 12 0.482843V11.8C12 11.9105 11.9105 12 11.8 12H0.482843C0.304662 12 0.215428 11.7846 0.341421 11.6586L5.68421 6.31579Z"
      fill="#FF1C24"
    />
  </svg>
);

export const MediumStrengthIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.68421 6.31579L11.6586 0.341421C11.7846 0.215428 12 0.304662 12 0.482843V11.8C12 11.9105 11.9105 12 11.8 12H0.482843C0.304662 12 0.215428 11.7846 0.341421 11.6586L5.68421 6.31579Z"
      fill="white"
    />
    <mask
      id="mask0_4106_38919"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="12"
      height="12"
    >
      <path
        d="M5.68421 6.31579L11.6586 0.341421C11.7846 0.215428 12 0.304662 12 0.482843V11.8C12 11.9105 11.9105 12 11.8 12H0.482843C0.304662 12 0.215428 11.7846 0.341421 11.6586L5.68421 6.31579Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4106_38919)">
      <path d="M3.78947 8.21053L8 4V12H0L3.78947 8.21053Z" fill="#FF1C24" />
    </g>
  </svg>
);

export const LowStrengthIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.68421 6.31579L11.6586 0.341421C11.7846 0.215428 12 0.304662 12 0.482843V11.8C12 11.9105 11.9105 12 11.8 12H0.482843C0.304662 12 0.215428 11.7846 0.341421 11.6586L5.68421 6.31579Z"
      fill="white"
    />
    <mask
      id="mask0_4106_38918"
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="12"
      height="12"
    >
      <path
        d="M5.68421 6.31579L11.6586 0.341421C11.7846 0.215428 12 0.304662 12 0.482843V11.8C12 11.9105 11.9105 12 11.8 12H0.482843C0.304662 12 0.215428 11.7846 0.341421 11.6586L5.68421 6.31579Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_4106_38918)">
      <path d="M1.42105 10.5789L3 9V12H0L1.42105 10.5789Z" fill="#FF1C24" />
    </g>
  </svg>
);
