import { Button, Dialog, PointerHorizontal, Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useState } from "react";
import { CheckIcon } from "src/assets/icons/general/CheckIcon";

const tw = {
  list: "cursor-pointer px-4 py-2 hover:bg-interactive-text-text-hover flex gap-2 items-center border-t border-elevation-outline-1 first:border-t-0",
  button:
    "justify-between px-3 hover:!border-interactive-outlined-idle active:!border-interactive-outlined-idle hover:text-text-default-text-default-tertiary active:text-text-default-text-default-tertiary fill-interactive-icon-idle",
  buttonDisabled:
    "!text-interactive-text-text-disabled hover:!text-interactive-text-text-disabled active:!text-interactive-text-text-disabled",
  buttonSelected:
    "text-text-default-text-default-tertiary hover:!text-text-default-text-default-tertiary active:!text-text-default-text-default-tertiary",
};

interface IListStatusDropdownProps {
  selectedStatus?: number;
  personId: number;
  onChange: (status: number) => void;
}

export enum ListStatusEnum {
  Blank,
  "In Draft",
  Requested,
  Made,
  Declined,
}
const ListStatusValues: number[] = Object.values(ListStatusEnum).filter(
  (val) => typeof val === "number",
) as number[];

const ListStatusDropdown = (props: IListStatusDropdownProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleChange = (statusIndex: number) => {
    props.onChange(statusIndex);
    setIsPopoverOpen(false);
  };

  const hasSelectedStatus = props.selectedStatus !== undefined;

  return (
    <Dialog
      dialogButton={
        <Button
          variant="outlined"
          icon={PointerHorizontal}
          iconPosition="right"
          className={cx(
            tw.button,
            hasSelectedStatus ? tw.buttonSelected : tw.buttonDisabled,
          )}
          textProps={{ size: "label-2", variant: "default" }}
          onPress={() => setIsPopoverOpen(true)}
        >
          {!hasSelectedStatus
            ? "Select status"
            : ListStatusEnum[props.selectedStatus ?? 0]}
        </Button>
      }
      popoverProps={{
        className: "py-0 px-0",
        isOpen: isPopoverOpen,
        onOpenChange: () => setIsPopoverOpen(false),
        placement: "bottom start",
      }}
    >
      {ListStatusValues.map((statusIndex) => (
        <div
          key={statusIndex}
          onClick={() => handleChange(statusIndex)}
          className={tw.list}
        >
          {props.selectedStatus === statusIndex ? (
            <CheckIcon className="fill-interactive-text-text-b-primary" />
          ) : (
            <div className="w-6 h-6" />
          )}
          <Typography
            variant="default"
            size="label-2"
            className="text-interactive-text-text-b-secondary"
          >
            {ListStatusEnum[statusIndex]}
          </Typography>
        </div>
      ))}
    </Dialog>
  );
};

export default ListStatusDropdown;
