import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { updateFuxStep } from "src/services/onboarding.service";

type MutationVariables = {
  step: number;
};

export const useSetFuxStepMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  const mutationFn = async ({ step }: MutationVariables) => {
    const accessToken = await getAccessTokenSilently();
    return updateFuxStep(accessToken, step);
  };

  return useMutation({ mutationFn });
};
