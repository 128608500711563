import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getMe } from "src/services/person.service";

export const useMe = (isDisabled = false) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryKey: [QUERY_KEYS.CURENT_USER],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getMe(accessToken);
    },
    staleTime: Infinity,
    enabled: !isDisabled,
  });
};
