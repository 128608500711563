import { track } from "@amplitude/analytics-browser";
import { AxiosResponse } from "axios";
import { HAS_BLOCKER_ACTIVE } from "src/hooks/useInitAmplitude";
import { User } from "src/models/person-api-response";
import { queryClient } from "src/providers/queryClient";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getUserAmplitudeProperties } from "src/utils/getUserAmplitudeProperties";
import { sendAmplitudeEvents } from "./global.service";

export const trackingService = (
  accessToken: string,
  eventName: string,
  eventProperties?: any,
) => {
  const hasBlockerActive = localStorage.getItem(HAS_BLOCKER_ACTIVE);
  if (hasBlockerActive) {
    if (!accessToken) return;
    const user = queryClient.getQueryData([
      QUERY_KEYS.CURENT_USER,
    ]) as AxiosResponse<User>;

    const events = [
      {
        event_type: eventName,
        event_properties: eventProperties,
        user_properties: getUserAmplitudeProperties(user.data),
      },
    ];
    return sendAmplitudeEvents({ accessToken, events });
  }
  return track(eventName, eventProperties);
};
