import { Person, User } from "./person-api-response";

export enum EventStatus {
  ATTENDING = "attending",
  NOT_ATTENDING = "not-attending",
  MAYBE_ATTENDING = "maybe",
}

export interface Attendee {
  id: number;
  status: EventStatus;
  user: EventUser;
  invited_by?: Person;
}

export type EventUser = Omit<
  User,
  | "is_founder"
  | "is_staff"
  | "companies"
  | "affinity_entity_id"
  | "affinity_row_id"
  | "created_at"
  | "updated_at"
  | "fux"
>;

export enum EventTypes {
  DEFAULT = "default",
  EXTERNAL = "external",
}

export interface Event {
  id: number;
  name: string;
  is_current_user_attendee: boolean | null;
  start_datetime: string;
  end_datetime: string;
  details: string;
  photo: string | null;
  location: string;
  location_url: string;
  attendees: Attendee[];

  event_type: EventTypes;
}
