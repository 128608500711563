import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { Routes } from "src/config/routes";
import { useTrackPageVisited } from "src/hooks";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();
  useTrackPageVisited(NavigationEvents.LOGIN_PAGE_VIEWED);
  useEffect(() => {
    void loginWithRedirect({
      appState: {
        returnTo:
          window.location.pathname !== Routes.LOGIN
            ? window.location.pathname
            : Routes.CONNECTIONS,
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  }, [loginWithRedirect]);

  return <></>;
};

export default LoginPage;
