import { Avatar, TextInput, Typography } from "@50y/celestial";
import { FilledCheckIcon } from "src/assets/icons/general/FilledCheckIcon";
import { InfoIcon } from "src/assets/icons/general/InfoIcon";
import { SocialsIcon } from "src/assets/icons/general/SocialsIcon";
import { LinkedInIcon } from "src/assets/icons/ui/LinkedInIcon";
import { PCIcon } from "src/assets/icons/ui/PCIcon";
import { UserFilledIcon } from "src/assets/icons/ui/UserFilledIcon";
import { XIcon } from "src/assets/icons/ui/XIcon";
import { Section } from "src/components/Section";
import { useMe } from "src/queries/useMe";
import { getDefaultEmail, getPersonAddress } from "src/utils/helpers";
import { tw } from "./styles";

const ErrorBox = () => (
  <div className={tw.infoBox}>
    <FilledCheckIcon className="w-6 h-6 fill-informational-warning" />
    <Typography variant="default" size="label-2">
      <a href="mailto:drew@50y.com" className="underline">
        Contact Fifty Years to edit this info
      </a>
    </Typography>
  </div>
);

export const ProfileSettingsTab = () => {
  const { data: me } = useMe();
  return (
    <div className="grid gap-6">
      <Section>
        <div className={tw.sectionTitle}>
          <UserFilledIcon className={tw.sectionIcon} />
          <Typography variant="prominent-1" size="title-6">
            Profile picture
          </Typography>
        </div>
        <Avatar
          src={me?.data.person.profile_picture_url ?? ""}
          size="extraLarge"
        />
      </Section>
      <Section>
        <div className={tw.sectionTitle}>
          <InfoIcon className={tw.sectionIcon} />
          <Typography variant="prominent-1" size="title-6">
            Personal information
          </Typography>
        </div>
        <ErrorBox />
        <div className={tw.inputsRow}>
          <TextInput
            value={me?.data.person.first_name ?? ""}
            variant="filled"
            label="First name"
            disabled
          />
          <TextInput
            value={me?.data.person.last_name ?? ""}
            variant="filled"
            label="Last name"
            disabled
          />
        </div>
        <TextInput
          value={getDefaultEmail(me?.data.person.emails) ?? ""}
          variant="filled"
          label="E-mail"
          disabled
        />
        <TextInput
          value={getPersonAddress(me?.data.person) ?? ""}
          variant="filled"
          label="Location"
          disabled
        />
        {/* hidden for now until we create this field
         <TextArea
          value={me?.data.person.summary}
          disabled
          rows={8}
          variant="filled"
          label="Bio"
        /> */}
      </Section>
      <Section>
        <div className={tw.sectionTitle}>
          <SocialsIcon className={tw.sectionIcon} />
          <Typography variant="prominent-1" size="title-6">
            Social profiles
          </Typography>
        </div>
        <ErrorBox />
        <TextInput
          value={me?.data.person.twitter_url ?? ""}
          variant="filled"
          label="Twitter"
          leftContent={<XIcon className="w-6 h-6" />}
          disabled
        />
        <TextInput
          value={me?.data.person.linkedin_url ?? ""}
          variant="filled"
          label="LinkedIn"
          leftContent={<LinkedInIcon />}
          disabled
        />
        <TextInput
          value={me?.data.person.current_organization?.website ?? ""}
          variant="filled"
          label="Website"
          leftContent={<PCIcon className="w-6 h-6" />}
          disabled
        />
      </Section>
    </div>
  );
};
