import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { FilterReturnType } from "src/components/Filters/Filters";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { PaginatedApiResponse } from "src/models/api-response";
import { LinkedinConnection, Page } from "src/models/connections-api-response";
import { Investor } from "src/models/investors-api-response";
import { queryClient } from "src/providers/queryClient";
import { addContactToList } from "src/services/lists.service";

export type ListContactVariables = {
  currentUserId: number;
  listId: number;
  personId: number;
  itemId: number;
  page: Page;
  listName?: string;
  queryParams?: Record<string, string | number | FilterReturnType>;
};

export const useAddContactToListMutation = (queryKey: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation({
    mutationFn: async (variables: ListContactVariables) => {
      const accessToken = await getAccessTokenSilently();
      return addContactToList({
        accessToken,
        ...variables,
      });
    },
    onSuccess: (data, { listId, listName, page, queryParams, itemId }) => {
      track(ActionEvents.LIST_PERSON_ADDED, {
        list_name: listName,
        page: page,
      });
      toast.success(data.data.message || "Person successfully added to list");

      queryClient.setQueryData(
        [queryKey, { ...queryParams }],
        (
          oldData: AxiosResponse<
            PaginatedApiResponse<LinkedinConnection | Investor>
          >,
        ) => {
          const updatedData = oldData.data.results.map((person) => {
            if (person.id === itemId) {
              return {
                ...person,
                saved_list_ids: [...person.saved_list_ids, Number(listId)],
              };
            }
            return person;
          });
          return {
            ...oldData,
            data: {
              ...oldData.data,
              results: updatedData,
            },
          };
        },
      );
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 409) {
        toast.error("Person is already in list");
        return;
      }
      toast.error("Failed to add person to the list");
    },
  });
};
