export interface Import {
  id: number;
  user: number;
  created_connections_count: number;
  updated_connections_count: number;
  updated_at: string;
  created_at: string;
}

export interface GoogleConnectionsImportHistory {
  imported_connections_count: number;
  matched_connections_count: number;
  created_at: string;
}

export interface ImportGoogleContactsResult {
  id: number;
  existing_connections: number;
  new_connections: number;
  total_connections: number;
}

export interface ImportGoogleEventsResult {
  id: number;
  existing_connections: number;
  new_connections: number;
  total_connections: number;
}

export interface ImportGmailThreadsResult {
  id: number;
  existing_connections: number;
  new_connections: number;
  total_connections: number;
}

export enum Entities {
  COMPANIES = "companies",
  USERS = "users",
  FUNDS = "funds",
  INVESTORS = "investors",
}
