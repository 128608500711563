import { Typography } from "@50y/celestial";
import { AdminSettingsIcon } from "src/assets/icons/ui/AdminSettingsIcon";
import SyncFromAirtableButton from "src/components/buttons/SyncFromAirtableButton";
import { Section } from "src/components/Section";
import { tw } from "./styles";

export const AdminSettingsTab = () => {
  return (
    <Section>
      <div className={tw.sectionTitle}>
        <AdminSettingsIcon className={tw.sectionIcon} />
        <Typography variant="prominent-1" size="title-6">
          Staff Admin
        </Typography>
      </div>
      <div className="flex flex-col gap-4">
        <Typography variant="default" size="body-4">
          Sync database with Airtable.
        </Typography>
        <SyncFromAirtableButton />
      </div>
    </Section>
  );
};
