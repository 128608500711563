import { Divider, Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { CalendarIcon } from "src/assets/icons/general/CalendarIcon";
import { Event } from "src/models/events-api-response";
import { getEventDateAndTime } from "src/utils/helpers";
import { EventCTARow } from "./EventCTARow";
import { row } from "./styles";
import { useInvitedBy } from "./useInvitedBy";

const tw = {
  eventCardWrapper:
    "w-full bg-white rounded-s shadow-base p-6 flex flex-col cursor-pointer",
  eventCardTime:
    "text-text-default-text-default-tertiary fill-text-default-text-default-tertiary",
  buttonSuccess:
    "!bg-informational-success/20 text-interactive-text-text-b-secondary",
  buttonError:
    "!bg-informational-error/20 text-interactive-text-text-b-secondary fill-informational-error",
  buttonWarning:
    "!bg-interactive-text-text-b-secondary/20 text-interactive-text-text-b-secondary",
};

type EventCardProps = {
  event: Event;
  onEventSelect: () => void;
  currentUserId: number;
};

const EventCard = ({ event, onEventSelect, currentUserId }: EventCardProps) => {
  const { invitedBy } = useInvitedBy({
    attendees: event.attendees,
    currentUserId,
  });

  return (
    <div className={tw.eventCardWrapper} onClick={onEventSelect}>
      <Typography
        size="label-3"
        variant="default"
        className={cx(tw.eventCardTime, row)}
      >
        <CalendarIcon className="w-5 h-5" />
        {getEventDateAndTime(event.start_datetime)}
      </Typography>
      <Typography size="title-5" variant="prominent-1" className="my-2">
        {event.name}
      </Typography>
      <Typography size="body-4" variant="default" className="line-clamp-3">
        {event.details}
      </Typography>
      <Divider className="my-3" />
      <Typography size="label-3" variant="prominent-1" className="mb-1">
        Location
      </Typography>
      <a
        href={event.location_url}
        target="_blank"
        rel="noreferrer"
        onClick={(e) => e.stopPropagation()}
      >
        <Typography
          size="body-4"
          variant="prominent-2"
          className={cx(
            "text-interactive-text-text-b-primary",
            event.location_url ? "cursor-pointer" : "cursor-default",
          )}
        >
          {event.location}
        </Typography>
      </a>
      {invitedBy && (
        <>
          <Divider className="my-3" />
          <Typography size="label-3" variant="prominent-1" className="mb-1">
            Invited by
          </Typography>
          <Typography size="body-4" variant="prominent-2">
            {invitedBy.name}
          </Typography>
        </>
      )}
      <EventCTARow event={event} className="mt-4" />
    </div>
  );
};

export default EventCard;
