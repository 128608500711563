import { cx } from "class-variance-authority";
import { FC, PropsWithChildren } from "react";

export const Section: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <section
    className={cx(
      "bg-elevation-background p-6 flex flex-col gap-8 rounded-s shadow-base max-w-3xl",
      className,
    )}
  >
    {children}
  </section>
);
