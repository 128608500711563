import { PropsWithChildren } from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { uiOutlineClass } from "src/styles/tailwind";

type LinkProps = PropsWithChildren<RouterLinkProps> & {
  className?: string;
};

export const Link = (props: LinkProps) => {
  return (
    <RouterLink
      {...props}
      className={`${uiOutlineClass} text-interactive-primary-idle underline outline-interactive-primary-focus hover:text-interactive-primary-hover focus:text-interactive-primary-focus active:text-interactive-primary-pressed ${props.className}`}
    />
  );
};
