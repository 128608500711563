import { Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { ActiveSectionContext } from "./context";

export interface ITableOfContents {
  title: string;
  id: string;
  item?: string;
}

const twStyles = {
  tableOfContent:
    "border-l-[1px] py-8 px-6 border-[#E5E5E5] w-toc overflow-y-auto h-vh",
  styledItemClass:
    "cursor-pointer font-bold p-3 border-l-4 border-[transparent] hover:border-interactive-text-text-b-primary hover:text-interactive-text-text-b-primary",
};

export const TableOfContents = ({
  headingIds = [],
}: {
  headingIds: ITableOfContents[] | undefined;
}) => {
  const { track } = useAmplitudeTrack();
  const { activeSection } = useContext(ActiveSectionContext);
  const { pathname } = useLocation();

  return (
    <div className={twStyles.tableOfContent}>
      <Typography variant="prominent-1" size="label-3">
        Table of contents
      </Typography>
      <div className="grid gap-3 mt-6">
        {headingIds?.map(({ id, title }) => (
          <div
            key={id}
            onClick={() => {
              const selectedElement = document.getElementById(id);
              selectedElement?.scrollIntoView({ block: "start" });
              track(ActionEvents.CLICKED_RESOURCE_PAGE_HEADING, {
                page: pathname,
                heading: title,
              });
            }}
          >
            <Typography
              size="body-4"
              variant="default"
              className={cx(
                activeSection === id &&
                  "text-interactive-text-text-b-primary font-headings-prominent-1",
                "cursor-pointer",
              )}
            >
              {title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
