import { Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { FilledCheckIcon } from "src/assets/icons/general/FilledCheckIcon";
import { RoundedXIcon } from "src/assets/icons/general/RoundedXIcon";
import { Event } from "src/models/events-api-response";
import { getEventDateAndTime } from "src/utils/helpers";
import { row } from "./styles";

const tw = {
  textSuccess: "text-informational-success fill-informational-success",
  textDefault:
    "text-text-default-text-default-tertiary fill-text-default-text-default-tertiary",
};

export const EventCardAttendance = ({
  event,
  className,
}: {
  event: Event;
  className?: string;
}) => (
  <Typography
    size="label-3"
    variant="prominent-1"
    className={cx(
      className,
      event.is_current_user_attendee ? tw.textSuccess : tw.textDefault,
      row,
    )}
  >
    {event.is_current_user_attendee ? <FilledCheckIcon /> : <RoundedXIcon />}
    {event.is_current_user_attendee
      ? `Attended ${getEventDateAndTime(event.start_datetime)}`
      : "Did not attend"}
  </Typography>
);
