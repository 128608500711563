import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getPersons } from "src/services/person.service";

export const usePersons = (page: number, search: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery({
    queryKey: [QUERY_KEYS.PERSONS, { page, search }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getPersons(accessToken, page, search);
    },
  });
};
