import {
  Button,
  Dropdown,
  IconButton,
  Modal,
  Typography,
} from "@50y/celestial";
import { useEffect, useMemo, useState } from "react";
import { CloseIcon } from "src/assets/icons/general/CloseIcon";
import { ConnectionStrength } from "src/models/connections-api-response";
import { Person, User } from "src/models/person-api-response";
import {
  RequestIntroMutationProps,
  useIntroRequestMutation,
} from "src/queries/useIntroRequestMutation";
import { useMe } from "src/queries/useMe";
import { openDraft } from "src/utils/email";
import {
  getCompanyName,
  getDefaultEmail,
  getDisplayName,
  getTitle,
  personIsInvestor,
  personIsUser,
} from "src/utils/helpers";
import { ConnectedUser } from "./ConnectedUser";
import { ModalType } from "./RequestIntroModal";
import { PersonPopover } from "./table/PersonPopover";

interface IProps {
  selectedPeople: Person[];
  onRemovePeople: (people: Person[]) => void;
  onClose: () => void;
}

interface SelectedConnections {
  [personId: number]: ConnectionStrength | null;
}

const BatchIntroRequestModal = ({
  selectedPeople,
  onClose,
  onRemovePeople,
}: IProps) => {
  const { data: currentUser } = useMe();
  const { mutate: createIntroRequest } = useIntroRequestMutation();

  const saveIntroRequest = (
    targetConnection: Person,
    introPath: User | null,
    subject: string,
    body: string,
  ) => {
    const props: RequestIntroMutationProps = {
      type: personIsInvestor(targetConnection)
        ? ModalType.INVESTOR
        : personIsUser(targetConnection)
        ? ModalType.USER
        : ModalType.CONNECTION,
      user_id: currentUser?.data?.id || 0,
      person_id: targetConnection.id,
      connection_id: introPath?.id || 0,
      subject,
      email_content: body,
    };
    createIntroRequest({
      ...props,
    });
  };

  const [introsRequested, setIntrosRequested] = useState<number>(0);

  const [selectedConnections, setSelectedConnections] =
    useState<SelectedConnections>(
      selectedPeople.reduce((acc: SelectedConnections, person: Person) => {
        acc[person.id] = person.connection_strengths[0] || null;
        return acc;
      }, {}),
    );

  const peopleById: Map<number, Person> = useMemo(() => {
    return selectedPeople.reduce((acc, person) => {
      acc.set(person.id, person);
      return acc;
    }, new Map<number, Person>());
  }, [selectedPeople]);

  const usersById = useMemo(() => {
    const usersById = new Map<number, User>();
    for (const connectionStrength of Object.values(selectedConnections)) {
      if (connectionStrength?.user) {
        usersById.set(connectionStrength.user.id, connectionStrength.user);
      }
    }
    return usersById;
  }, [selectedConnections]);

  const introPaths = useMemo<Map<string, Person[]>>(() => {
    const introPaths = new Map<string, Person[]>();
    for (const [personId, connectionStrength] of Object.entries(
      selectedConnections,
    )) {
      const key = connectionStrength?.user.id
        ? String(connectionStrength.user.id)
        : "-1";
      introPaths.set(key, [
        ...(introPaths.get(key) ?? []),
        selectedPeople.find(
          (person) => person.id === Number(personId),
        ) as Person,
      ]);
    }
    return introPaths;
  }, [selectedConnections, selectedPeople]);

  const [selectedIntroPathId, setSelectedIntroPathId] = useState<string>(
    [...introPaths.keys()].sort(
      (a, b) =>
        (introPaths.get(a)?.length ?? -1) - (introPaths.get(b)?.length ?? -1),
    )[0],
  );

  useEffect(() => {
    if (!selectedIntroPathId) {
      return;
    }
    if (!introPaths.has(selectedIntroPathId)) {
      const nextIntroPathId = introPaths.keys().next().value;
      setSelectedIntroPathId(nextIntroPathId);
    }
  }, [usersById, introPaths, selectedIntroPathId]);

  const introPathOptions = [...introPaths.keys()]
    .sort(
      (a, b) =>
        (introPaths.get(a)?.length ?? -1) - (introPaths.get(b)?.length ?? -1),
    )
    .map((key) => ({
      id: key ?? "-1",
      label:
        key === "-1" ? "Universe" : usersById.get(Number(key))!.person.name,
    }));

  const createBatchIntroRequest = () => {
    const currentUserCompany = currentUser!.data.companies[0];
    const introPathUser =
      selectedIntroPathId === "-1"
        ? null
        : usersById.get(Number(selectedIntroPathId));

    const targets: Person[] = [];
    const peopleIds = [];
    const connectionEmail = introPathUser
      ? getDefaultEmail(introPathUser!.person.emails)
      : "universe@50y.com";

    for (const [personId, connectionStrength] of Object.entries(
      selectedConnections,
    )) {
      if (
        (connectionStrength ? String(connectionStrength.user.id) : "-1") ===
        selectedIntroPathId
      ) {
        const person = peopleById.get(Number(personId)) as Person;
        targets.push(peopleById.get(Number(personId)) as Person);
        peopleIds.push(person.id);
      }
    }

    setIntrosRequested(introsRequested + targets.length);
    const updatedSelectedConnections: SelectedConnections = {};
    for (const [personId] of Object.entries(selectedConnections)) {
      if (!peopleIds.includes(Number(personId))) {
        updatedSelectedConnections[Number(personId)] =
          selectedConnections[Number(personId)];
      }
    }
    setSelectedConnections(updatedSelectedConnections);
    onRemovePeople(targets);
    const subject = "✨ Request for intros";

    const targetStrings = targets.map(
      (person) =>
        `- ${getDisplayName(person)}${
          personIsInvestor(person)
            ? ` @ ${getCompanyName(person.investor?.firm_affiliation[0])}`
            : ""
        }${person.linkedin_url ? ` - ${person.linkedin_url}` : ""}`,
    );
    const body = introPathUser
      ? `Hi ${
          introPathUser.person.nickname || introPathUser.person.first_name
        }, 

  I noticed in Universe that you're connected to people I'd like to meet:
  ${targetStrings.join("\n")}

  Do you any of them well enough to intro?

  Here's a forwardable blurb about what we do at ${getCompanyName(
    currentUserCompany,
  )}:

  ${
    currentUserCompany?.blurb
      ? currentUserCompany?.blurb
      : "<< " + getCompanyName(currentUserCompany) + " Blurb >>"
  }

  Also happy to hop on a 5m call to paint any more color about what we're looking for!

  Best,
  ${currentUser?.data?.person.nickname ?? currentUser?.data?.person.name}`
      : `Dear Universe,

  Could you please help me reach the following people:
  ${targetStrings.join("\n")}

  Can the magical Fifty Years elves help me out?

  Best,
  ${currentUser?.data?.person.nickname ?? currentUser?.data?.person.name}`;

    for (const target of targets) {
      saveIntroRequest(target, introPathUser ?? null, subject, body);
    }

    openDraft({
      to: connectionEmail,
      subject,
      body,
      client: "mailto",
    });
  };

  const renderedPeople = selectedPeople.map((person) => {
    const connectionItems = person.connection_strengths.map((connection) => ({
      label: connection.user.person.name,
      id: connection.user.id,
      inputElement: <ConnectedUser connection={connection} />,
    }));
    const selectedConnection = selectedConnections[person.id] ?? null;
    const hasConnections = person.connection_strengths.length > 0;
    const removePerson = () => {
      const updatedSelectedConnections: SelectedConnections = {};
      for (const [key, val] of Object.entries(selectedConnections)) {
        if (Number(key) !== person.id) {
          updatedSelectedConnections[Number(key)] = val;
        }
      }
      setSelectedConnections(updatedSelectedConnections);
      onRemovePeople([person]);
    };
    return (
      <div className="grid grid-cols-12 gap-4 items-center" key={person.id}>
        <div className="col-span-5">
          <PersonPopover
            person={person}
            subtext={getTitle(person)}
          ></PersonPopover>
        </div>
        <div className="col-span-5">
          {hasConnections ? (
            <Dropdown
              closeOnItemSelect={true}
              onChange={(selectedItemIds) => {
                setSelectedConnections({
                  ...selectedConnections,
                  [person.id]:
                    person.connection_strengths.find(
                      (person) => person.user.id === selectedItemIds[0],
                    ) || null,
                });
              }}
              selectionMode="single"
              label="Intro path"
              placeholder="Select intro path"
              items={connectionItems}
              selectedItemIds={
                selectedConnection?.user.id ? [selectedConnection.user.id] : []
              }
            ></Dropdown>
          ) : (
            "No connections"
          )}
        </div>
        <IconButton
          className="col-span-2"
          label="Remove"
          icon={CloseIcon}
          onPress={() => {
            removePerson();
          }}
        ></IconButton>
      </div>
    );
  });

  return (
    <Modal isOpen={true} onClose={onClose}>
      <div className="mb-8">
        <Typography variant="prominent-1" size="title-4">
          Select intro paths
        </Typography>
      </div>
      {introsRequested > 0 && (
        <div className="mb-8">{introsRequested} Intros Requested!</div>
      )}
      {!!renderedPeople.length && (
        <>
          <div className="grid gap-4 mb-8">{renderedPeople}</div>
          <div className="grid grid-cols-3 gap-4 items-end">
            <Button
              isDisabled={selectedPeople.length === 0}
              onPress={() => createBatchIntroRequest()}
              variant="primary"
            >
              {selectedIntroPathId &&
              introPaths.get(selectedIntroPathId)?.length
                ? `Request ${
                    introPaths.get(selectedIntroPathId)!.length
                  } intros`
                : "Request intros"}
            </Button>
            <Dropdown
              closeOnItemSelect={true}
              onChange={(selectedItemIds) => {
                setSelectedIntroPathId(
                  selectedItemIds.length && selectedItemIds[0]
                    ? String(selectedItemIds[0])
                    : "-1",
                );
              }}
              selectionMode="single"
              label="From"
              placeholder="Select an intro path"
              items={introPathOptions.filter((item) => item.id)}
              selectedItemIds={selectedIntroPathId ? [selectedIntroPathId] : []}
            ></Dropdown>
            <Button onPress={onClose} variant="outlined">
              Cancel
            </Button>
          </div>
        </>
      )}
      {renderedPeople.length === 0 && (
        <Button onPress={onClose} variant="primary">
          Done
        </Button>
      )}
    </Modal>
  );
};

export default BatchIntroRequestModal;
