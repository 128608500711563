import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { PaginatedApiResponse } from "src/models/api-response";
import { Investor, InvestorRole } from "src/models/investors-api-response";
import { API_SERVER_URL, getHeaders } from "./global.service";

export const DEFAULT_INVESTOR_PAGE_SIZE = 20;
export const DEFAULT_INVESTOR_ROLES_PAGE_SIZE = 200;

export const getInvestors = async ({
  accessToken,
  page,
  search,
  sort,
  investorIds,
  fundIds,
  connectionIds,
}: {
  accessToken: string;
  page: number;
  search: string;
  sort?: string;
  investorIds?: number[];
  fundIds?: number[];
  connectionIds?: number[];
}): Promise<AxiosResponse<PaginatedApiResponse<Investor>>> => {
  const params = {
    limit: DEFAULT_INVESTOR_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_INVESTOR_PAGE_SIZE,
    search,
    ordering: sort,
    investorIds: investorIds?.join(","),
    fundIds: fundIds?.join(","),
    connectionIds: connectionIds?.join(","),
  };
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/investors`,
    method: "GET",
    params,
    paramsSerializer: {
      indexes: true,
    },
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getInvestor = async (
  accessToken: string,
  id?: string,
): Promise<AxiosResponse<Investor>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/investors/${id}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getInvestorRoles = async ({
  accessToken,
  page,
  fundIds,
}: {
  accessToken: string;
  page: number;
  fundIds: number[];
}): Promise<AxiosResponse<PaginatedApiResponse<InvestorRole>>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/investors/titles`,
    method: "GET",
    params: {
      limit: DEFAULT_INVESTOR_ROLES_PAGE_SIZE,
      offset: (page - 1) * DEFAULT_INVESTOR_ROLES_PAGE_SIZE,
      fundIds: fundIds?.join(","),
    },
    paramsSerializer: {
      indexes: true,
    },
    headers: getHeaders(accessToken),
  };

  return axios(config);
};
