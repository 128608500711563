import { Button, Typography } from "@50y/celestial";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FilledCheckIcon } from "src/assets/icons/general/FilledCheckIcon";
import { GmailIcon } from "src/assets/icons/general/GmailIcon";
import { GoogleIcon } from "src/assets/icons/general/GoogleIcon";
import { LinkedInIcon } from "src/assets/icons/ui/LinkedInIcon";
import { Section } from "src/components/Section";
import {
  ActionEvents,
  IGoogleContactsImportEventParams,
  NavigationEvents,
} from "src/config/amplitudeEvents";
import { CHROME_EXTENSION_LINK } from "src/config/constants";
import { useAmplitudeTrack } from "src/hooks";
import useTrackPageVisited from "src/hooks/useTrackPageVisited";
import { useGoogleToken } from "src/queries/google-token/useGoogleToken";
import { useCreateGoogleTokenMutation } from "src/queries/google-token/useGoogleTokenMutation";
import { useUpdateGmailThreadsImport } from "src/queries/useImports";
import { useMe } from "src/queries/useMe";
import {
  getGoogleCalendarEvents,
  getGooglePeopleApiContacts,
  getGooglePeopleApiOtherContacts,
  getUserGoogleEmail,
  launchGmailAuthorization,
  launchGoogleAuthorization,
} from "src/services/callGoogleApis";
import {
  getGoogleImportHistory,
  importGoogleContacts,
  importGoogleEvents,
} from "src/services/imports.service";
import { v4 as uuidv4 } from "uuid";

const MITRA_SERVICE_BATCH_SIZE = 1000;
const GOOGLE_ACCESS_TOKEN_KEY = "accessTokenGoogle";
const GMAIL_AUTH_CODE_KEY = "gmailAuthCode";

const useGoogleAuthorizationRedirect = () => {
  // This method captures the access token from the URL in case we
  // were redirected from a google authentication event.

  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.slice(1));
    const accessTokenFromUrl = urlParams.get("access_token");
    const errorFromUrl = urlParams.get("error");
    // TODO(kiafathi): This is a temp hack, we should probably not do this.
    // Save access token from url to local storage
    if (errorFromUrl) {
      setAccessToken(null);
      setErrorMsg(
        "Please try again and give permission to access your Google Contacts.",
      );
    } else if (accessTokenFromUrl) {
      setErrorMsg(null);
      setAccessToken(accessTokenFromUrl);
      window.localStorage.setItem(
        "accessTokenGoogle",
        accessTokenFromUrl ?? "",
      );

      navigate(window.location.pathname, { replace: true });
    }
  }, [accessToken, errorMsg, navigate]);

  return [accessToken, errorMsg];
};

const useGmailAuthorizationCode = () => {
  // This method captures the access token from the URL in case we
  // were redirected from a google authentication event.
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCodeFromUrl = urlParams.get("code");
    const errorFromUrl = urlParams.get("error");

    if (errorFromUrl) {
      setAuthCode(authCode);
      setErrorMsg("Failed to authorize Gmail.");
    } else if (authCodeFromUrl) {
      setErrorMsg(null);
      setAuthCode(authCodeFromUrl);
      window.localStorage.setItem(GMAIL_AUTH_CODE_KEY, authCodeFromUrl ?? "");

      navigate(window.location.pathname, { replace: true });
    }
  }, [authCode, errorMsg, navigate]);

  return [authCode, errorMsg];
};

const useSaveGoogleAuthCode = () => {
  const { mutate: createGoogleToken } = useCreateGoogleTokenMutation();

  useEffect(() => {
    const authCode = window.localStorage.getItem(GMAIL_AUTH_CODE_KEY) ?? null;
    if (authCode) {
      window.localStorage.removeItem(GMAIL_AUTH_CODE_KEY);
      createGoogleToken({ code: authCode });
    }
  });

  return;
};

const useImportDataFromGoogle = (
  accessTokenGoogle: string | null,
  getAccessTokenSilently: any,
) => {
  // This method coordinates hitting the google APIs for data we want to import.
  // It's called automatically when the "accessTokenGoogle" gets set.
  const [updatedAt, setUpdatedAt] = useState(Date.now());
  const [loading, setLoading] = useState(false);
  const { track } = useAmplitudeTrack();

  useEffect(() => {
    const accessToken =
      accessTokenGoogle ??
      window.localStorage.getItem(GOOGLE_ACCESS_TOKEN_KEY) ??
      null;

    // Always remove
    window.localStorage.removeItem(GOOGLE_ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return;
    }

    const importDataFromGoogle = async () => {
      const generalError = "Something went wrong!";
      // Start by showing the loading indicator.
      setLoading(true);
      const startTime = Date.now();

      // Get the authorized user's email from the access token.
      const result = await getUserGoogleEmail(accessToken);
      const email = result.email;
      const allEvents = [];
      const allContacts = [];

      const mitraAccessToken = await getAccessTokenSilently();
      const batchId = uuidv4();

      // Get Google Contacts and store in allContacts
      try {
        allContacts.push(...(await getGooglePeopleApiContacts(accessToken)));
      } catch (e) {
        toast.error(generalError);
      }

      // Get Other Contacts and store in allContacts
      try {
        allContacts.push(
          ...(await getGooglePeopleApiOtherContacts(accessToken)),
        );
      } catch (e) {
        toast.error(generalError);
      }

      // Get Google Calendar Events and store in allEvents
      try {
        allEvents.push(...(await getGoogleCalendarEvents(accessToken)));
      } catch (e) {
        toast.error(generalError);
      }

      const promises = [];

      // Post Contacts to backend, paginated
      if (allContacts.length) {
        const contactsCount = allContacts.length;

        for (let i = 0; i < contactsCount; i += MITRA_SERVICE_BATCH_SIZE) {
          const contactsBatch = allContacts.slice(
            i,
            i + MITRA_SERVICE_BATCH_SIZE,
          );
          const promise = importGoogleContacts(
            mitraAccessToken,
            email,
            contactsBatch,
            batchId,
          );
          promises.push(promise);
        }
      }

      // Post Events to backend, paginated
      if (allEvents.length) {
        const totalEvents = allEvents.length;

        for (let i = 0; i < totalEvents; i += MITRA_SERVICE_BATCH_SIZE) {
          const eventsBatch = allEvents.slice(i, i + MITRA_SERVICE_BATCH_SIZE);
          const promise = importGoogleEvents(
            mitraAccessToken,
            email,
            eventsBatch,
            batchId,
          );
          promises.push(promise);
        }
      }

      // Wait for all promises before updating the UI.
      await Promise.all(promises);

      setUpdatedAt(Date.now());
      setLoading(false);
      const endTime = Date.now();

      const params: IGoogleContactsImportEventParams = {
        accountEmail: email,
        importBatchId: batchId,
        duration: endTime - startTime,
        contactsCount: allContacts.length,
        eventsCount: allEvents.length,
        threadSummariesCount: 0,
      };
      track(ActionEvents.GOOGLE_CONNECTIONS_IMPORT_COMPLETED, params);
    };
    importDataFromGoogle();
  }, [accessTokenGoogle, getAccessTokenSilently, track]);

  return [updatedAt, loading];
};

const useGetGoogleImportHistory = (
  importsUpdated: any,
  getAccessTokenSilently: any,
) => {
  const [importHistory, setImportHistory] = useState<any>([]);

  useEffect(() => {
    const fn = async () => {
      const accessToken = await getAccessTokenSilently();
      const result = await getGoogleImportHistory(accessToken);
      setImportHistory(result.data);
    };
    fn();
  }, [importsUpdated, getAccessTokenSilently]);

  return [importHistory];
};

const tw = {
  page: "bg-elevation-outline-0 flex flex-col justify-center items-center p-6 gap-6",
  infoBox: "bg-elevation-surface-1 p-height-s rounded-sm flex gap-1",
  errorBox:
    "bg-elevation-background border border-informational-error-outline p-height-s rounded-sm flex gap-3 items-center",
  sectionTitle: "flex items-center gap-4",
  sectionContent: "flex flex-col gap-4",
  fitWidth: "!w-fit",
};

const AddConnectionsPage = () => {
  const { track } = useAmplitudeTrack();
  const { data: currentUser } = useMe();

  useTrackPageVisited(NavigationEvents.ADD_CONNECTIONS_PAGE_VIEWED);
  const { getAccessTokenSilently } = useAuth0();

  // Sets up the backend OAuth methods
  useGmailAuthorizationCode();
  useSaveGoogleAuthCode();

  const [accessTokenGoogle, errorMsg] = useGoogleAuthorizationRedirect();

  const { data } = useGoogleToken();

  const refreshTokenPresent = !!data?.data.refresh_token_present;

  const [updatedAt, loading] = useImportDataFromGoogle(
    accessTokenGoogle,
    getAccessTokenSilently,
  );

  const [importHistory] = useGetGoogleImportHistory(
    updatedAt,
    getAccessTokenSilently,
  );

  const {
    mutate: updateGmailThreads,
    isPending: isGmailThreadsImportPending,
    isSuccess: updatedGmailThreads,
  } = useUpdateGmailThreadsImport();

  const onExtensionClick = () => {
    track(ActionEvents.CLICKED_CHROME_EXTENSION_INSTALL, {
      page: "add connections",
    });
    window.open(CHROME_EXTENSION_LINK);
  };

  const onImportClick = () => {
    launchGoogleAuthorization(getAccessTokenSilently);
  };

  const authorizeGmail = () => {
    launchGmailAuthorization();
  };

  return (
    <div className={tw.page}>
      <Section className="w-full">
        <div className={tw.sectionTitle}>
          <GoogleIcon />
          <Typography variant="prominent-1" size="title-6">
            From Google Contacts & Calendar
          </Typography>
        </div>
        <div className={tw.sectionContent}>
          <div>
            <Typography variant="default" size="body-4">
              We&apos;ll import your contacts from contacts.google.com as well
              as contacts from your Google Calendar, and match it against
              investors in our database.
            </Typography>
            <Typography variant="prominent-2" size="body-4">
              Click on &quot;Import Google Contacts And Calendar&quot;
            </Typography>
            <Typography variant="default" size="body-4">
              below, select an account, and authorize access. We never access or
              download any of your emails or attachments. Calendar data is only
              used for determining connection and contact strength.
            </Typography>
          </div>

          {importHistory?.length > 0 &&
            importHistory.map((item: any, index: any) => (
              <div className={tw.infoBox} key={index}>
                <Typography variant="prominent-1" size="label-2">
                  {item.imported_connections_count} imported
                </Typography>
                <Typography variant="default" size="label-2">
                  from
                </Typography>
                <Typography variant="prominent-1" size="label-2">
                  {item.account_email}
                </Typography>
              </div>
            ))}
          {errorMsg && (
            <div className={tw.errorBox}>
              <FilledCheckIcon className="w-6 h-6 fill-informational-error" />
              <Typography variant="default" size="label-2">
                {errorMsg}
              </Typography>
            </div>
          )}
          <Button
            variant="secondary"
            className={tw.fitWidth}
            size="small"
            isLoading={!!loading}
            onPress={!loading ? () => onImportClick() : undefined}
          >
            Import Google Contacts and Calendar
          </Button>
        </div>
      </Section>
      {currentUser?.data?.is_staff && (
        <Section className="w-full">
          <div className={tw.sectionTitle}>
            <GmailIcon />
            <Typography variant="prominent-1" size="title-6">
              Import Gmail Metadata
            </Typography>
          </div>
          <div className={tw.sectionContent}>
            <div>
              <Typography variant="default" size="body-4">
                We&apos;ll import the metadata of your Gmail threads.
                <br />
                Click on &quot;Authorize Gmail&quot; below, to select an account
                (e.g. your work email) and authorize access.
                <br />
                Once authorized, click &quot;Import Threads&quot; to begin a
                background import.
                <br />
                We cannot access the content of your emails or attachments.
                Metadata is used to determine your connectivity to existing
                people in the 50Y network. We won&apos;t add new contacts to the
                network.
              </Typography>
            </div>
            <div className="flex gap-4 my-4">
              <Button
                variant="secondary"
                className={tw.fitWidth}
                isLoading={!!loading}
                isDisabled={refreshTokenPresent}
                onPress={!loading ? authorizeGmail : undefined}
              >
                {refreshTokenPresent ? "Gmail Authorized √" : "Authorize Gmail"}
              </Button>
              <Button
                variant="outlined"
                className={tw.fitWidth}
                isLoading={!!loading || isGmailThreadsImportPending}
                isDisabled={!refreshTokenPresent || isGmailThreadsImportPending}
                onPress={
                  !loading || isGmailThreadsImportPending
                    ? () => updateGmailThreads()
                    : undefined
                }
              >
                {`Import Gmail Threads ${updatedGmailThreads ? "√" : ""}`}
              </Button>
            </div>
          </div>
        </Section>
      )}
      <Section className="w-full">
        <div className={tw.sectionTitle}>
          <LinkedInIcon className="fill-linkedin" />
          <Typography variant="prominent-1" size="title-6">
            From LinkedIn
          </Typography>
        </div>
        <div className={tw.sectionContent}>
          <div>
            <Typography variant="default" size="body-4">
              Install the Chrome Extension and fetch or update your Linkedin
              connections.
            </Typography>
            <ul className="list-disc px-6">
              <li>
                <Typography variant="default" size="body-4">
                  Install the extension.
                </Typography>
              </li>
              <li>
                <Typography variant="default" size="body-4">
                  Run the Chrome extension.
                </Typography>
              </li>
              <li>
                <Typography variant="default" size="body-4">
                  Click &quot;Import&quot; and your connections will begin to
                  appear.
                </Typography>
              </li>
            </ul>
          </div>
          <Button
            variant="secondary"
            className={tw.fitWidth}
            onPress={onExtensionClick}
          >
            Install the Extension
          </Button>
        </div>
      </Section>
    </div>
  );
};

export default AddConnectionsPage;
