import { useAuth0 } from "@auth0/auth0-react";
import { FetchNextPageOptions, useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getInvestorRoles } from "src/services/investor.service";
import { orderBy } from "src/utils/orderBy";
import { uniqBy } from "src/utils/uniqBy";
import { uniquePredicate } from "src/utils/uniquePredicate";

type UseGetInvestorGroupedRoles = {
  selectedFunds: number[];
};

type ReturnType = {
  roles: InvestroRole[];
  selectedRoles: (string | number)[];
  selectedInvestorIds: number[];
  isLoadingRoles: boolean;
  setRoles: React.Dispatch<React.SetStateAction<InvestroRole[]>>;
  setSelectedRoles: React.Dispatch<React.SetStateAction<string[]>>;
  fetchNextRolesPage(): void;
};

type InvestroRole = {
  count: number;
  id_list: number[];
  role: string;
};

enum OrderFields {
  ROLE = "role",
  COUNT = "count",
}

export const useGetInvestorGroupedRoles = ({
  selectedFunds,
}: UseGetInvestorGroupedRoles): ReturnType => {
  const { getAccessTokenSilently } = useAuth0();
  const page = useRef<number>(1);
  const [roles, setRoles] = useState<InvestroRole[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      page.current = 1;
      const accessToken = await getAccessTokenSilently();
      handleFetchNextPage({
        accessToken,
        page: page.current,
        selectedFunds,
      } as FetchNextPageOptions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFunds.length]);

  const {
    isLoading: isLoadingRoles,
    fetchNextPage: handleFetchNextPage,
    hasNextPage,
    data,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.INVESTOR_TITLES],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getInvestorRoles({
        accessToken,
        page: page.current,
        fundIds: selectedFunds,
      });
    },
    getNextPageParam: () => true,
  } as any);

  const handleLazyLoad = async () => {
    if (hasNextPage && !isFetchingNextPage) {
      const accessToken = await getAccessTokenSilently();
      page.current = page.current + 1;
      handleFetchNextPage({
        accessToken,
        page: page.current,
        selectedFunds,
      } as any);
    }
  };

  useEffect(() => {
    if (data) {
      /*
        Exclude from the list the latest item which is got from lazy loading as it has previous and results.length is 0
        The reason is as that item is not relevant for showing count, as we should rely only on items which have results, 
        or does not have the results but it's received by filtering Funds and Connections
      */
      const latestData: any = data?.pages?.filter((item: any) => {
        return !(!item.data?.results?.length && item?.data?.previous);
      });
      const invesstorTitles: InvestroRole[] =
        latestData[latestData?.length - 1]?.data?.results;

      const mergedData: InvestroRole[] = orderBy(
        uniqBy(
          data?.pages
            .map((item: any) => {
              return item?.data?.results;
            })
            .flat() || [],
          OrderFields.ROLE,
        ).map((item) => {
          if (
            selectedFunds.length &&
            !invesstorTitles?.find(
              (latestItem: InvestroRole) => latestItem.role === item.role,
            )
          ) {
            return {
              ...item,
              count: 0,
            };
          }
          return item;
        }),
        [
          {
            key: OrderFields.COUNT,
            direction: "desc",
          },
          {
            key: OrderFields.ROLE,
            direction: "asc",
          },
        ],
      );

      setRoles(mergedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedFunds.length]);

  const selectedInvestorIds = useMemo(() => {
    return (
      roles
        ?.filter((item: InvestroRole) => new Set(selectedRoles).has(item?.role))
        .map((item: InvestroRole) => item.id_list)
        .flat()
        .filter(uniquePredicate) || []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, selectedRoles.length, selectedFunds.length]);

  return {
    roles,
    selectedRoles,
    isLoadingRoles,
    selectedInvestorIds,
    setRoles,
    setSelectedRoles,
    fetchNextRolesPage: handleLazyLoad,
  };
};
