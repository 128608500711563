export const GmailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    {...props}
  >
    <path
      d="M1.5 17.2521H5V8.75207L0 5.00208V15.7521C0 16.5821 0.6725 17.2521 1.5 17.2521Z"
      fill="#4285F4"
    />
    <path
      d="M17 17.2521H20.5C21.33 17.2521 22 16.5796 22 15.7521V5.00208L17 8.75207"
      fill="#34A853"
    />
    <path
      d="M17 2.25206V8.75206L22 5.00206V3.00206C22 1.14706 19.8825 0.0895582 18.4 1.20206"
      fill="#FBBC04"
    />
    <path
      d="M5 8.75207V2.25208L11 6.75207L17 2.25208V8.75207L11 13.2521"
      fill="#EA4335"
    />
    <path
      d="M0 3.00206V5.00206L5 8.75206V2.25206L3.6 1.20206C2.115 0.0895582 0 1.14706 0 3.00206Z"
      fill="#C5221F"
    />
  </svg>
);
