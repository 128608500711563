import type { SVGProps } from "react";

export const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <rect width="24" height="24" fill="white" fillOpacity="0.01" />
    <path d="M16.3284 7.62549L15.2559 8.67024L18.1125 11.5471H3V13.0471H18.1125L15.2559 15.8984L16.3284 16.9686L21 12.2971L16.3284 7.62549Z" />
  </svg>
);
