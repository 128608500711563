import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { queryClient } from "src/providers/queryClient";
import { createGoogleToken } from "src/services/google-token.service";
import { QUERY_KEYS } from "../query-keys";

type Variables = { code: string };

export const useCreateGoogleTokenMutation = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMutation<
    AxiosResponse<{ message: string }>,
    AxiosError<{ message: string }>,
    Variables
  >({
    mutationFn: async ({ code }) => {
      const accessToken = await getAccessTokenSilently();
      return createGoogleToken({ accessToken, code });
    },
    onSuccess: async (result, variables) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GOOGLE_TOKEN],
      });
      toast.success(result.data.message || "Saved Google authorization token.");
    },
    onError: () => {
      toast.error("Failed to save Google authorization token.");
    },
  });
};
