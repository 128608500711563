import { Divider, Popover, Typography } from "@50y/celestial";
import { FC } from "react";
import { Logo } from "src/assets/icons/Logo";
import { Person } from "src/models/person-api-response";
import { getDisplayName } from "src/utils/helpers";
import Socials from "../Socials";

interface IPersonPopoverProps {
  person: Person;
  subtext?: string;
}

export const PersonPopover: FC<IPersonPopoverProps> = ({ person, subtext }) => (
  <Popover
    text={getDisplayName(person)}
    subtext={subtext}
    {...(person.is_user && {
      iconRight: <Logo className="w-5 h-5 ml-2" />,
    })}
    crossOffset={-6}
  >
    <Typography variant="prominent-2" size="body-4" className="mb-2">
      {getDisplayName(person)}
    </Typography>
    <Typography variant="default" size="body-5">
      {subtext}
    </Typography>
    <Divider className="my-6" />
    <Socials person={person} />
  </Popover>
);
