import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { ActionEvents, ISearchEventParams } from "src/config/amplitudeEvents";
import { useAmplitudeTrack, useDebouncedValue } from "src/hooks";
import usePrevious from "src/hooks/usePrevious";
import { AppError, PaginatedApiResponse } from "src/models/api-response";
import {
  LinkedinConnection,
  SavedContact,
  UserType,
} from "src/models/connections-api-response";
import { Investor } from "src/models/investors-api-response";
import { addLog } from "src/services/global.service";

type UseTrackSearch = {
  search: string;
  scope: UserType;
  currentPage: number;
  data:
    | PaginatedApiResponse<
        LinkedinConnection | Investor | SavedContact | undefined
      >
    | undefined;
  error: AppError | undefined | null;
  isLoading: boolean;
};

const TYPE_TIMEOUT = 3000;

const useTrackSearch = ({
  search,
  scope,
  currentPage,
  data,
  error,
  isLoading,
}: UseTrackSearch) => {
  const { track } = useAmplitudeTrack();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const prevIsLoading = usePrevious(isLoading);
  const { getAccessTokenSilently } = useAuth0();

  useDebouncedValue(search, {
    delay: TYPE_TIMEOUT,
    onDebounce: () => setSearchQuery(search),
  });

  const handleaAddLog = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const params: ISearchEventParams = {
      search: searchQuery,
      scope,
    };
    track(ActionEvents.SEARCH_INITIATED, params);
    addLog({
      accessToken,
      type: ActionEvents.SEARCH_INITIATED,
      data: params,
    });
  }, [searchQuery, scope, getAccessTokenSilently, track]);

  useEffect(() => {
    if (searchQuery) {
      handleaAddLog();
    }
  }, [searchQuery, handleaAddLog]);

  useEffect(() => {
    if (search && prevIsLoading && !isLoading) {
      const params: ISearchEventParams = {
        search,
        scope,
        count: data?.count,
      };
      track(ActionEvents.SEARCH_RESULTS_LOADED, params);
    }
  }, [search, data, prevIsLoading, isLoading, currentPage, scope, track]);

  useEffect(() => {
    if (search && error) {
      const params: ISearchEventParams = {
        search,
        error,
      };
      track(ActionEvents.SEARCH_ERROR, params);
    }
  }, [search, error, track]);

  useEffect(() => {
    if (search && !isLoading && !data?.count) {
      const params: ISearchEventParams = {
        search,
        scope,
      };
      track(ActionEvents.SEARCH_NO_RESULTS, params);
    }
  }, [search, isLoading, data, scope, track]);
};

export default useTrackSearch;
