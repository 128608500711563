import { useEffect, useMemo, useState } from "react";
import { IFormEventParams, ModalEvents } from "src/config/amplitudeEvents";
import { BCC_EMAIL } from "src/config/constants";
import { useAmplitudeTrack } from "src/hooks";
import {
  Company,
  ConnectionStrength,
} from "src/models/connections-api-response";
import { Person, User } from "src/models/person-api-response";
import {
  RequestIntroMutationProps,
  useIntroRequestMutation,
} from "src/queries/useIntroRequestMutation";
import {
  getCompanyName,
  getDefaultEmail,
  personIsInvestor,
  personIsUser,
  sortTagsByStrength,
} from "src/utils/helpers";
import { intersectArrays } from "src/utils/intersectArrays";

import {
  Button,
  Dropdown,
  Modal,
  TextArea,
  TextInput,
  Typography,
} from "@50y/celestial";
import { Heading } from "react-aria-components";
import { Routes } from "src/config/routes";
import { SettingsSectionsEnum } from "src/pages/SettingsPage/SettingsPage";
import { openDraft } from "src/utils/email";
import { ConnectedUser } from "./ConnectedUser";
import { Link } from "./Link";

export enum ModalType {
  CONNECTION = "connection",
  INVESTOR = "investor",
  USER = "user",
}

enum TemplateTypes {
  GENERAL = "general",
  FUNDRAISE = "fundraise",
  COBACKED_FUNDRAISE = "cobacked_fundraise",
  USER = "user",
  UNIVERSE = "universe",
}

type PersonBody = {
  connectedUser: User | undefined;
  connectedUserCompany: Company | undefined;
  targetConnection: Person;
  currentUser: User;
};

export type RequestIntroModalProps = {
  currentUser: User;
  targetConnection: Person;
  onClose: () => void;
  savedListId?: number;
};

type Templates = Record<
  TemplateTypes,
  {
    title: string;
    subject(targetConnection: Person, currentUser: User): string;
    body(values: PersonBody): string;
  }
>;

const TEMPLATES: Templates = {
  [TemplateTypes.GENERAL]: {
    title: "General",
    subject: (targetConnection) => {
      return `Intro to ${targetConnection.first_name} ${targetConnection.last_name}?`;
    },
    body: ({ connectedUser, targetConnection, currentUser }: PersonBody) => {
      return `Hi ${
        connectedUser?.person.nickname ||
        connectedUser?.person.first_name ||
        connectedUser?.person.name?.split(" ")?.[0]
      },

It looks like you're connected to ${targetConnection?.name}${
        targetConnection.linkedin_url
          ? ` (${targetConnection.linkedin_url})`
          : ""
      }.

Do you know them well enough to make an introduction?

Best,
${currentUser?.person.nickname || currentUser?.person.first_name}`;
    },
  },
  [TemplateTypes.FUNDRAISE]: {
    title: "Fundraise Related",
    subject: (targetConnection: Person, currentUser: User) => {
      const investor = targetConnection.investor!;
      return `${getCompanyName(currentUser.companies[0])} <> ${
        targetConnection.name
      } @ ${getCompanyName(investor.firm_affiliation[0])}?`;
    },
    body: ({ connectedUser, targetConnection, currentUser }: PersonBody) => {
      const currentUserCompany = currentUser.companies[0];
      return `Hey ${connectedUser?.person.nickname},

I noticed you're connected to ${targetConnection.name} at ${getCompanyName(
        targetConnection.investor?.firm_affiliation[0],
      )}. Do you know them well enough to intro?

We're kicking off a round at ${getCompanyName(
        currentUserCompany,
      )} and think they could be a good partner.

As a reminder, here’s what we do:

${
  currentUserCompany?.blurb
    ? currentUserCompany?.blurb
    : "<< " + getCompanyName(currentUserCompany) + " Blurb >>"
}

Also happy to hop on a 5m call to paint any more color about what we’re looking for!

Best,
${currentUser?.person.nickname}`;
    },
  },
  [TemplateTypes.COBACKED_FUNDRAISE]: {
    title: "Fundraise related",
    subject: (targetConnection: Person, currentUser: User) => {
      const investor = targetConnection.investor!;
      return `${getCompanyName(currentUser.companies[0])} <> ${
        targetConnection.name
      } @ ${getCompanyName(investor.firm_affiliation[0])}?`;
    },
    body: ({
      connectedUser,
      targetConnection,
      currentUser,
      connectedUserCompany,
    }: PersonBody) => {
      const investor = targetConnection.investor!;
      const currentUserCompany = currentUser.companies[0];
      return `Hey ${connectedUser?.person.nickname},

It looks like ${getCompanyName(
        investor.firm_affiliation[0],
      )} is an investor in ${getCompanyName(
        connectedUserCompany,
      )}. Do you know them well enough to intro?

We're kicking off a round at ${getCompanyName(
        currentUserCompany,
      )} and think they could be a good partner.

As a reminder, here’s what we do:
      
${
  currentUserCompany?.blurb
    ? currentUserCompany?.blurb
    : "<< " + getCompanyName(currentUserCompany) + " Blurb >>"
}

Also happy to hop on a 5m call to paint any more color about what we’re looking for!

Best,
${currentUser?.person.nickname}`;
    },
  },
  [TemplateTypes.UNIVERSE]: {
    title: "Universe template",
    subject: (targetConnection: Person) =>
      `Universe, can you help me reach ${targetConnection.name}?`,
    body: ({ targetConnection, currentUser }) => `Dear Universe,
      
I’m trying to reach ${targetConnection.name}${
      targetConnection?.linkedin_url
        ? ` (${targetConnection.linkedin_url})`
        : ""
    }. 

Can the magical Fifty Years elves help me out?

Best,
${currentUser?.person.nickname}`,
  },
  [TemplateTypes.USER]: {
    title: "Connect",
    subject: (targetConnection: Person) => "50Y founder",
    body: ({ targetConnection, currentUser }) =>
      `Hey ${targetConnection.name},

My name is ${currentUser.person.name} and I'm a founder at Fifty Years. I'd love to connect and learn more about what you're working on.

Would you have time in the next couple of weeks for a chat?

Thanks, and look forward to getting to know you.

Best,
${currentUser.person.name}`,
  },
};

// TODO(kiafathi): Render connection strength icon & company info
// const mapStrongConnectionWithDetails = (connection: ConnectionStrength) => {
//   const companyName = getCompanyName(connection.user.companies[0]) ?? "";
//   return connection.user.person.name;
// (
//   <Item key={connection.user.id} value={connection}>
//     <div className="grid grid-cols-[2fr_3fr_30px] items-center">
//       <strong className="whitespace-nowrap">
//         {connection.user.person.name}
//       </strong>
//       <Ellipsis className="secondary-text flex">
//         {connection.user.role}
//         {getCompanyName(connection.user.companies[0]) && (
//           <>
//             <span className="secondary-text">&nbsp;@&nbsp;</span>
//             <span className="flex secondary-text" title={companyName}>
//               {companyName.split(" ")[0]}
//               {companyName.split(" ")[1] && (
//                 <>
//                   &nbsp;
//                   <Ellipsis title={companyName}>
//                     {companyName?.split(" ")?.[1]}
//                   </Ellipsis>
//                 </>
//               )}
//             </span>
//           </>
//         )}
//       </Ellipsis>
//       <div className="flex justify-end items-center">
//         <ConnectionStrengthIcon strength={connection.connection_strength} />
//       </div>
//     </div>
//   </Item>
// );
// };

const RequestIntroModal = ({
  currentUser,
  targetConnection,
  onClose,
  savedListId,
}: RequestIntroModalProps) => {
  const { track } = useAmplitudeTrack();
  const { mutate: handleIntroRequest } = useIntroRequestMutation();

  const isInvestor = personIsInvestor(targetConnection);
  const isUser = personIsUser(targetConnection);

  const allConnections: ConnectionStrength[] = sortTagsByStrength(
    targetConnection.connection_strengths,
  );

  const hasConnections = !!allConnections.length;

  const [selectedConnection, setSelectedConnection] = useState<
    ConnectionStrength | undefined
  >(allConnections?.[0]);

  const connectedUserCompany: Company | undefined = useMemo(() => {
    const investorFunds: number[] =
      targetConnection.investor?.firm_affiliation.map((item) => item.id) || [];
    return selectedConnection?.user?.companies?.find((company) => {
      return !!intersectArrays(investorFunds, company.funds || []).length;
    });
  }, [selectedConnection, targetConnection]);

  const [template, setTemplate] = useState<TemplateTypes>(
    isUser
      ? TemplateTypes.USER
      : !hasConnections
      ? TemplateTypes.UNIVERSE
      : isInvestor && connectedUserCompany
      ? TemplateTypes.COBACKED_FUNDRAISE
      : isInvestor
      ? TemplateTypes.FUNDRAISE
      : TemplateTypes.GENERAL,
  );

  const templateItems = useMemo(() => {
    if (isUser) {
      return [
        {
          id: TemplateTypes.USER,
          label: "Connect",
        },
      ];
    }
    if (!hasConnections) {
      return [
        {
          id: TemplateTypes.UNIVERSE,
          label: "Universe template",
        },
      ];
    }

    const templates = [{ id: TemplateTypes.GENERAL, label: "General" }];
    if (isInvestor) {
      if (connectedUserCompany) {
        templates.push({
          id: TemplateTypes.COBACKED_FUNDRAISE,
          label: "Cobacked Fundraise related",
        });
      } else {
        templates.push({
          id: TemplateTypes.FUNDRAISE,
          label: "Fundraise related",
        });
      }
    }
    return templates;
  }, [isInvestor, connectedUserCompany, hasConnections, isUser]);

  const [subject, setSubject] = useState(
    TEMPLATES[template].subject(targetConnection, currentUser),
  );

  const [body, setBody] = useState(
    TEMPLATES[template].body({
      targetConnection,
      connectedUser: selectedConnection?.user,
      currentUser,
      connectedUserCompany,
    }),
  );

  useEffect(() => {
    setBody(
      TEMPLATES[template].body({
        targetConnection,
        connectedUser: selectedConnection?.user,
        currentUser,
        connectedUserCompany,
      }),
    );
    setSubject(TEMPLATES[template].subject(targetConnection, currentUser));
  }, [
    currentUser,
    targetConnection,
    template,
    selectedConnection,
    connectedUserCompany,
  ]);

  const connectionItems = allConnections.map((item) => ({
    inputElement: <ConnectedUser connection={item} />,
    label: item.user.person.name,
    id: item.user.id,
  }));

  const changeTemplate = ([templateId]: (string | number)[]) => {
    const templateType = templateId as TemplateTypes;

    const params: IFormEventParams = {
      old: template,
      new: templateType,
    };
    track(ModalEvents.CHANGED_TEMPLATE, params);
    setTemplate(templateType);
  };

  const requestIntro = () => {
    const props: RequestIntroMutationProps = {
      type: isInvestor
        ? ModalType.INVESTOR
        : targetConnection.user
        ? ModalType.USER
        : ModalType.CONNECTION,
      user_id: currentUser.id,
      person_id: targetConnection.id,
      subject,
      email_content: body,
      organization_id: targetConnection.investor?.organization?.id,
      saved_list_id: savedListId,
    };
    if (selectedConnection?.user.id) {
      props["connection_id"] = selectedConnection.user.id;
    }
    handleIntroRequest({
      ...props,
    });
  };

  const openDraftInGmail = () => {
    openDraft({
      to: getDefaultEmail(selectedConnection?.user.person.emails) || BCC_EMAIL,
      subject,
      body,
      client: "gmail",
    });
    requestIntro();
  };

  const openDraftInDefaultMailClient = () => {
    openDraft({
      to: getDefaultEmail(selectedConnection?.user.person.emails) || BCC_EMAIL,
      subject,
      body,
      client: "mailto",
    });
    requestIntro();
  };

  return (
    <Modal isOpen={true} onClose={onClose} modalClass="!max-w-intro-modal">
      <Heading slot="title">
        <Typography variant="prominent-1" size="title-4">
          Request intro
        </Typography>
      </Heading>
      <>
        <div className="grid gap-4 mt-8 mb-8">
          <div className="grid gap-4 grid-cols-2">
            {hasConnections && (
              <Dropdown
                onChange={(ids) => {
                  setSelectedConnection(
                    allConnections.find((c) => c.user.id === ids[0]),
                  );
                }}
                label="Best connection"
                placeholder="Select a connection"
                items={connectionItems}
                selectedItemIds={
                  selectedConnection?.user.id
                    ? [selectedConnection.user.id]
                    : []
                }
                closeOnItemSelect={true}
              ></Dropdown>
            )}
            <Dropdown
              onChange={changeTemplate}
              label="E-mail template"
              placeholder="Select a template"
              items={templateItems}
              selectedItemIds={[template]}
              closeOnItemSelect={true}
            ></Dropdown>
          </div>
          <TextInput
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          ></TextInput>
          <TextArea
            label="Message"
            rows={10}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          ></TextArea>
          {!currentUser?.companies?.[0]?.blurb && (
            <div>
              <Link
                to={Routes.RESOURCES_COMPANY_BLURB}
                target="_blank"
                className="text-label-3"
              >
                See our guide on blurbs
              </Link>
              {" · "}
              <Link
                to={Routes.SETTINGS + "#" + SettingsSectionsEnum.TEMPLATES}
                className="text-label-3"
              >
                Update your company blurb ↗
              </Link>
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <Button variant="outlined" onPress={openDraftInDefaultMailClient}>
            Open Default Mail App
          </Button>
          <Button variant="primary" onPress={openDraftInGmail}>
            Open Draft in Gmail
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default RequestIntroModal;
