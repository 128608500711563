import { Dropdown, Typography } from "@50y/celestial";
import { useEffect, useState } from "react";
import { FundsIcon } from "src/assets/icons/ui/FundsIcon";
import { SavedListsIcon } from "src/assets/icons/ui/SavedListsIcon";
import { Fund } from "src/models/funds-api-response";
import { InvestorRole } from "src/models/investors-api-response";
import { useFunds } from "src/queries/filters/useFunds";
import { useGetInvestorGroupedRoles } from "src/queries/filters/useGetInvestorGroupedRoles";
import { useProvideData } from "src/queries/filters/useProvideData";
import { uniquePredicate } from "src/utils/uniquePredicate";

export type Data = InvestorRole[] | Fund[] | undefined;

export type FilterReturnType = {
  investors?: number[];
  funds?: number[];
  connections?: number[];
};

type IFilters = {
  handleFilterChange(values: FilterReturnType): void;
};

const Filters = ({ handleFilterChange }: IFilters) => {
  const [searchFunds, setSearchFunds] = useState<string>("");
  const [searchRoles, setSearchRoles] = useState<string>("");
  const [selectedFunds, setSelectedFunds] = useState<(string | number)[]>([]);
  const [selectedFundIds, setSelectedFundIds] = useState<number[]>([]);

  const {
    roles,
    selectedRoles,
    selectedInvestorIds,
    setSelectedRoles,
    fetchNextRolesPage,
  } = useGetInvestorGroupedRoles({
    selectedFunds: selectedFundIds,
  });

  const { funds } = useFunds({
    selectedRoles,
    investorIds: selectedInvestorIds,
    ordering: "users_count",
  });

  useEffect(() => {
    const selectedData: number[] =
      funds
        .filter((item: Fund) => selectedFunds.includes(item?.id))
        .map((item: Fund) => item?.id)
        .filter(uniquePredicate) || [];

    setSelectedFundIds(selectedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funds, selectedFunds.length, selectedInvestorIds.length]);

  const [values] = useProvideData({
    selectedInvestorIds,
    selectedFundIds,
  });

  useEffect(
    () => handleFilterChange(values),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values],
  );

  return (
    <div className="relative flex items-center gap-6 ml-8 z-10">
      <Typography
        size="label-2"
        className="text-text-default-default-secondary"
      >
        Filter By:
      </Typography>
      <div className="flex gap-4">
        <Dropdown
          selectionMode="multiple"
          selectedItemIds={selectedRoles}
          label=""
          placeholder="Title"
          inputValue={searchRoles}
          onInputValueChange={setSearchRoles}
          items={
            roles
              .map((role) => ({
                id: role.role,
                label: role.role,
                count: role.count,
              }))
              .filter((role) =>
                role.label.toLowerCase().includes(searchRoles.toLowerCase()),
              ) || []
          }
          onChange={
            (selectedRoles) =>
              setSelectedRoles(selectedRoles.map((role) => role.toString())) // expects string, dropdown can have both strings and numbers
          }
          onClear={() => {
            setSelectedRoles([]);
          }}
          fetchNextPage={fetchNextRolesPage}
          icon={<SavedListsIcon className="fill-interactive-icon-idle" />}
        />
        <Dropdown
          selectionMode="multiple"
          selectedItemIds={selectedFunds}
          label=""
          placeholder="Company"
          inputValue={searchFunds}
          onInputValueChange={setSearchFunds}
          items={
            funds
              .map((fund) => ({
                id: fund.id,
                label: fund.organization.name,
                count: fund.users_count,
              }))
              .filter((fund) =>
                fund.label.toLowerCase().includes(searchFunds.toLowerCase()),
              ) || []
          }
          onChange={(selectedFunds) => setSelectedFunds(selectedFunds)}
          onClear={() => {
            setSelectedFunds([]);
          }}
          icon={<FundsIcon className="fill-interactive-icon-idle" />}
        />
      </div>
    </div>
  );
};

export default Filters;
