export const FounderResourcesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="fill-current"
    {...props}
  >
    <path d="M16.25 6.25H11.875V7.5H16.25V6.25Z" />
    <path d="M16.25 9.375H11.875V10.625H16.25V9.375Z" />
    <path d="M16.25 12.5H11.875V13.75H16.25V12.5Z" />
    <path d="M8.125 6.25H3.75V7.5H8.125V6.25Z" />
    <path d="M8.125 9.375H3.75V10.625H8.125V9.375Z" />
    <path d="M8.125 12.5H3.75V13.75H8.125V12.5Z" />
    <path d="M17.5 3.125H2.5C2.16858 3.12533 1.85083 3.25713 1.61648 3.49148C1.38213 3.72583 1.25033 4.04358 1.25 4.375V15.625C1.25033 15.9564 1.38213 16.2742 1.61648 16.5085C1.85083 16.7429 2.16858 16.8747 2.5 16.875H17.5C17.8314 16.8747 18.1492 16.7429 18.3835 16.5085C18.6179 16.2742 18.7497 15.9564 18.75 15.625V4.375C18.7497 4.04358 18.6179 3.72583 18.3835 3.49148C18.1492 3.25713 17.8314 3.12533 17.5 3.125ZM2.5 4.375H9.375V15.625H2.5V4.375ZM10.625 15.625V4.375H17.5V15.625H10.625Z" />
  </svg>
);
