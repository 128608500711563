import { Typography } from "@50y/celestial";
import { FC } from "react";
import { SortDirection, SortIcon } from "src/assets/icons/general/SortIcons";

interface ISortableCellProps {
  cellText: string;
  direction?: SortDirection;
  onSortClick: () => void;
}

export const SortableCell: FC<ISortableCellProps> = ({
  cellText,
  direction,
  onSortClick,
}) => {
  const groupName = cellText.toLowerCase().replace(" ", "-");
  return (
    <div
      className={`flex items-center gap-2 cursor-pointer w-content group/t${groupName}`}
      onClick={() => onSortClick()}
    >
      <Typography variant="prominent-1" size="label-3">
        {cellText}
      </Typography>
      <SortIcon
        direction={direction}
        className={`group-hover/t${groupName}:visible`}
      />
    </div>
  );
};
