import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  AppError,
  GenericApiResponse,
  PaginatedApiResponse,
} from "src/models/api-response";
import {
  Company,
  LinkedinConnection,
  Status,
} from "src/models/connections-api-response";
import { callExternalApi } from "./callExternalApi";
import {
  API_SERVER_URL,
  DEFAULT_PAGE_SIZE,
  getHeaders,
  getRequestData,
} from "./global.service";

export const getLinkedinConnections = async (
  accessToken: string,
  page: number,
  search: string,
  sort?: string | undefined,
  userId?: number,
): Promise<AxiosResponse<PaginatedApiResponse<LinkedinConnection>>> => {
  const params = {
    limit: DEFAULT_PAGE_SIZE,
    offset: (page - 1) * DEFAULT_PAGE_SIZE,
    search,
    ordering: sort,
    connected_users: userId,
  };
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/connections/linkedin`,
    method: "GET",
    params,
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const updateConnectionStatus = async ({
  accessToken,
  savedContactId,
  status,
}: {
  accessToken: string;
  savedContactId: number;
  status: number;
}): Promise<{ data: string | null; error: AppError | null }> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-contacts/status`,
    method: "POST",
    data: {
      saved_contact_id: savedContactId,
      status,
    },
    headers: getHeaders(accessToken),
  };

  const { data, error } = (await callExternalApi({
    config,
  })) as { data: string; error: AppError };
  return {
    data,
    error,
  };
};

export const saveContact = async ({
  accessToken,
  personId,
}: {
  accessToken: string;
  personId: number;
}): Promise<{
  data: { id: number; message: string } | null;
  error: AppError | null;
}> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-contacts`,
    method: "POST",
    data: {
      person_id: personId,
    },
    headers: getHeaders(accessToken),
  };

  const { data, error } = (await callExternalApi({
    config,
  })) as { data: { id: number; message: string }; error: AppError };
  return {
    data,
    error,
  };
};

export const getStatuses = async (
  accessToken: string,
): Promise<AxiosResponse<Status[]>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/status`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getCompany = async (
  accessToken: string,
  id: number,
): Promise<AxiosResponse<Company>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/companies/${id}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const updateCompany = async (
  accessToken: string,
  id: number,
  updates: Partial<Company>,
): Promise<GenericApiResponse<Company>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/companies/${id}`,
    method: "patch",
    headers: getHeaders(accessToken),
    data: {
      ...updates,
    },
  };

  const { data, error } = (await getRequestData(
    config,
  )) as GenericApiResponse<Company>;

  return {
    data,
    error,
  };
};
