import { Button, Dialog, Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useMemo, useRef } from "react";
import { useElementSize } from "src/hooks/useElementSize";
import { ConnectionStrength as ConnectionStrengthType } from "src/models/connections-api-response";
import { TableCellBreakpoints } from "src/utils/constants";
import { getDisplayName } from "src/utils/helpers";
import { ConnectionStrength } from "./ConnectionStrength";
import UserTag, { groupHover } from "./UserTag";

interface TagsWrapperProps {
  tags: ConnectionStrengthType[];
  className?: string;
  onClick?: () => void;
  openRequestIntroModal: () => void;
}

const tw = {
  buttonTrigger:
    "flex items-center hover:!text-text-default-text-default-tertiary active:!text-text-default-text-default-tertiary !w-8 !h-8 justify-center rounded-full bg-elevation-surface-1 !p-1 focus-within:outline-none",
  popoverItem:
    "flex gap-2 justify-start px-4 py-2 items-center border-t border-elevation-outline-1 first:border-t-0",
};

export const TagsWrapper = ({
  tags,
  className,
  onClick,
  openRequestIntroModal,
}: TagsWrapperProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    wrapperSize: { width },
  } = useElementSize(wrapperRef);

  // This function is used to determine how many tags to display based on the window size
  function tagsToDisplay(width: number) {
    if (width > TableCellBreakpoints.LARGE_SCREEN) {
      return { tags: tags.slice(0, 12), showMore: tags.slice(12) };
    }
    if (width > TableCellBreakpoints.DESKTOP_SCREEN) {
      return { tags: tags.slice(0, 10), showMore: tags.slice(10) };
    }
    if (width > TableCellBreakpoints.MEDIUM_SCREEN) {
      return { tags: tags.slice(0, 7), showMore: tags.slice(7) };
    }
    if (width > TableCellBreakpoints.DEFAULT_SCREEN) {
      return { tags: tags.slice(0, 5), showMore: tags.slice(5) };
    }
    if (width > TableCellBreakpoints.TABLET_SCREEN) {
      return { tags: tags.slice(0, 4), showMore: tags.slice(4) };
    }
    if (width > TableCellBreakpoints.DEFAULT_TABLET_SCREEN) {
      return { tags: tags.slice(0, 3), showMore: tags.slice(3) };
    }
    if (width > TableCellBreakpoints.SMALL_TABLET_SCREEN) {
      return { tags: tags.slice(0, 2), showMore: tags.slice(2) };
    }
    if (width > TableCellBreakpoints.MOBILE_SCREEN) {
      return { tags: tags.slice(0, 1), showMore: tags.slice(1) };
    }
    return { tags: [], showMore: tags };
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generateTags = useMemo(() => tagsToDisplay(width), [width]);

  if (!tags.length) return null;

  return (
    <div className={cx("flex gap-2 items-center", className)} ref={wrapperRef}>
      {generateTags.tags.map((tag) => (
        <UserTag
          key={tag?.user?.id}
          user={tag?.user}
          is_confident={!!tag?.is_confident}
          connection_strength={tag?.connection_strength}
          onClick={onClick}
          openRequestIntroModal={openRequestIntroModal}
        />
      ))}
      {generateTags.showMore.length > 0 && (
        <Dialog
          dialogButton={
            <Button
              onPress={onClick}
              className={cx(tw.buttonTrigger, groupHover)}
              variant="outlined"
              textProps={{
                variant: "prominent-1",
                size: "body-5",
                className:
                  "hover:!text-text-default-text-default-tertiary active:!text-text-default-text-default-tertiary",
              }}
            >
              {`+${generateTags.showMore.length}`}
            </Button>
          }
          popoverProps={{
            className: "py-0 px-0 !rounded-input-rounding",
          }}
        >
          {generateTags.showMore.map((tag) => (
            <div key={tag?.user?.id} className={tw.popoverItem}>
              <ConnectionStrength
                is_confident={!!tag.is_confident}
                strength={tag.connection_strength}
              />
              <Typography variant="default" size="label-2">
                {getDisplayName(tag.user.person)}
              </Typography>
            </div>
          ))}
        </Dialog>
      )}
    </div>
  );
};
