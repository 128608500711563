import { useEffect, useRef, useState } from "react";

const DEFAULT_SEARCH_DELAY_MS = 300;

type UseDebouncedValueOptions<T> = {
  delay?: number;
  onDebounce?: (newValue: T) => void;
};

export function useDebouncedValue(
  value: string,
  {
    delay = DEFAULT_SEARCH_DELAY_MS,
    onDebounce,
  }: UseDebouncedValueOptions<string>,
): string {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);

  const { current } = useRef({
    delay,
    onDebounce,
    value,
    debounceId: 0 as number,
    debounceValue(newValue: string) {
      if (newValue === current.value) {
        return;
      }
      current.value = newValue;
      if (current.debounceId) clearTimeout(current.debounceId);
      // @ts-expect-error doesnt work xD
      current.debounceId = setTimeout(() => {
        setDebouncedValue(current.value);
        if (current.onDebounce) current.onDebounce(current.value);
      }, current.delay);
    },
    cleanup() {
      if (current.debounceId) clearTimeout(current.debounceId);
    },
  });
  current.delay = delay;
  current.onDebounce = onDebounce;
  current.debounceValue(value);

  useEffect(() => current.cleanup(), [current]);

  return debouncedValue;
}
