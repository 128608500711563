import { Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { useTrackPageVisited } from "src/hooks";
import { useMe } from "src/queries/useMe";
import { AdminSettingsTab } from "./AdminSettingsTab";
import { CompanySettingsTab } from "./CompanySettingsTab";
import { ProfileSettingsTab } from "./ProfileSettingsTab";
import { tw } from "./styles";
import { TemplatesTab } from "./TemplateSettingsTab";

export enum SettingsSectionsEnum {
  PERSONAL = "personal",
  COMPANY = "company",
  TEMPLATES = "templates",
  ADMIN = "admin",
}

export const DEBOUNCE_TIMEOUT_MS = 500;

const settingsTabs = [
  {
    name: "Personal Settings",
    key: SettingsSectionsEnum.PERSONAL,
  },
  {
    name: "Company Settings",
    key: SettingsSectionsEnum.COMPANY,
  },
  {
    name: "Templates",
    key: SettingsSectionsEnum.TEMPLATES,
  },
  {
    name: "Admin Settings",
    key: SettingsSectionsEnum.ADMIN,
  },
];

const SettingsPage = () => {
  const { data: me } = useMe();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(SettingsSectionsEnum.PERSONAL);

  useEffect(() => {
    if (hash) {
      const tab = hash.replace("#", "") as SettingsSectionsEnum;
      const hashIsValid =
        SettingsSectionsEnum[
          tab.toUpperCase() as keyof typeof SettingsSectionsEnum
        ] !== undefined;
      if (hashIsValid) {
        setSelectedTab(tab);
      }
    }
  }, [hash]);

  useTrackPageVisited(NavigationEvents.SETTINGS_PAGE_VIEWED);

  return (
    <div className="flex flex-row h-full">
      <div className={tw.tabNav}>
        {settingsTabs.map((tab) => {
          const isSelected = selectedTab === tab.key;
          if (tab.key === SettingsSectionsEnum.ADMIN && !me?.data?.is_staff) {
            return null;
          }
          return (
            <div
              key={tab.key}
              className={cx(tw.tab, isSelected && tw.activeTab)}
              onClick={() => navigate(`#${tab.key}`)}
            >
              <Typography
                variant={isSelected ? "prominent-2" : "default"}
                size="body-4"
              >
                {tab.name}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className={tw.tabContent}>
        <div className="w-full max-w-3xl m-auto">
          {selectedTab === SettingsSectionsEnum.PERSONAL && (
            <ProfileSettingsTab />
          )}
          {selectedTab === SettingsSectionsEnum.COMPANY && (
            <CompanySettingsTab />
          )}
          {selectedTab === SettingsSectionsEnum.TEMPLATES && <TemplatesTab />}
          {selectedTab === SettingsSectionsEnum.ADMIN && me?.data?.is_staff && (
            <AdminSettingsTab />
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
