export const ClockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="fill-current"
    {...props}
  >
    <rect
      width="16"
      height="16"
      transform="translate(0.5)"
      fill="white"
      fillOpacity="0.01"
    />
    <path d="M8.5 15C7.11553 15 5.76216 14.5895 4.61101 13.8203C3.45987 13.0511 2.56266 11.9579 2.03285 10.6788C1.50303 9.3997 1.36441 7.99224 1.63451 6.63437C1.9046 5.2765 2.57129 4.02922 3.55026 3.05026C4.52922 2.07129 5.7765 1.4046 7.13437 1.13451C8.49224 0.86441 9.8997 1.00303 11.1788 1.53285C12.4579 2.06266 13.5511 2.95987 14.3203 4.11101C15.0895 5.26216 15.5 6.61553 15.5 8C15.5 9.85652 14.7625 11.637 13.4497 12.9497C12.137 14.2625 10.3565 15 8.5 15ZM8.5 2C7.31332 2 6.15328 2.3519 5.16658 3.01119C4.17989 3.67047 3.41085 4.60755 2.95673 5.7039C2.5026 6.80026 2.38378 8.00666 2.61529 9.17054C2.8468 10.3344 3.41825 11.4035 4.25736 12.2426C5.09648 13.0818 6.16558 13.6532 7.32946 13.8847C8.49335 14.1162 9.69975 13.9974 10.7961 13.5433C11.8925 13.0892 12.8295 12.3201 13.4888 11.3334C14.1481 10.3467 14.5 9.18669 14.5 8C14.5 6.4087 13.8679 4.88258 12.7426 3.75736C11.6174 2.63214 10.0913 2 8.5 2Z" />
    <path d="M10.795 11L8 8.205V3.5H9V7.79L11.5 10.295L10.795 11Z" />
  </svg>
);
