import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getLinkedinConnections } from "src/services/connection.service";

export const useLinkedinConnections = (
  page: number,
  search: string,
  sort?: string,
  userId?: number,
) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [QUERY_KEYS.CONNECTIONS, { page, search, sort, userId }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getLinkedinConnections(accessToken, page, search, sort, userId);
    },
  });
};
