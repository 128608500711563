import { User } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_SERVER_URL, getHeaders } from "./global.service";

export const updateFuxStep = async (
  accessToken: string,
  step: number,
): Promise<AxiosResponse<User>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/users/me`,
    method: "patch",
    headers: getHeaders(accessToken),
    data: {
      fux: {
        step,
      },
    },
  };

  return axios(config);
};
