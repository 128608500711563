import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  GenericApiResponse,
  PaginatedApiResponse,
} from "src/models/api-response";
import { SavedList } from "src/models/lists-api-response";
import { Person } from "src/models/person-api-response";
import { API_SERVER_URL, getHeaders, getRequestData } from "./global.service";

export const LISTS_PAGE_SIZE = 10;
export const LIST_PAGE_SIZE = 25;

export const getLists = async (
  accessToken: string,
  nextPage: number | string,
  search?: string,
): Promise<AxiosResponse<PaginatedApiResponse<SavedList>>> => {
  const config: AxiosRequestConfig = {
    url: `${
      typeof nextPage === "string"
        ? nextPage
        : `${API_SERVER_URL}/api/saved-lists?page=${nextPage}&page_size=${LISTS_PAGE_SIZE}&name=${
            search ?? "" // handling undefined search for lists dropdown that do not have search
          }`
    }`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const createList = async ({
  accessToken,
  name,
}: {
  accessToken: string;
  name: string;
}): Promise<AxiosResponse<{ id: number; message: string }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists`,
    method: "POST",
    data: {
      name,
    },
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getSavedListDetails = async (
  accessToken: string,
  id: number | string,
): Promise<GenericApiResponse<SavedList>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${id}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };
  return axios(config);
};

export const getSavedListPeople = async (
  accessToken: string,
  id: number | string,
  nextPage: number,
  sort: string,
): Promise<GenericApiResponse<PaginatedApiResponse<Person>>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${id}/person?page=${nextPage}&page_size=${LIST_PAGE_SIZE}&ordering=${sort}`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const editSavedList = async (
  accessToken: string,
  listId: number,
  name: string,
): Promise<GenericApiResponse<{ message: "string" }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${listId}`,
    method: "PATCH",
    data: {
      name,
    },
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const deleteSavedList = async (
  accessToken: string,
  listId: number,
): Promise<GenericApiResponse<{ message: "string" }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${listId}`,
    method: "DELETE",
    headers: getHeaders(accessToken),
  };

  const { data, error } = await getRequestData(config);

  return { data, error };
};

export const addContactToList = async ({
  accessToken,
  listId,
  personId,
}: {
  accessToken: string;
  listId: number | string;
  personId: number;
}): Promise<AxiosResponse<{ message: "string" }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${listId}/person/${personId}`,
    method: "POST",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const removeContactFromList = async ({
  accessToken,
  listId,
  peopleIds,
}: {
  accessToken: string;
  listId: number | string;
  peopleIds: number[];
}) => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${listId}/batch_delete_people`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: {
      person_ids: peopleIds,
    },
  };

  return axios(config);
};

export const updatePersonStatusForList = async ({
  accessToken,
  listId,
  personId,
  status,
}: {
  accessToken: string;
  listId: number | string;
  personId: number;
  status: number;
}): Promise<AxiosResponse<{ message: string }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/saved-lists/${listId}/person/${personId}/status`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: {
      status,
    },
  };

  return axios(config);
};
