import { Button, Typography } from "@50y/celestial";
import noConnectionsImg from "src/assets/images/no-connections.jpg";

interface IProps {
  title: string;
  description: string;
  callToActionText?: string;
  onClick?: () => void;
}

const PageEmptyState = ({
  title,
  description,
  callToActionText,
  onClick,
}: IProps) => (
  <div className="h-full flex justify-center items-center">
    <div className="grid gap-6 justify-center max-w-[520px] m-auto">
      <img src={noConnectionsImg} alt="No connections" />
      <div className="flex flex-col gap-4 px-7 justify-center items-center">
        <Typography variant="prominent-1" size="title-4" as="h1">
          {title}
        </Typography>
        <Typography variant="default" size="body-4" className="text-center">
          {description}
        </Typography>
        {callToActionText && onClick && (
          <Button variant="primary" size="large" width="l" onPress={onClick}>
            {callToActionText}
          </Button>
        )}
      </div>
    </div>
  </div>
);

export default PageEmptyState;
