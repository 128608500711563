import type { SVGProps } from "react";

export const ChevronRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.25105 6.25105C9.58579 5.91632 10.1285 5.91632 10.4632 6.25105L15.6061 11.3939C15.9408 11.7286 15.9408 12.2714 15.6061 12.6061L10.4632 17.7489C10.1285 18.0837 9.58579 18.0837 9.25105 17.7489C8.91632 17.4142 8.91632 16.8715 9.25105 16.5368L13.7878 12L9.25105 7.46323C8.91632 7.1285 8.91632 6.58579 9.25105 6.25105Z"
    />
  </svg>
);
