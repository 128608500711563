import { Button, Typography } from "@50y/celestial";
import { useNavigate } from "react-router-dom";

export const PageError = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center h-full gap-6">
      <Typography variant="prominent-1" size="title-2">
        Error loading page content
      </Typography>
      <div>
        <Button onPress={() => navigate(-1)}>Go back</Button>
      </div>
    </div>
  );
};
