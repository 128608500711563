import dayjs from "dayjs";
import {
  EVENT_CARD_DATE_FORMAT,
  EVENT_CARD_TIME_FORMAT,
  PACIFIC_TIMEZONE,
} from "src/config/constants";
import {
  CompanyPartial,
  ConnectionStrength,
  ConnectionStrengthEnum,
} from "src/models/connections-api-response";
import { Email, Person } from "src/models/person-api-response";

const WORDS_PER_MINUTE = 250;

export function getDefaultEmail(email: Email[] | undefined) {
  if (!email) {
    return "";
  }

  const defaultEmail = email.find((e) => e.is_default);
  return defaultEmail?.email ?? "";
}

export const getCompanyName = (company: CompanyPartial | undefined): string => {
  return company?.organization.display_name || company?.organization.name || "";
};

export const connectionStrengthScoreToEnum = (
  score: number,
): ConnectionStrengthEnum => {
  if (!score) {
    return ConnectionStrengthEnum.NoConnection;
  }
  if (score >= 70) {
    return ConnectionStrengthEnum.High;
  }
  if (score >= 45) {
    return ConnectionStrengthEnum.Medium;
  }
  return ConnectionStrengthEnum.Low;
};

export const getConnectionStrengthContext = ({
  email_connection_score,
  is_cobacker_connection,
  is_linkedin_connection,
  is_google_contact,
  share_google_event,
}: ConnectionStrength): string => {
  const sources = [];
  if (email_connection_score) {
    sources.push("Gmail threads");
  }
  if (is_linkedin_connection) {
    sources.push("Linkedin");
  }
  if (is_google_contact) {
    sources.push("Google Contacts");
  }
  if (share_google_event) {
    sources.push("Google Events");
  }
  if (is_cobacker_connection) {
    sources.push("investment");
  }

  if (sources.length === 1) {
    return `Connected via ${sources[0]}`;
  }

  return `Connected via ${sources
    .map((source, idx) =>
      idx === sources.length - 1 ? `and ${source}` : source,
    )
    .join(", ")}`;
};

export const sortTagsByStrength = (connection: ConnectionStrength[]) => {
  return connection.sort(
    (a, b) => (b.connection_strength || 0) - (a.connection_strength || 0),
  );
};

/**
 *
 * @param url - full URL - not only query part
 * @param param - query parameter to get
 * @returns - value of the query parameter or null if it does not exist
 */
export const getQueryParam = (url: string, param: string) => {
  if (!url.includes("?")) {
    return null;
  }
  const urlParams = new URLSearchParams(url.split("?")[1]);
  return urlParams.get(param);
};

export const getReadingTime = (str: string) => {
  return Math.ceil(str.split(" ").length / WORDS_PER_MINUTE);
};

export const getDisplayName = (person: Person) => {
  return `${person.nickname || person.first_name} ${person.last_name}`;
};

export const getParentRoute = (pathname: string) => {
  const pathParts = pathname.split("/");
  pathParts.pop();
  return pathParts.join("/");
};

export const personIsInvestor = (person: Person) => {
  return !!person.investor;
};

export const personIsUser = (person: Person) => {
  return !!person.user;
};

export const personIsLinkedInConnection = (person: Person) => {
  return !!person.linkedinconnection;
};

export const getPersonAddress = (person?: Person) => {
  if (!person) {
    return "";
  }
  const addressArray = [person.city, person.country]; // we have more properties regarding address that we want to add later on
  return addressArray.filter(Boolean).join(", ");
};

export const getTitle = (person: Person) => {
  return (
    person.occupation ||
    (personIsInvestor(person)
      ? person.investor!.role
      : personIsLinkedInConnection(person)
      ? person.headline
      : personIsUser(person)
      ? person.user!.role
      : "")
  );
};

export const getEventDateAndTime = (date: string): string => {
  return `${dayjs(date)
    .tz(PACIFIC_TIMEZONE)
    .format(EVENT_CARD_DATE_FORMAT)} at ${dayjs(date)
    .tz(PACIFIC_TIMEZONE)
    .format(EVENT_CARD_TIME_FORMAT)}`;
};
