export const FundsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className="fill-current"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.18656 3.13452C6.67694 1.88037 4.63573 1.49988 2.5013 1.49988H1.91797V2.08321C1.91797 4.06288 2.48924 6.08386 3.80886 7.61963C5.04729 9.0609 6.90134 10.0235 9.41797 10.1519V18.75H10.5846V10.1519C13.1013 10.0235 14.9553 9.0609 16.1937 7.61963C17.5134 6.08386 18.0846 4.06288 18.0846 2.08321V1.49988H17.5013C15.3669 1.49988 13.3257 1.88037 11.816 3.13452C11.0239 3.7926 10.4097 4.6639 10.0013 5.78152C9.59287 4.6639 8.97869 3.7926 8.18656 3.13452ZM9.40593 8.98288C7.21158 8.85518 5.69041 8.01921 4.69374 6.85929C3.72 5.72606 3.20714 4.23651 3.10415 2.67801C4.87192 2.74716 6.36282 3.13615 7.44104 4.03191C8.53378 4.93972 9.30199 6.45369 9.40593 8.98288ZM10.5967 8.98288C12.791 8.85518 14.3122 8.01921 15.3089 6.85929C16.2826 5.72606 16.7955 4.23651 16.8985 2.67801C15.1307 2.74716 13.6398 3.13615 12.5616 4.03191C11.4688 4.93972 10.7006 6.45369 10.5967 8.98288Z"
    />
  </svg>
);
