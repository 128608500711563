import { useAuth0 } from "@auth0/auth0-react";
import { cx } from "class-variance-authority";
import { useEffect, useMemo, useState } from "react";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { FilledCheckIcon } from "src/assets/icons/general/FilledCheckIcon";
import { Routes } from "src/config/routes";
import { useWindowSize } from "src/hooks";
import { useMe } from "src/queries/useMe";
import { useSetFuxStepMutation } from "src/queries/useSetFuxStepMutation";
import { ScreenBreakpoints } from "src/utils/constants";
import OnboardingModal from "./fux/OnboardingModal";
import { Header } from "./Header";
import { Loader } from "./Loader";
import { SideNav } from "./SideNav";

const tw = {
  loader: "flex justify-center items-center w-screen h-screen",
  toast:
    "border bg-elevation-background rounded-sm relative flex justify-between overflow-hidden cursor-pointer text-text-default-default-primary shadow-lg py-3-5 px-4 w-full mt-8",
  toastBody:
    "gap-3 p-0 family-heading items-start leading-font-headings-default text-label-2",
  success: "border-informational-success-outline fill-informational-success",
  error: "border-informational-error-outline fill-informational-error",
  info: "border-elevation-outline-1 fill-interactive-icon-idle",
  warning: "border-informational-warning-outline fill-informational-warning",
  default: "border-elevation-outline-1 fill-interactive-icon-idle",
};

export const Layout = () => {
  const [currentUserLoaded, setCurrentUserLoaded] = useState(false);
  const { windowSize } = useWindowSize();
  const { isLoading, isAuthenticated } = useAuth0();
  const { mutate: setFuxStep } = useSetFuxStepMutation();
  const location = useLocation();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const {
    data: me,
    refetch: refetchUser,
    isLoading: isUserLoading,
    isError: isAuthError,
  } = useMe();

  useEffect(() => {
    if (
      windowSize.width < ScreenBreakpoints.TABLET &&
      location.pathname !== Routes.EVENTS
    ) {
      navigate(Routes.MOBILE);
    }
  }, [windowSize.width, location.pathname, navigate]);

  const isInvestorsOrConnectionsPage = useMemo(() => {
    return (
      location.pathname === Routes.INVESTORS ||
      location.pathname === Routes.CONNECTIONS
    );
  }, [location.pathname]);

  const showModal = isInvestorsOrConnectionsPage && me?.data?.fux?.step === 0;

  useEffect(() => {
    if (isUserLoading) return;
    setCurrentUserLoaded(true);
  }, [isUserLoading]);

  if (isLoading || !currentUserLoaded) {
    return (
      <div className={tw.loader}>
        <Loader />
      </div>
    );
  }

  if (isAuthError && isAuthenticated) {
    return <Navigate to={Routes.NOT_ALLOWED} />;
  }

  return (
    <div className="w-full flex h-full">
      <ToastContainer
        position="bottom-left"
        icon={() => <FilledCheckIcon className="w-6 h-6" />}
        autoClose={5000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Slide}
        closeButton={false}
        toastClassName={(context) =>
          tw[context?.type || "default"] + " " + tw.toast
        }
        bodyClassName={tw.toastBody}
      />
      {windowSize.width >= ScreenBreakpoints.TABLET && isAuthenticated && (
        <SideNav />
      )}
      <div
        className={cx(
          "w-full flex-grow overflow-x-hidden",
          windowSize.width < ScreenBreakpoints.TABLET && "!max-w-full",
        )}
      >
        {isAuthenticated && <Header />}
        <div className={cx("h-[calc(100%_-_73px)] overflow-y-auto")}>
          {navigation.state === "loading" &&
            !location.pathname.includes(Routes.RESOURCES) && <Loader />}
          {showModal && (
            <OnboardingModal
              onClose={() => {
                setFuxStep({ step: 1 });
                refetchUser();
              }}
            />
          )}
          <Outlet />
        </div>
      </div>
    </div>
  );
};
