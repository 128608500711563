import { useState } from "react";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";

export const useSort = (initialSort: string) => {
  const { track } = useAmplitudeTrack();
  const [sort, setSort] = useState<string>(initialSort);
  const sortHandler = (field: string) => () => {
    let sortStr = "";

    track(ActionEvents.CLICKED_SORT, {
      sort: field,
    });
    if (sort === field) {
      sortStr = `-${field}`;
    } else if (sort === `-${field}`) {
      sortStr = "";
    } else {
      sortStr = field;
    }
    setSort(sortStr);
  };

  const getSort = (field: string) =>
    sort === field ? "desc" : sort === `-${field}` ? "asc" : undefined;

  return {
    sort,
    sortHandler,
    getSort,
  };
};
