export const EmailIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="fill-current"
    {...props}
  >
    <path d="M20 0H2C1.175 0 0.5 0.6675 0.5 1.5V13.5C0.5 14.325 1.175 15 2 15H20C20.825 15 21.5 14.325 21.5 13.5V1.5C21.5 0.675 20.825 0 20 0ZM18.35 1.5L11 6.585L3.65 1.5H18.35ZM2 13.5V2.1825L10.5725 8.1225C10.7 8.2125 10.85 8.2575 11 8.2575C11.15 8.2575 11.3 8.2125 11.4275 8.1225L20 2.19V13.5075H2V13.5Z" />
  </svg>
);
