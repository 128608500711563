import React from "react";
import { createRoot } from "react-dom/client";
/**
 * Import must go in this order so that TW base from Universe does not override Celestial styles
 * with this we prevent autosorting of imports
 */
// eslint-disable-next-line prettier/prettier
import "src/styles/styles.css";
// eslint-disable-next-line prettier/prettier
import "@50y/celestial/dist/cjs/index.css";
import { App } from "src/app";

import initializeSentry from "./initializeSentry";

const IS_DEV = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

if (!IS_DEV) {
  initializeSentry();
}

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
