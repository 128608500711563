export const getIntersectionElements = (
  list1: (number | string)[] = [],
  list2: (number | string)[] = [],
): (number | string)[] => {
  if (!list1.length) {
    return list2;
  } else if (!list2.length) {
    return list1;
  }

  return list1.filter((value) => list2.includes(value));
};
