import { Article, Typography } from "@50y/celestial";
import { cva, cx, VariantProps } from "class-variance-authority";
import React, { FC, PropsWithChildren, useEffect, useMemo } from "react";
import { Link, Navigate, useLoaderData, useLocation } from "react-router-dom";
import { ClockIcon } from "src/assets/icons/ui/ClockIcon";
import connectingImage from "src/assets/images/connecting.png";
import BreadcrumbComponent from "src/components/Breadcrumbs";
import { Deals } from "src/components/Deals";
import { MarkdownContent } from "src/components/MarkdownContent";
import { StickyHeader } from "src/components/StickyHeader";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { Routes } from "src/config/routes";
import { useAmplitudeTrack } from "src/hooks";
import { useElementSize } from "src/hooks/useElementSize";
import { GenericApiResponse } from "src/models/api-response";
import {
  FounderResources,
  GeneralStrapiResponse,
} from "src/models/strapi-api-response";
import {
  generateBreadcrumbs,
  getHeadings,
  slugToLink,
} from "src/utils/founderResourcesHelpers";
import { getReadingTime } from "src/utils/helpers";
import { slugifyTitle } from "src/utils/slugify";
import { FounderResourcesSearch } from "./FounderResourcesSearch";
import { HeroSection } from "./HeroSection";
import { ITableOfContents, TableOfContents } from "./TableofContents";

export const twClasses = {
  page: "bg-white flex flex-col h-full overflow-y-auto",
  pageFlex: "flex flex-row py-8 px-6 relative mx-auto my-0 w-full gap-[75px]",
  pageMainWrapper: "w-full flex justify-center max-w-[calc(100%-325px)]",
  pageContentWrapper: "pb-10 w-full",
  pageName: "!font-bold !text-[32px] !leading-[35px] mb-[20px] m-auto",
  pageSubtitle: "mb-[20px] !text-[14px] !leading-none m-auto",
  pageCardsWrapper: "grid gap-10 w-fit grid-cols-1 lg:grid-cols-2 m-auto",
  pageCardWrapperSmall:
    "!flex items-center justify-start flex-wrap !gap-3 !w-full",
  pageCardText: "font-bold",
  maxWidth: `max-w-2xl m-auto w-full`,
};

const HEADING_TAG_REGEX = /#{1,6} |\*{1,2}/gm;

export const resourcesLayout = cva("", {
  variants: {
    layout: {
      default: "p-6 bg-elevation-surface-1 h-full overflow-x-auto no-scrollbar",
      withTableOfContent: "w-full overflow-hidden flex",
    },
  },
});

type ResourceVariantProps = VariantProps<typeof resourcesLayout>;

const ResourceLayout: FC<PropsWithChildren<ResourceVariantProps>> = ({
  children,
  layout,
}) => {
  return <div className={resourcesLayout({ layout })}>{children}</div>;
};

export const ResourcePage: React.FC = () => {
  const { pathname } = useLocation();
  const { track } = useAmplitudeTrack();

  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const { wrapperSize } = useElementSize(wrapperRef);

  const { data: founderContent } = useLoaderData() as GenericApiResponse<
    GeneralStrapiResponse<FounderResources>
  >;

  useEffect(() => {
    track(NavigationEvents.RESOURCES_PAGE_VIEWED, {
      pageName: pathname,
    });
  }, [pathname, track]);

  const pageData = useMemo(() => {
    return founderContent?.data?.[0]?.attributes;
  }, [founderContent?.data]);

  const headingsList = getHeadings(pageData?.coda_markdown || "");

  const headingIds: ITableOfContents[] | undefined = useMemo(
    () =>
      headingsList?.map((item) => {
        const title = item.replaceAll(HEADING_TAG_REGEX, "").trim();
        return {
          item,
          title,
          id: slugifyTitle(title),
        };
      }),
    [headingsList],
  );

  const isDealsPage = pathname.includes("deals");

  if (!founderContent?.data?.length) {
    return <Navigate to={Routes.NOT_FOUND} replace />;
  }

  return (
    <div className={twClasses.page}>
      <BreadcrumbComponent
        breadcrumbs={generateBreadcrumbs(pathname, founderContent?.data ?? [])}
      />
      <StickyHeader>
        <FounderResourcesSearch />
      </StickyHeader>
      <ResourceLayout
        layout={
          !pageData.coda_markdown || isDealsPage
            ? "default"
            : "withTableOfContent"
        }
      >
        {(!pageData.coda_markdown || isDealsPage) && (
          <HeroSection
            name={pageData.name}
            subtitle={pageData.subtitle}
            isDealsPage={isDealsPage}
          />
        )}
        {!!pageData.coda_markdown && !isDealsPage && (
          <div
            className={cx(
              "overflow-y-scroll overflow-x-hidden no-scrollbar scroll-smooth",
              headingIds?.length ? "w-blog" : "w-full",
            )}
          >
            {pageData.image?.data?.attributes?.url && (
              <div
                style={{
                  backgroundImage: `url(${pageData.image.data.attributes.url})`,
                }}
                className="h-40 bg-no-repeat bg-cover bg-center w-full"
              />
            )}
            <div ref={wrapperRef} className="grid gap-8 p-8">
              <div className="grid gap-4">
                <div
                  className={cx("flex gap-1 items-center", twClasses.maxWidth)}
                >
                  <ClockIcon className="fill-text-default-text-default-tertiary" />
                  <Typography
                    variant="default"
                    size="label-3"
                    className="text-text-default-text-default-tertiary"
                  >
                    {getReadingTime(pageData?.coda_markdown)} minute read
                  </Typography>
                </div>
                <Typography
                  size="title-1"
                  variant="prominent-1"
                  className={cx(twClasses.maxWidth)}
                >
                  {pageData?.name}
                </Typography>
              </div>
              {!!pageData.children.data.length && (
                <div
                  className={cx(twClasses.maxWidth, "grid gap-2 grid-cols-2")}
                >
                  {pageData.children?.data?.map(({ attributes, id }) => (
                    <Link to={slugToLink(attributes.slug)} key={id}>
                      <Article
                        size="links"
                        title={attributes.name}
                        image={{
                          url:
                            attributes.image?.data?.attributes?.url ??
                            connectingImage,
                        }}
                        justify="justifyCenter"
                      />
                    </Link>
                  ))}
                </div>
              )}
              {pageData?.subtitle && (
                <Typography size="body-4" variant="prominent-1">
                  {pageData.subtitle}
                </Typography>
              )}
              {!!pageData?.coda_markdown && (
                <div
                  className={cx(
                    pageData?.coda_blocks.length ? "pb-0" : "pb-[500px]",
                  )}
                >
                  <MarkdownContent
                    codaMarkdown={pageData?.coda_markdown}
                    parentWidth={wrapperSize.width}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {!!headingIds?.length && <TableOfContents headingIds={headingIds} />}
        {!pageData.coda_markdown && pageData.children.data.length && (
          <div className="grid gap-6 grid-cols-resources-grid">
            {pageData.children.data.length &&
              pageData.children.data.map(({ attributes, id }) => (
                <Link
                  key={id}
                  className="no-underline"
                  to={slugToLink(attributes.slug)}
                >
                  <Article
                    size="small"
                    title={attributes.name}
                    image={{
                      url:
                        attributes.image?.data?.attributes?.url ??
                        connectingImage,
                    }}
                  >
                    <div className="flex gap-1 items-center">
                      <ClockIcon className="fill-text-default-text-default-tertiary" />
                      <Typography
                        variant="default"
                        size="label-3"
                        className="text-text-default-text-default-tertiary"
                      >
                        {getReadingTime(attributes?.coda_markdown)} minute read
                      </Typography>
                    </div>
                  </Article>
                </Link>
              ))}
          </div>
        )}
        {!!pageData?.coda_blocks.length && (
          <Deals cards={pageData.coda_blocks} />
        )}
      </ResourceLayout>
    </div>
  );
};
