type OrderByDirection = "asc" | "desc";

type SortConfig<T> = {
  key: keyof T;
  direction?: OrderByDirection;
};

export const orderBy = <T extends Record<string, any>>(
  items: T[],
  sortConfigs: SortConfig<T>[],
): T[] => {
  return items.slice().sort((a, b) => {
    for (const { key, direction = "asc" } of sortConfigs) {
      const valueA = a[key];
      const valueB = b[key];

      if (valueA < valueB) {
        return direction === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === "asc" ? 1 : -1;
      }
    }

    return 0;
  });
};
