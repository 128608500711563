import { useAuth0 } from "@auth0/auth0-react";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ActionEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack } from "src/hooks";
import { PaginatedApiResponse } from "src/models/api-response";
import { SavedList } from "src/models/lists-api-response";
import { queryClient } from "src/providers/queryClient";
import { QUERY_KEYS } from "src/queries/query-keys";
import {
  deleteSavedList,
  editSavedList,
  getLists,
} from "src/services/lists.service";

/**
 * @returns query cached per page for saved lists page
 */
export const useLists = (page: number, search?: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, refetch, isLoading, error, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.LISTS, { page, search }],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getLists(accessToken, page, search);
    },
  });

  useEffect(() => {
    if (error) {
      toast.error("Unable to retrieve saved lists");
    }
  }, [error]);

  return {
    data: data?.data,
    refetch,
    isLoading,
    count: data?.data?.count,
    isFetching,
  };
};

/**
 * @returns infinite query for saved lists dropdown
 */
export const useListsInfinite = () => {
  const { getAccessTokenSilently } = useAuth0();

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    fetchPreviousPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.LISTS_INFINITE],
    queryFn: async ({ pageParam }) => {
      const accessToken = await getAccessTokenSilently();
      return getLists(accessToken, pageParam);
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.next ? lastPage?.data?.next : (undefined as any);
    },
    staleTime: Infinity,
  });

  useEffect(() => {
    if (error) {
      toast.error("Unable to retrieve saved lists");
    }
  }, [error]);

  return {
    data,
    count: data?.pages[0].data.count,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    refetch,
    fetchPreviousPage,
    isFetchingNextPage,
  };
};

export const useEditList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation({
    mutationFn: async ({
      list,
    }: {
      list: SavedList;
      page: number;
      search?: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      return await editSavedList(accessToken, list.id, list.name);
    },
    onSuccess: async (_, variables) => {
      track(ActionEvents.LIST_EDITED, {
        list_name: variables?.list.name,
        list_person_count: variables?.list.people_count,
      });
      toast.success("List name updated");
      queryClient.setQueryData(
        [QUERY_KEYS.LISTS, { page: variables.page, search: variables.search }],
        (oldData: AxiosResponse<PaginatedApiResponse<SavedList>>) => {
          const updatedPage = oldData.data.results.map((list: SavedList) => {
            if (list.id === variables.list.id) {
              return {
                ...list,
                name: variables.list.name,
              };
            }
            return list;
          });
          return {
            ...oldData,
            data: {
              ...oldData.data,
              results: updatedPage,
            },
          };
        },
      );
    },
    onError: () => {
      toast.error("Unable to update list name");
    },
  });
};

export const useDeleteList = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { track } = useAmplitudeTrack();

  return useMutation({
    mutationFn: async ({
      list,
    }: {
      list: SavedList;
      page: number;
      search?: string;
    }) => {
      const accessToken = await getAccessTokenSilently();
      return await deleteSavedList(accessToken, list.id);
    },
    onSuccess: async (_, variables) => {
      track(ActionEvents.LIST_DELETED, {
        list_name: variables?.list.name,
        list_person_count: variables?.list.people_count,
      });
      let hasPageRemoved = false;
      queryClient.setQueryData(
        [QUERY_KEYS.LISTS, { page: variables.page, search: variables.search }],
        (oldData: AxiosResponse<PaginatedApiResponse<SavedList>>) => {
          const updatedPage = oldData.data.results.filter(
            (list: SavedList) => list.id !== variables.list.id,
          );
          if (!updatedPage.length) {
            hasPageRemoved = true;
          }
          return {
            ...oldData,
            data: {
              ...oldData.data,
              results: updatedPage,
            },
          };
        },
      );
      toast.success("Deleted list");
      if (hasPageRemoved) {
        // only refetch all due to count data if page is removed - AKA no more lists on that page
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.LISTS, { page: variables.page }],
        });
      }
    },
    onError: () => {
      toast.error("Unable to delete list");
    },
  });
};
