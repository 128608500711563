export const XIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M17.7512 2.96106H20.818L14.1179 10.6188L22 21.0392H15.8284L10.9946 14.7193L5.46359 21.0392H2.39494L9.5613 12.8484L2 2.96106H8.32828L12.6976 8.73772L17.7512 2.96106ZM16.6748 19.2036H18.3742L7.4049 4.70027H5.58133L16.6748 19.2036Z" />
  </svg>
);
