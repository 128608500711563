import { cx } from "class-variance-authority";
import { FC, PropsWithChildren } from "react";

export const StickyHeader: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={cx(
      "sticky top-0 z-50 h-[72px] bg-elevation-background py-3 px-6 border-b border-elevation-outline-0 items-center flex",
      className,
    )}
  >
    {children}
  </div>
);
