import { useAuth0 } from "@auth0/auth0-react";
import { getGoogleToken } from "src/services/google-token.service";

import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../query-keys";

export const useGoogleToken = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: [QUERY_KEYS.GOOGLE_TOKEN],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getGoogleToken({ accessToken });
    },
  });
};
