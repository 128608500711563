export const PCIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M19.5 17.25H4.5C4.10232 17.2495 3.72105 17.0914 3.43984 16.8102C3.15864 16.529 3.00046 16.1477 3 15.75V5.25C3.0004 4.8523 3.15856 4.471 3.43978 4.18978C3.721 3.90856 4.1023 3.7504 4.5 3.75H19.5C19.8977 3.75046 20.2789 3.90864 20.5602 4.18984C20.8414 4.47105 20.9995 4.85232 21 5.25V15.75C20.9995 16.1477 20.8413 16.5289 20.5601 16.8101C20.2789 17.0913 19.8977 17.2495 19.5 17.25ZM4.5 5.25V15.75H19.5V5.25H4.5Z" />
    <path d="M22.5 18.75H1.5V20.25H22.5V18.75Z" />
  </svg>
);
