export const SocialsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="fill-current"
    {...props}
  >
    <path d="M19.5 7.5H14.25V9H19.5V7.5Z" />
    <path d="M19.5 11.25H14.25V12.75H19.5V11.25Z" />
    <path d="M19.5 15H14.25V16.5H19.5V15Z" />
    <path d="M9.75 7.5H4.5V9H9.75V7.5Z" />
    <path d="M9.75 11.25H4.5V12.75H9.75V11.25Z" />
    <path d="M9.75 15H4.5V16.5H9.75V15Z" />
    <path d="M21 3.75H3C2.6023 3.7504 2.221 3.90856 1.93978 4.18978C1.65856 4.471 1.5004 4.8523 1.5 5.25V18.75C1.5004 19.1477 1.65856 19.529 1.93978 19.8102C2.221 20.0914 2.6023 20.2496 3 20.25H21C21.3977 20.2496 21.779 20.0914 22.0602 19.8102C22.3414 19.529 22.4996 19.1477 22.5 18.75V5.25C22.4996 4.8523 22.3414 4.471 22.0602 4.18978C21.779 3.90856 21.3977 3.7504 21 3.75ZM3 5.25H11.25V18.75H3V5.25ZM12.75 18.75V5.25H21V18.75H12.75Z" />
  </svg>
);
