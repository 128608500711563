import { Investor } from "src/models/investors-api-response";
import { Note, Person, User } from "./person-api-response";

export enum ConnectionStrengthEnum {
  High = "Strong connection",
  Medium = "Connected",
  Low = "Weak connection",
  NoConnection = "Not connected",
}

export interface Organization {
  id: number;
  name: string;
  website?: string;
  display_name?: string;
}
export interface CompanyPartial {
  id: number;
  organization: Organization;
  blurb?: string | null;
}

export interface Founder {
  id: number;
  name: string;
  title: string;
  email_address: string;
}

export interface Company {
  id: number;
  organization: Organization;
  next_stage: string | null;
  blurb: string | null;
  stage: string;
  founders: Founder[];
  affinity_entity_id: string;
  affinity_row_id: string;
  created_at: string;
  updated_at: string;
  funds?: number[];
  tagline?: string;
}

export interface Connection {
  id: number;
  person: Person;
  role: string | null;
  companies: Company[];
}

export interface Connections {
  fifty_years: Connection[];
  linkedin: Connection[];
  google_contacts?: Connection[];
  google_events?: Connection[];
  cobacked: Connection[];
}

export interface ConnectionStrength {
  user: User;
  connection_strength: number;
  email_connection_score?: number;
  is_cobacker_connection?: boolean;
  is_google_contact?: boolean;
  is_linkedin_connection?: boolean;
  share_google_event?: boolean;
  is_confident?: boolean;
  person: number;
}

export interface LinkedinConnection {
  id: number;
  person: Person;
  avatar_url: string | null;
  starred_list_id: number | null;
  saved_list_ids: number[];
  note?: Note;
}

export interface Status {
  id: number;
  name: string;
}

export interface SavedContact {
  connection: LinkedinConnection & Investor;
  created_at: string;
  id: number;
  requested_intro: {
    requested_at: string;
    requested_by: string;
  } | null;
  updated_at: string;
  user: number;
  statuses: { status_id: number }[];
}

export enum Page {
  ALL_CONNECTIONS = "all connections",
  INVESTORS = "investors",
  SAVED_LISTS = "saved lists",
}

export enum UserType {
  CONNECTION = "connection",
  INVESTOR = "investor",
  SAVED = "saved",
}
