import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Fund } from "src/models/funds-api-response";
import { QUERY_KEYS } from "src/queries/query-keys";
import { getFunds } from "src/services/funds.service";
import { orderBy } from "src/utils/orderBy";
import { uniqBy } from "src/utils/uniqBy";

type UseFundsProps = {
  selectedRoles: (string | number)[];
  investorIds: number[];
  ordering?: string;
};

enum OrderFields {
  NAME = "person__first_name,person__last_name",
  USERS_COUNT = "users_count",
  ID = "id",
}

export const useFunds = ({
  selectedRoles,
  investorIds,
  ordering,
}: UseFundsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [funds, setFunds] = useState<Fund[]>([]);
  const page = useRef<number>(1);

  const { isLoading, data, refetch } = useQuery({
    queryKey: [QUERY_KEYS.FUNDS],
    queryFn: async () => {
      const accessToken = await getAccessTokenSilently();
      return getFunds({
        accessToken,
        investorIds,
        ordering,
        page: page.current,
      });
    },
  });

  useEffect(() => {
    refetch();
  }, [selectedRoles, refetch]);

  useEffect(() => {
    if (data) {
      const fundsResult: Fund[] = data.data?.results;

      const mergedData: any = orderBy(
        uniqBy(data?.data.results || [], OrderFields.ID).map((item) => {
          if (
            investorIds.length &&
            !fundsResult?.find((latestItem: Fund) => latestItem.id === item.id)
          ) {
            return { ...item, users_count: 0 };
          }
          return item;
        }),
        [
          {
            key: OrderFields.USERS_COUNT,
            direction: "desc",
          },
        ],
      );
      setFunds(mergedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, investorIds.length]);

  return {
    funds,
    isLoading,
  };
};
