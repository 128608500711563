import { Typography } from "@50y/celestial";
import { NavigationEvents } from "src/config/amplitudeEvents";
import { useTrackPageVisited } from "src/hooks";

const TermsPage = () => {
  useTrackPageVisited(NavigationEvents.TERMS_PAGE_VIEWED);

  return (
    <Typography variant="prominent-1" size="title-1">
      Terms and Conditions
    </Typography>
  );
};

export default TermsPage;
