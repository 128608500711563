import { BCC_EMAIL } from "src/config/constants";

export const openDraft = ({
  to,
  subject,
  body,
  client,
}: {
  to: string;
  subject: string;
  body: string;
  client: "gmail" | "mailto";
}) => {
  const to_enc = encodeURIComponent(to);
  const bccc = encodeURIComponent(BCC_EMAIL);
  const subject_enc = encodeURIComponent(subject);
  const body_enc = encodeURIComponent(body);
  const bcc_text = `&bcc=${bccc}`;

  if (client === "gmail") {
    const href = `https://mail.google.com/mail/u/0/?tf=cm&to=${to_enc}${bcc_text}&su=${subject_enc}&body=${body_enc}&fs=1`;
    window.open(href, "_blank");
  } else if (client === "mailto") {
    const href = `mailto:${to_enc}?body=${body_enc}${bcc_text}&subject=${subject_enc}`;
    window.open(href);
  }
};
