import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { QUERY_KEYS } from "src/queries/query-keys";
import {
  getPage,
  getPages,
  searchResources,
} from "src/services/founder-resources.service";

export const useFounderResources = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEYS.FOUNDER_RESOURCES + "infinite"],
      queryFn: getPages,
      initialPageParam: 1,
      getNextPageParam: (lastPage, _pages, lastPageParam) => {
        return lastPage.data.meta.pagination.page ===
          lastPage.data.meta.pagination.pageCount
          ? undefined
          : lastPageParam + 1;
      },
    });

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return { data: data?.pages.flatMap((page) => page.data.data), isLoading };
};

export const useFounderResource = ({ slug }: { slug: string }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: [QUERY_KEYS.FOUNDER_RESOURCE, slug],
    queryFn: () => getPage({ slug }),
  });

  return { data, isLoading, error };
};

export const useFounderResourcesSearch = ({
  searchTerm,
}: {
  searchTerm: string;
}) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.FOUNDER_RESOURCE, { searchTerm }],
    queryFn: () => searchResources({ searchTerm }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _pages, lastPageParam) => {
      return lastPage.data.meta.pagination.page ===
        lastPage.data.meta.pagination.pageCount
        ? undefined
        : lastPageParam + 1;
    },
  });

  return {
    data: data?.pages.flatMap((page) => page.data.data),
    isLoading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};
