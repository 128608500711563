import { AppError } from "src/models/api-response";
import {
  Connection,
  Page,
  UserType,
} from "src/models/connections-api-response";
import { User } from "src/models/person-api-response";

export interface IOnboardingEventParams {
  step?: number;
  type?: string;
}

export interface IFormEventParams {
  old: string | User | Connection | undefined;
  new: string | User | Connection | undefined;
}

export interface IRequestIntroClickEventParams {
  page: Page;
  requester_id: number;
  requester_name: string;
  contact_name: string;
  contact_id: number;
  connections_number?: number;
  fifty_years_connections?: number;
}

export interface IRequestIntroOpenDraftEventParams {
  client_selection: "gmail" | "default client";
  email_address: string;
  subject: string;
  body: string;
  contactId: number;
  company?: number;
}

export interface ISocialEventParams {
  channel: "linkedin" | "twitter" | "email";
  url?: string;
  email?: string;
}

export interface IErrorEventParams {
  message: string | undefined;
  stack: string | undefined;
}

export interface ISearchEventParams {
  search: string;
  scope?: UserType;
  count?: number;
  error?: AppError | undefined;
}

export interface IGoogleContactsImportEventParams {
  accountEmail: string;
  importBatchId: string;
  duration: number;
  contactsCount: number;
  eventsCount: number;
  threadSummariesCount: number;
}

export interface CompanyWebsiteClickEventParams {
  page: Page;
  firm: string;
  website: string | undefined;
}

export interface SavedContactEventParams {
  type: UserType;
  contact_name: string;
  contact_id: number;
  page: Page;
  requester_id: number;
  requester_name: string;
}

export enum LoginEvents {
  LOGGED_IN = "logged in",
  LOGGED_OUT = "logged out",
  SIGNED_UP = "signed up",
}

export enum NavigationEvents {
  ADD_CONNECTIONS_PAGE_VIEWED = "add connections page viewed",
  ALL_CONNECTIONS_PAGE_VIEWED = "all connections page viewed",
  INVESTORS_PAGE_VIEWED = "investors page viewed",
  RESOURCES_PAGE_VIEWED = "resources page viewed",
  LOGIN_PAGE_VIEWED = "login page viewed",
  NOT_FOUND_PAGE_VIEWED = "not found page viewed",
  NOT_ALLOWED_PAGE_VIEWED = "not allowed page viewed",
  SETTINGS_PAGE_VIEWED = "settings page viewed",
  TERMS_PAGE_VIEWED = "terms page viewed",
  LISTS_PAGE_VIEWED = "lists page viewed",
  LIST_PAGE_VIEWED = "list viewed",
  EVENTS_PAGE_VIEWED = "events page viewed",
}

export enum ModalEvents {
  CHANGED_EMAIL_SUBJECT = "modal: email subject changed",
  CHANGED_EMAIL_BODY = "modal: email body changed",
  COPIED_CONNECTION_EMAIL = "modal: connection email address copied",
  COPIED_EMAIL_BODY = "modal: email body copied",
  CHANGED_TEMPLATE = "modal: email template changed",
  CLICKED_CLOSE = "modal closed",
  CLICKED_SETTINGS_URL = "modal: settings clicked",
  CHANGED_FAVORED_CONNECTION = "modal: favored connection changed",
  CLICKED_BLURBS_URL = "blurb guide clicked",
}

export enum OnboardingEvents {
  ONBOARDING_STARTED = "onboarding started",
  ONBOARDING_COMPLETED = "onboarding completed",
  ONBOARDING_STEP_CHANGE = "onboarding step changed",
}

export enum SettingsEvents {
  CHANGED_COMPANY_NAME = "settings: company name changed",
  CHANGED_FUNDRAISE_ROUND = "settings: company fundraise round changed",
  CHANGED_COMPANY_BLURB = "settings: company blurb changed",
}

export enum StarConnectionEvents {
  STARRED_CONNECTION_ADDED = "starred connection added",
  STARRED_CONNECTION_REMOVED = "starred connection removed",
}

export enum ActionEvents {
  EVENT_CARD_RSVP_CLICKED = "event card rsvp clicked",
  EXTENSION_MISSING = "error occurred",
  GLOBAL_ERROR = "error occurred",
  SEARCH_BAR_CLICKED = "search bar clicked",
  SEARCH_INITIATED = "search initiated",
  SEARCH_RESULTS_LOADED = "search results loaded",
  SEARCH_NO_RESULTS = "search no results",
  SEARCH_ERROR = "search error",
  GOOGLE_CONNECTIONS_IMPORT_STARTED = "google import started",
  GOOGLE_CONNECTIONS_IMPORT_COMPLETED = "google import completed",
  CLICKED_CHROME_EXTENSION_INSTALL = "chrome store url clicked",
  CLICKED_PAGINATION = "pagination clicked",
  CLICKED_USER_PROFILE_CHIP = "user profile chip clicked",
  CLICKED_SORT = "sort clicked",
  CLICKED_COMPANY = "company url clicked",
  API_TIME_RESPONDED = "perf: api responded",
  CLICKED_AIRTABLE_SYNC = "admin: airtable synced",
  CLICKED_BACK_BUTTON = "resources back button clicked",
  CLICKED_RESOURCES_NAV = "resources nav clicked",
  CLICKED_RESOURCE_PAGE_HEADING = "resources page heading clicked",
  RESOURCE_LINK_COPIED = "resource link copied",
  USER_PROFILE_LINK_CLICKED = "user profile social link clicked",
  CLOSE_THE_LOOP_FOLLOWUP_EMAIL_ERROR = "close the loop error",
  LIST_CREATED = "list: created",
  LIST_DELETED = "list: deleted",
  LIST_EDITED = "list: edited",
  LIST_PERSON_ADDED = "list: person added",
  LIST_PERSON_REMOVED = "list: person removed",
  LIST_PERSON_STATUS_CHANGED = "list: person status changed",
  // LIST_PERSON_SEARCHED = "list: person searched", will be commented out for now until we get a clear definition what we are tracking here
}

export enum RequestIntroEvents {
  REQUEST_INTRO_CLICKED = "request intro clicked",
  REQUEST_INTRO_DRAFT_OPENED = "request intro draft opened",
  REQUEST_INTRO_EMAIL_RECEIVED = "request intro email received",
  REQUEST_INTRO_MADE = "request intro made",
  REQUEST_INTRO_DECLINED = "request intro declined",
}
