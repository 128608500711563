import { ActionModal } from "@50y/celestial";
import { useEffect, useState } from "react";
import connectingImg from "src/assets/images/connecting.png";
import uploadImg from "src/assets/images/upload.png";
import welcomeImg from "src/assets/images/welcome.png";
import {
  IOnboardingEventParams,
  OnboardingEvents,
} from "src/config/amplitudeEvents";
import { CHROME_EXTENSION_LINK } from "src/config/constants";
import { useAmplitudeTrack } from "src/hooks";
import ConnectingStep from "./ConnectingStep";

enum EventTypes {
  CONTINUE_CLICKED = "continue clicked",
  CIRCLE_STEP_CLICKED = "circle step clicked",
}

const steps = [
  {
    title: "Welcome to 50Y Network",
    image: welcomeImg,
    description: (
      <span>
        A tool to 50x your network. Browse connections in the Fifty Years
        universe, request introductions, and expand your reach.
      </span>
    ),
  },
  {
    title: "Connecting all our networks",
    image: connectingImg,
    description: <ConnectingStep />,
  },
  {
    title: "How to upload connections",
    image: uploadImg,
    description: (
      <div className="text-left">
        <span>
          Install the Chrome Extension and automatically fetch and import your
          Linkedin connections.
        </span>
        <ol className="mt-8 list-decimal ml-4">
          <li>Install the extension.</li>
          <li>Log into Linkedin.</li>
          <li>
            Run the Chrome extension, and click Upload. Your connections will
            begin to appear.
          </li>
        </ol>
      </div>
    ),
  },
];

const OnboardingModal = ({ onClose }: { onClose: () => void }) => {
  const { track } = useAmplitudeTrack();
  const [step, setStep] = useState(0);
  const stepDefinition = steps[step];

  useEffect(() => {
    track(OnboardingEvents.ONBOARDING_STARTED);
  }, [track]);

  const handleStepChange = (step: number) => {
    if (step === 2) {
      track(OnboardingEvents.ONBOARDING_COMPLETED);
      window.open(CHROME_EXTENSION_LINK, "_blank");
      onClose();
      return;
    }
    const params: IOnboardingEventParams = {
      step,
      type: EventTypes.CIRCLE_STEP_CLICKED,
    };
    track(OnboardingEvents.ONBOARDING_STEP_CHANGE, params);
    setStep(step + 1);
  };

  return (
    <ActionModal
      isOpen
      heading={stepDefinition.title}
      description={stepDefinition.description}
      primaryAction={{
        label: step < 2 ? "Continue" : "Install Chrome Extension",
        onPress: () => {
          handleStepChange(step);
        },
      }}
      {...(step !== 0 && {
        secondaryAction: {
          label: "Back",
          onPress: () => setStep(step - 1),
        },
      })}
      onClose={onClose}
      steps={steps.length}
      currentStep={step}
      imageURL={stepDefinition.image}
      type="withImage"
    />
  );
};

export default OnboardingModal;
