import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import { useNavigate } from "react-router-dom";
import { Routes } from "src/config/routes";
import { useMe } from "src/queries/useMe";
import { Loader } from "./Loader";

interface ProtectedRouteProps {
  component: ComponentType;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component,
}) => {
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0();
  const { isError: meError, isLoading } = useMe();
  const navigate = useNavigate();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="page-layout">
        <Loader />
      </div>
    ),
  });

  if (isLoading || isAuthLoading) {
    return <Loader />;
  }

  if (meError && isAuthenticated) {
    navigate(Routes.NOT_ALLOWED);
  }

  return <Component />;
};
