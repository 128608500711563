import axios, { AxiosRequestConfig } from "axios";
import { IAmplitudeTrack } from "src/hooks/useAmplitudeTrack";
import { AppError } from "src/models/api-response";
import { callExternalApi } from "src/services/callExternalApi";

export const DEFAULT_PAGE_SIZE = 50;

export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;

export const getHeaders = (accessToken: string) => {
  const headers = {
    "content-type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  };
  return headers;
};

export const getRequestData = async (
  config: AxiosRequestConfig,
): Promise<{ data: any; error: AppError | null }> => {
  const { data, error } = await callExternalApi({
    config,
  });

  return {
    data,
    error,
  };
};

export const addLog = async ({
  accessToken,
  type,
  data,
}: {
  accessToken: string;
  type: string;
  data: unknown;
}) => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/add-log`,
    method: "POST",
    data: {
      type,
      data,
    },
    headers: getHeaders(accessToken),
  };

  await callExternalApi({
    config,
  });
};

export const sendAmplitudeEvents = async ({
  events,
  accessToken,
}: {
  events: IAmplitudeTrack[];
  accessToken: string;
}) => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/amplitude`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: { events },
  };

  return axios(config);
};
