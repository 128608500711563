import { TextInput, Typography } from "@50y/celestial";
import { cx } from "class-variance-authority";
import { useRef, useState } from "react";
import { Popover } from "react-aria-components";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { AddIcon } from "src/assets/icons/general/AddIcon";
import { CheckIcon } from "src/assets/icons/general/CheckIcon";
import { SearchIcon } from "src/assets/icons/general/SearchIcon";
import { useDebouncedValue } from "src/hooks";
import { Person } from "src/models/person-api-response";
import { usePersons } from "src/queries/usePersons";

interface PersonSearchResult {
  displayName: string;
  id: number;
  headline: string;
  isDisabled: boolean;
}

interface IProps {
  onPersonSelect?: (person: Person) => void;
  selectedPeopleIds?: Set<number>;
  placeholder?: string;
}

const tw = {
  popover:
    "z-50 bg-elevation-background border border-elevation-outline-1 rounded-md shadow-xl w-96",
  dropdown: "max-h-80 overflow-y-auto no-scrollbar",
  item: "flex items-center justify-between px-4 py-2 cursor-pointer hover:bg-elevation-surface-1 fill-interactive-primary-idle border-b border-elevation-outline-1",
  selectedItem:
    "bg-elevation-surface-1 text-interactive-text-text-disabled hover:cursor-auto",
};

export const PeopleSearch = ({
  onPersonSelect,
  selectedPeopleIds,
  placeholder = "Search",
}: IProps) => {
  const [search, setSearch] = useState("");
  const searchQuery = useDebouncedValue(search, { delay: 500 });
  const [isOpen, setOpen] = useState(false);
  const triggerRef = useRef<HTMLInputElement>(null);

  const { data: personsData, isLoading: personsLoading } = usePersons(
    1,
    searchQuery,
  );

  const searchResults = (personsData?.data.results || []).map((person) => {
    const isInvestor = !!person.investor;
    const isUser = !!person.user;
    // If headline isn't set in the API response, fallback to older fields.
    const headline =
      person.headline !== null
        ? person.headline
        : isInvestor
        ? person.investor!.role // firm_affiliation is not returned when searching all people
        : isUser
        ? person.user?.role
        : null;

    return {
      displayName: person.first_name + " " + person.last_name,
      id: person.id,
      headline,
      isDisabled: selectedPeopleIds?.has(person.id) || false,
    };
  }) as PersonSearchResult[];

  return (
    <div className="w-64">
      <TextInput
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setOpen(true);
        }}
        onFocus={() => setOpen(true)}
        ref={triggerRef}
        leftContent={<SearchIcon />}
        id="people-search"
        placeholder={placeholder}
        autoComplete="off"
      />

      <Popover
        className={tw.popover}
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={() => {
          setOpen(!isOpen);
          // unfocus input
          triggerRef.current?.blur();
        }}
        placement="bottom start"
        shouldCloseOnInteractOutside={(element) => {
          // Close the popover when user clicks outside the popover but the trigger element (input).
          return !(element.id === triggerRef.current?.id);
        }}
        offset={20}
        crossOffset={-40}
      >
        <div className={tw.dropdown}>
          {personsLoading && <Skeleton count={5} height={40} />}
          {searchResults.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                onPersonSelect &&
                  onPersonSelect(
                    personsData?.data.results.find(
                      (person) => person.id === item.id,
                    ) as Person,
                  );
                setOpen(false);
                setSearch("");
              }}
              className={cx(
                tw.item,
                selectedPeopleIds?.has(item.id) && tw.selectedItem,
              )}
            >
              <Typography variant="default" size="body-4" className="truncate">
                {item.displayName}
              </Typography>
              {selectedPeopleIds?.has(item.id) ? <CheckIcon /> : <AddIcon />}
            </div>
          ))}
          {!!search.length && searchResults.length === 0 && !personsLoading && (
            <div className="p-2">
              <Typography variant="default" size="body-4">
                No items
              </Typography>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
