import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { Routes } from "src/config/routes";

export const CallbackPage: React.FC = () => {
  const { error, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return null;
  }
  if (error) {
    return (
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Error
        </h1>
        <div className="content__body">
          <p id="page-description">
            <span>{error.message}</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <Navigate
      to={
        isAuthenticated
          ? window.location.pathname
            ? window.location.pathname
            : Routes.CONNECTIONS
          : Routes.LOGIN
      }
    />
  );
};
