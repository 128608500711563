import { reset } from "@amplitude/analytics-browser";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { LoginEvents, NavigationEvents } from "src/config/amplitudeEvents";
import { useAmplitudeTrack, useTrackPageVisited } from "src/hooks";

import { Button, Typography } from "@50y/celestial";
import notAllowedImg from "src/assets/images/not-allowed.gif";

const NotAllowedPage = () => {
  const { logout } = useAuth0();
  const { track } = useAmplitudeTrack();
  useTrackPageVisited(NavigationEvents.NOT_ALLOWED_PAGE_VIEWED);

  const handleLogout = useCallback(() => {
    track(LoginEvents.LOGGED_OUT);
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    reset();
  }, [logout, track]);

  return (
    <div className="h-full flex flex-col items-center max-w-[520px] m-auto py-4">
      <Typography variant="default" size="title-2" className="m-4">
        Universe is for 50Y portfolio founders only.{" "}
      </Typography>
      <Typography variant="default" size="body-4" className="m-4">
        Reach out to a 50Y team member if you should have access.
      </Typography>
      <img className="my-2" src={notAllowedImg} alt="Not Allowed" />
      <Button
        variant="primary"
        size="default"
        width="m"
        onPress={() => handleLogout()}
      >
        Logout
      </Button>
    </div>
  );
};

export default NotAllowedPage;
