import type { SVGProps } from "react";

export const PointerHorizontalIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="fill-current"
    {...props}
  >
    <path d="M14.1663 8.33334L9.99967 13.3333L5.83301 8.33334H14.1663Z" />
  </svg>
);
