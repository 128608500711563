import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { IntroRequest } from "src/models/intro-request-api-response";
import { API_SERVER_URL, getHeaders } from "./global.service";

export const addIntroRequest = async ({
  accessToken,
  user_id,
  connection_id,
  person_id,
  subject,
  email_content,
  organization_id,
  saved_list_id,
}: {
  accessToken: string;
  user_id: number;
  connection_id?: number;
  person_id?: number;
  subject: string;
  email_content: string;
  organization_id?: number | null;
  saved_list_id?: number;
}): Promise<AxiosResponse<IntroRequest>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/requested_intros`,
    method: "POST",
    data: {
      user_id,
      connection_id,
      person_id,
      subject,
      email_content,
      organization_id,
      saved_list_id,
    },
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const updateFollowUpEmail = async ({
  accessToken,
  answer,
  id,
}: {
  accessToken: string;
  id?: string;
  answer?: string;
}) => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/followup_email`,
    method: "POST",
    headers: getHeaders(accessToken),
    data: {
      id,
      answer,
    },
  };

  return axios(config);
};
