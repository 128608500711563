import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_SERVER_URL, getHeaders } from "./global.service";

export const createGoogleToken = async ({
  accessToken,
  code,
}: {
  accessToken: string;
  code: string;
}): Promise<AxiosResponse<{ message: string }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/google-token`,
    method: "POST",
    data: {
      code,
    },
    headers: getHeaders(accessToken),
  };

  return axios(config);
};

export const getGoogleToken = async ({
  accessToken,
}: {
  accessToken: string;
}): Promise<AxiosResponse<{ refresh_token_present: boolean }>> => {
  const config: AxiosRequestConfig = {
    url: `${API_SERVER_URL}/api/google-token`,
    method: "GET",
    headers: getHeaders(accessToken),
  };

  return axios(config);
};
